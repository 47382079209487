import axios from 'axios';

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

export const apiInstance = axios.create({
    // baseURL: 'http://127.0.0.1:8000/api',
    baseURL: 'https://admin.africaonestop.com/api',
    headers: {
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
    },
    cancelToken: source.token,
});


