import useRequest from "./useRequest";

const usePaymentQuery = () => {
    const request = useRequest();
    const verifyPayment = async(payload) => {
        return await request.post('/payments/verify', payload).then(res => res);
    }

    const verifyAndroidPayment = async(payload) => {
        return await request.post('/payments/verify/android', payload).then(res => res);
    }

    return{
        verifyPayment,
        verifyAndroidPayment
    }
}

export default usePaymentQuery;
