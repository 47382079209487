import React, {useEffect, useState} from 'react';
import {View, Text, StyleSheet, Platform} from "react-native";
import {getIsMobileDevice} from "../../utils/helpers";
import {AppTextInput} from "../../components/AppTextInput";
import {AppCard} from "../../components/AppCard";
import {useDimension} from "../../hooks/useDimension";
import {AppSpacing} from "../../components/AppSpacing";
import {AppButton} from "../../components/AppButton";
import {AppImage} from "../../components/AppImage";
import {AppTextButton} from "../../components/AppTextButton";
import {useNavigation, useTheme} from "@react-navigation/native";
import Constants from 'expo-constants';
import {Layout} from "../Layout";
import {useNavigate} from "react-router";
import useAppNavigation from "../../hooks/useNavigation";
import {useToast} from "react-native-toast-notifications";
import useUsersQuery from "../../hooks/useUsersQuery";
import {setAuthToken} from "../../contexts/reducers";
import {useAppContext} from "../../contexts/AppContext";
import LogRocket from "@logrocket/react-native";

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        textAlign: 'center'
    }
})

export const LoginScreen = () => {
    const isMobile = getIsMobileDevice();
    const toast = useToast();
    const [formData, setFormData] = useState({
        email: null,
        password: null
    })
    const [loading, setLoading] = useState(false);

    const {login} = useUsersQuery();
    const {redirectTo} = useAppNavigation();
    const {dispatch} = useAppContext();

    useEffect(() => {
        if(Platform.OS === 'web'){
            document.title = "Login"
        }
    }, [])

    const handleLogin = async() => {
        if(!formData?.email) {
            toast.show('Email address is required');
            return;
        }

        if(!formData?.password) {
            toast.show('Password is required');
            return;
        }

        setLoading(true);

        const response = await login(formData);
        setLoading(false);
        if(response?.error) {
            toast.show(response?.message);
            return;
        }

        dispatch(setAuthToken(response?.token));
        LogRocket.identify(response?.id, {
            name: response?.name,
            email: response?.email,
        });
        redirectTo("Home");
    }

    return(
        <Layout isStack={true}>
            <View style={[styles.container, {paddingVertical: 60, justifyContent: 'center' , alignItems: 'center'}]}>
                {isMobile
                    ?
                    <MobileView isButtonDisable={loading} onFormChange={(data) => setFormData(data)} onLoginPress={handleLogin}/>
                    :
                    <WebView isButtonDisable={loading} onFormChange={(data) => setFormData(data)} onLoginPress={handleLogin}/>
                }
            </View>
        </Layout>
    )
}
const MobileView = ({onFormChange, onLoginPress, isButtonDisable}) => {
    const {width} = useDimension();
    return(
        <View style={{width: width, padding: 16}}>
            <Form onFormChange={onFormChange} onLoginPress={onLoginPress} isButtonDisable={isButtonDisable}/>
        </View>
    )
}
const WebView = ({onFormChange, onLoginPress, isButtonDisable}) => {
    const {width} = useDimension();
    return(
        <View >
            <AppCard>
                <View style={{padding: 16, width: width * 0.3}}>
                    <Form onFormChange={onFormChange} onLoginPress={onLoginPress} isButtonDisable={isButtonDisable}/>
                </View>
            </AppCard>
        </View>
    )
}

const Form = ({onFormChange, onLoginPress, isButtonDisable}) => {
    const theme = useTheme();
    const isMobile = getIsMobileDevice();
    const {redirectTo} = useAppNavigation();
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const navigation  = useNavigation();
    const navigate = useNavigate();

    useEffect(() => {
        onFormChange({
            email: email,
            password: password
        })
    }, [email, password])

    const handleForgotPasswordRedirect = () => {
        if(Platform.OS === 'web') {
            navigate('/forgot-password')
        }
        else{
            navigation.navigate('ForgotPassword')
        }
    }

    return(
        <View style={{flex: 1}}>
            <View style={{justifyContent: 'center', alignItems: 'center'}}>
                <Text style={[styles.title, {color: theme.colors?.primaryText}]}>Login</Text>
                <AppSpacing gap={16}/>
                <Text style={{color: 'grey', fontWeight: 'bold'}}>Please login to get a seamless experience</Text>
            </View>
            <AppSpacing gap={64}/>
            <AppTextInput autoComplete={'email'} autoCorrect={false} autoCapitalize={'none'} onChangeText={(e) => setEmail(e)} height={Platform.OS === 'web' ? 40 : 50} label={"Email"}/>
            <AppSpacing gap={16}/>
            <AppTextInput autoComplete={'off'} autoCorrect={false} autoCapitalize={'none'} onChangeText={(e) => setPassword(e)} secureTextEntry={true} height={Platform.OS === 'web' ? 40 : 50} label={"Password"}/>
            <AppSpacing gap={16}/>
            <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                <AppTextButton onPress={handleForgotPasswordRedirect} title={'Forgot Password'}/>
                <AppTextButton onPress={() => redirectTo('Signup')} title={'Create Account'} color={theme.colors.primary}/>
            </View>
            <AppSpacing gap={32}/>
            <AppButton loading={isButtonDisable} disable={isButtonDisable} onPress={onLoginPress} title={"Login"}/>
        </View>
    )
}
