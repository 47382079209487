import React from 'react';
import {StyleSheet, View, TextInput, Text} from 'react-native';
import {useTheme} from "@react-navigation/native";
import {Colors} from "../themes/colors";
import {Platform} from "react-native-web";

const styles = StyleSheet.create({
    input: {
        paddingVertical: 0,
        minHeight: 30,
        paddingHorizontal: 0,
    },
});

export const AppTextInput = (props) => {
    const {
        label,
        limit = undefined,
        height = 50,
        backgroundColor = 'white',
        borderColor = 'grey',
        multiline = false
    } = props;


    return (
        <View>
            {label && (
                <View style={{marginBottom: 4}}>
                    <Text variant={'title'}>{label}</Text>
                </View>
            )}
            <TextInput
                mode={'outlined'}
                {...props}
                maxLength={limit}
                outlineStyle={{borderColor: borderColor}}
                placeholderTextColor="grey"
                style={[
                    styles.input,
                    {   height: height,
                        borderRadius: 10,
                        borderColor: borderColor,
                        borderWidth: 1,
                        backgroundColor: backgroundColor,
                        outlineStyle: 'none',
                        paddingLeft: 16,
                        paddingVertical: multiline ? 16 : 0,
                    },
                ]}
                theme={{roundness: 12}}
            />
        </View>
    );
};
