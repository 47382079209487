// import {NavigationContainer} from "@react-navigation/native";
// import {createStackNavigator} from "@react-navigation/stack";
import colors from "../themes/colors";
import HomeScreen from "../screens/home/home";
import React, {useEffect, useState} from 'react';
import {StyleSheet, Platform} from 'react-native';
// import {createDrawerNavigator} from "@react-navigation/drawer";
import {MobileMenu} from "./MobileMenu";
import {SplashScreen} from "../screens/splash/splash";
import {AddBusinessScreen} from "../screens/business/add-business";
import {ResetPasswordScreen} from "../screens/auth/reset-password";
import {ForgotPasswordScreen} from "../screens/auth/forget-password";
import {SignupScreen} from "../screens/auth/signup";
import {LoginScreen} from "../screens/auth/login";
import {ProfileScreen} from "../screens/user/profile";
import {AboutUsScreen} from "../screens/about-us";
import {ContactUsScreen} from "../screens/contact-us";
import * as Linking from 'expo-linking';
import { Route } from "react-router";
import {
    NativeRouter,
    StackNavigator,
} from "react-native-url-router";
import {View, Text, TextInput, Button} from 'react-native';
import {AppSpacing} from "../components/AppSpacing";
import {Home} from "iconsax-react-native";
import {NavigationContainer} from "@react-navigation/native";
import {createDrawerNavigator} from "@react-navigation/drawer";
import {BusinessViewScreen} from "../screens/business/view-business";
import {createStackNavigator} from "@react-navigation/stack";
import {BusinessListScreen} from "../screens/home/business-list";
import {useAppContext} from "../contexts/AppContext";
import useUsersQuery from "../hooks/useUsersQuery";
import {setUser} from "../contexts/reducers";
import {IAPSubscription} from "../screens/subscriptions/SubscriptionIAP";
import SubscriptionWebScreen from "../screens/subscriptions/SubscriptionWeb";
import PrivacyScreen from "../screens/others/privacy";
import DisclaimersScreen from "../screens/others/disclaimers";
import TermsScreen from "../screens/others/terms";
import SupportScreen from "../screens/others/support";
import FaqScreen from "../screens/others/faq";
import {ThankYouScreen} from "../screens/subscriptions/ThankYou";
import {ReferralScreen} from "../screens/auth/referral";

const Stack = createStackNavigator();
const Drawer = createDrawerNavigator();

const styles = StyleSheet.create({
    cardStyle: {
        backgroundColor: 'transparent',
        opacity: 1,
    },
});

const WebNavigator = () => {
    return(
        <StackNavigator defaultScreenConfig={{
            stackHeaderConfig: { hidden: false },
            containerStyle: { backgroundColor: "#f3f3f3" },
        }} screensConfig={{ "/": { title: "Home" }, "login": { title: "Login" } }}>
            <Route
                path="/"
                element={<HomeScreen/>}
            />
            <Route
                path="/login"
                element={<LoginScreen/>}
            />
            <Route
                path="/signup"
                element={<SignupScreen/>}
            />
            <Route
                path="/forgot-password"
                element={<ForgotPasswordScreen/>}
            />
            <Route
                path="/password/reset"
                element={<ResetPasswordScreen/>}
            />
            <Route
                path="/businesses"
                element={<BusinessListScreen/>}
            />
            <Route
                path="/business/setup"
                element={<AddBusinessScreen/>}
            />
            <Route
                path="/business/:slug"
                element={<BusinessViewScreen/>}
            />
            <Route
                path="/business/:id/edit"
                element={<AddBusinessScreen/>}
            />
            <Route
                path="/payments/subscription"
                element={<SubscriptionWebScreen/>}
            />
            <Route
                path="/profile"
                element={<ProfileScreen/>}
            />
            <Route
                path="/contact-us"
                element={<ContactUsScreen/>}
            />
            <Route
                path="/about-us"
                element={<AboutUsScreen/>}
            />
            <Route
                path="/privacy"
                element={<PrivacyScreen/>}
            />
            <Route
                path="/terms"
                element={<TermsScreen/>}
            />
            <Route
                path="/support"
                element={<SupportScreen/>}
            />
            <Route
                path="/disclaimers"
                element={<DisclaimersScreen/>}
            />
            <Route
                path="/faq"
                element={<FaqScreen/>}
            />
            <Route
                path="/subscriptions/thank-you"
                element={<ThankYouScreen/>}
            />
            <Route
                path="/business/p/:id/"
                element={<ReferralScreen/>}
            />

        </StackNavigator>
    )
}

const AppNavigator = () => {
    return(
        <Stack.Navigator screenOptions={{headerShown: false}} initialRouteName="Home">
            <Stack.Screen name="Splash" component={SplashScreen} />
            <Stack.Screen name="Login" component={LoginScreen} />
            <Stack.Screen name="Signup" component={SignupScreen} />
            <Stack.Screen name="ForgotPassword" component={ForgotPasswordScreen} />

            <Stack.Screen name="Home" component={HomeScreen} />
            <Stack.Screen name="BusinessesList" component={BusinessListScreen} />
            <Stack.Screen name="ViewBusiness" component={BusinessViewScreen} />
            <Stack.Screen name="AddBusiness" component={AddBusinessScreen} />
            <Stack.Screen name="Profile" component={ProfileScreen} />
            <Stack.Screen name="Subscription" component={IAPSubscription} />

            <Stack.Screen name="AboutUs" component={AboutUsScreen} />
            <Stack.Screen name="ContactUs" component={ContactUsScreen} />
            <Stack.Screen name="Privacy" component={PrivacyScreen} />
            <Stack.Screen name="Terms" component={TermsScreen} />
            <Stack.Screen name="Disclaimers" component={DisclaimersScreen} />
            <Stack.Screen name="Support" component={SupportScreen} />
            <Stack.Screen name="Faq" component={FaqScreen} />
        </Stack.Navigator>
    )
}

const MainNavigator = () => {
    return Platform.OS === 'web' ? <WebNavigator/> : <AppNavigator/>;
}


const GlobalNavigator = (props) => {
    const {theme} = props;
    const themeColors = theme.colors;
    const [loggedIn, setLoggedIn] = useState(false);
    const {state, dispatch} = useAppContext();

    return (
        <NativeRouter>
            <NavigationContainer theme={theme} fallback={SplashScreen}>
                <Drawer.Navigator
                    screenOptions={{
                        headerShown: false,
                        cardStyle: styles.cardStyle,
                        headerStyle: {
                            backgroundColor: themeColors.background,
                        },
                        headerTintColor: themeColors.text,
                        headerTitleStyle: {
                            fontWeight: '600',
                        },
                        headerShadowVisible: false,
                        headerBackTitleVisible: false,
                    }}
                    drawerContent={(props) => <MobileMenu {...props} />}>
                    <Drawer.Screen name={'Main'} options={{title: "Home"}} component={MainNavigator}/>
                </Drawer.Navigator>
            </NavigationContainer>
        </NativeRouter>
    );
};

export default GlobalNavigator;
