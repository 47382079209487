import {StyleSheet, Text, TouchableOpacity, View, ActivityIndicator} from "react-native";
import {useTheme} from "@react-navigation/native";
import {AppSpacing} from "./AppSpacing";

const styles = StyleSheet.create({
    button: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 4,
        paddingHorizontal: 16,
        borderRadius: 10,
        height: 40,
        justifyContent: 'center'
    },
    buttonContainer: {
        flexDirection: 'row',
    },
    icon: {
        marginRight: 8,
    },
    buttonText: {
        textDecorationLine: 'underline'
    }
})


export const AppButton = ({
    width,
    icon,
    title,
    isUnderline = true,
    onPress,
    color,
    buttonTextColor = 'white',
    disabled = false,
    loading = false
}) => {
    const theme = useTheme();
    if (!color) color = theme.colors.primary;
    // const {icon, title, isUnderline = true, onPress, color = theme.colors?.primary, buttonTextColor = 'white'} = props;

    return (
        <TouchableOpacity
            activeOpacity={0.8}
            style={[styles.button, {width: width, backgroundColor: disabled ? 'grey' : color}]}
            onPress={() => {
                !disabled ? onPress() : {}
            }}>
            {icon && <View>{icon}</View>}
            {icon && <AppSpacing gap={8} isHorizontal={true}/>}

            {!loading &&
            <Text style={{textTransform: 'uppercase', fontWeight: 'bold', color: buttonTextColor}}>
                {title}
            </Text>
            }
            {loading && <ActivityIndicator color={'white'}/>}
        </TouchableOpacity>
    )
}
