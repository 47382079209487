import React, {useState} from 'react';
import {View, Text, StyleSheet, Platform} from "react-native";
import {getIsMobileDevice} from "../../utils/helpers";
import {AppTextInput} from "../../components/AppTextInput";
import {AppCard} from "../../components/AppCard";
import {useDimension} from "../../hooks/useDimension";
import {AppSpacing} from "../../components/AppSpacing";
import {AppButton} from "../../components/AppButton";
import {AppImage} from "../../components/AppImage";
import {AppTextButton} from "../../components/AppTextButton";
import {useTheme} from "@react-navigation/native";
import Constants from 'expo-constants';
import {Layout} from "../Layout";
import {useNavigate} from "react-router";
import useAppNavigation from "../../hooks/useNavigation";
import useUsersQuery from "../../hooks/useUsersQuery";
import {useToast} from "react-native-toast-notifications";

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        textAlign: 'center'
    }
})

export const ResetPasswordScreen = () => {
    const isMobile = getIsMobileDevice();
    return(
        <Layout>
            <View style={[styles.container, {paddingVertical: 60, justifyContent: 'center' , alignItems: 'center'}]}>
                {isMobile ? <MobileView/> : <WebView/>}
            </View>
        </Layout>
    )
}
const MobileView = () => {
    const {width} = useDimension();
    return(
        <View style={{width: width, padding: 16}}>
            <Form/>
        </View>
    )
}
const WebView = () => {
    const {width} = useDimension();
    return(
        <View >
            <AppCard>
                <View style={{padding: 16, width: width * 0.3}}>
                    <Form/>
                </View>
            </AppCard>
        </View>
    )
}

const Form = () => {
    const theme = useTheme();
    const [isLoading, setIsLoading] = useState(false);
    const isMobile = getIsMobileDevice();
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [password, setPassword] = useState('');
    const {redirectTo} = useAppNavigation();
    const {resetPassword} = useUsersQuery();
    const toast = useToast();
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const token = params?.token;

    const updatePassword = async() => {
        if(!password){
            toast.show('Enter a valid password.');
            return;
        }

        if(password !== passwordConfirmation) {
            toast.show('Password does not match');
            return;
        }

        if(!token) {
            toast.show('Something went wrong.');
            return;
        }

        setIsLoading(true);
        const response = await resetPassword({
            password: password,
            password_confirmation: passwordConfirmation,
            token: token
        });
        setIsLoading(false);
        if(response?.error) {
            toast.show(response?.message);
            return;
        }

        console.log('response', response);

        toast.show(response?.message);

        redirectTo('Login');
    }
    return(
        <View style={{flex: 1}}>
            <View style={{justifyContent: 'center', alignItems: 'center'}}>
                <Text style={[styles.title, {color: theme.colors?.primaryText}]}>Update Password</Text>
                <AppSpacing gap={16}/>
                <Text style={{color: 'grey', fontWeight: 'bold'}}>Please enter your new password</Text>
            </View>
            <AppSpacing gap={64}/>
            <AppTextInput secureTextEntry={true} value={password} secure onChangeText={e => setPassword(e)}  autoCorrect={false} autoCapitalize={'none'}  height={Platform.OS === 'web' ? 40 : 50} label={"Password"}/>
            <AppSpacing gap={16}/>
            <AppTextInput secureTextEntry={true} value={passwordConfirmation} onChangeText={e => setPasswordConfirmation(e)}  autoCorrect={false} autoCapitalize={'none'}  height={Platform.OS === 'web' ? 40 : 50} label={"Confirm Password"}/>
            <AppSpacing gap={16}/>
            <AppButton loading={isLoading} onPress={updatePassword} title={"Update Password"}/>
        </View>
    )
}
