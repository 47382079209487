import React, {useEffect, useState} from 'react'
import {
    NativeModules,
    Platform,
    Text,
    TouchableOpacity,
    View,
    StyleSheet,
    ActivityIndicator,
    Pressable
} from "react-native";
import {
    PurchaseError, purchaseUpdatedListener,
    requestSubscription,
    useIAP,
    acknowledgePurchaseAndroid, flushFailedPurchasesCachedAsPendingAndroid, clearTransactionIOS
} from "react-native-iap";
import {Layout} from "../Layout";
import {AppButton} from "../../components/AppButton";
import {useNavigation, useTheme} from "@react-navigation/native";
import usePaymentQuery from "../../hooks/usePaymentQuery";
import {useToast} from "react-native-toast-notifications";
import {setUser} from "../../contexts/reducers";
import useUsersQuery from "../../hooks/useUsersQuery";
import {useAppContext} from "../../contexts/AppContext";
import {
    APPLE_PLAN_1,
    APPLE_PLAN_2,
    APPLE_PLAN_3,
    APPLE_PLAN_4,
    APPLE_PLAN_5,
    GOOGLE_PLAN_1,
    GOOGLE_PLAN_2,
    GOOGLE_PLAN_3,
    GOOGLE_PLAN_4,
    GOOGLE_PLAN_5
} from "../../utils/constants";

const pricingOptions = [
    {
        name: 'Plan 1',
        features: ['Add up to 2 businesses', 'Monthly renewable', 'Cancel item or upgrade to next plan'],
        price: 7.99,
        limit: 2,
    },
    {
        name: 'Plan 2',
        features: ['Add up to 5 businesses', 'Monthly renewable', 'Cancel item or upgrade to next plan'],
        price: 14.99,
        limit: 5
    },
    {
        name: 'Plan 3',
        features: ['Add up to 10 businesses', 'Monthly renewable', 'Cancel item or upgrade to next plan'],
        price: 34.99,
        limit: 10,
    },
    {
        name: 'Plan 4',
        features: ['Add up to 20 businesses', 'Monthly renewable', 'Cancel item or upgrade to next plan'],
        price: 79.99,
        limit: 20
    },
    {
        name: 'Plan 5',
        features: ['Add up to 50 businesses', 'Monthly renewable', 'Cancel item or upgrade to next plan'],
        price: 149.99,
        limit: 9999
    }
]

export const IAPSubscription = () => {
    const {RNIapIos, RNIapIosSk2, RNIapModule, RNIapAmazonModule} = NativeModules;
    const isIos = Platform.OS === 'ios';
    const isAndroid = Platform.OS === 'android';
    const isAmazon = false;
    const {state} = useAppContext();
    const isPlay = isAndroid && !!RNIapModule;
    const {dispatch} = useAppContext();
    const [disabled, setDisabled] = useState(false);
    const [connecting, setConnecting] = useState(true);
    const subscriptionSkus = Platform.select({
        ios: [APPLE_PLAN_1, APPLE_PLAN_2, APPLE_PLAN_3, APPLE_PLAN_4, APPLE_PLAN_5],
        android: [GOOGLE_PLAN_1, GOOGLE_PLAN_2, GOOGLE_PLAN_3, GOOGLE_PLAN_4, GOOGLE_PLAN_5],
        default: [],
    })

    // useEffect(() => {
    //     (async() => {
    //         await initConnection();
    //         const products = await getProducts({skus:  subscriptionSkus})
    //         console.log('products', products);
    //     })()
    // }, [])


    const {
        connected,
        subscriptions,
        getSubscriptions,
        currentPurchase,
        finishTransaction,
        availablePurchases,
        purchaseHistory
    } = useIAP();

    console.log('currentPurchase s', currentPurchase);

    const [selectedPlan, setSelectedPlan] = useState(null);
    const [loading, setLoading] = useState(false);
    const theme = useTheme();
    const {verifyPayment, verifyAndroidPayment} = usePaymentQuery();
    const {getMe}  = useUsersQuery();
    const navigation = useNavigation();
    const toast = useToast();
    const choosenPlan = pricingOptions[selectedPlan];

    const [ownedSubscriptions, setOwnedSubscriptions] = useState([]);
    const handleGetSubscriptions = async () => {
        try {
            console.log('subscriptionSkus', subscriptionSkus);
            const response = await getSubscriptions({skus:  subscriptionSkus});
            console.log('response', response);
            setConnecting(false);
        } catch (error) {
            console.log('error getSubscriptions', error?.message)
        }
    };

    // useEffect(() => {
    //     (async() => {
    //         if(Platform.OS === 'ios') {
    //             await clearTransactionIOS();
    //         }
    //         else{
    //             await flushFailedPurchasesCachedAsPendingAndroid();
    //         }
    //
    //     })()
    // }, [])

    // useEffect(() => {
    //     const subscription = purchaseUpdatedListener((purchase) => {
    //         console.log('purchase 2', purchase);
    //     });
    //
    //     return () => {
    //         subscription.remove();
    //     };
    // }, []);

    useEffect(() => {
        handleGetSubscriptions();
    }, [selectedPlan])


    const redirectToAddBusiness = () => {
        navigation.navigate('Profile', {refresh: true});
    }

    const handleBuySubscription = async (
        productId,
        offerToken
    ) => {
        console.log('productId', productId);
        console.log('offerToken', offerToken);
        setLoading(true);
        if (isPlay && !offerToken) {
            console.warn(
                `There are no subscription Offers for selected product (Only requiered for Google Play purchases): ${productId}`,
            );
        }
        try {
            await requestSubscription({
                sku: productId,
                ...(offerToken && {
                    subscriptionOffers: [{sku: productId, offerToken}],
                }),
            });
        } catch (error) {
            if (error instanceof PurchaseError) {
                setLoading(false);
                errorLog({message: `[${error.code}]: ${error.message}`, error});
            } else {
                setLoading(false);
                console.log('handleBuySubscription error', error?.message)
            }
        }
    };


    useEffect(() => {
        const checkCurrentPurchase = async () => {
            console.log('checkCurrentPurchase', currentPurchase);
            try {
                if (currentPurchase?.productId && !ownedSubscriptions.includes(currentPurchase?.productId) && choosenPlan?.name) {
                    if(Platform.OS === 'android') {
                        const acknowledgeRes = await acknowledgePurchaseAndroid({token: currentPurchase?.purchaseToken});
                        if(acknowledgeRes.code === 'OK') {
                            const verifyResponse = await verifyAndroidPayment({
                                planName: choosenPlan?.name,
                                transactionId: currentPurchase?.transactionId
                            })
                            setLoading(false);

                            if(verifyResponse?.error) {
                                toast.show(verifyResponse?.message);
                                return;
                            }

                            await refreshCurrentUser();

                        }
                        else{
                            toast.show('Something went wrong, try again later.');
                            setLoading(false);
                        }
                    }
                    else{
                        await finishTransaction({
                            purchase: currentPurchase,
                            isConsumable: true,
                        });

                        setOwnedSubscriptions((prev) => [
                            ...prev,
                            currentPurchase?.productId,
                        ]);

                        console.log('currentPurchase.transactionReceipt', currentPurchase.transactionReceipt)

                        if(currentPurchase.transactionReceipt) {
                            const payload = {
                                platform: Platform.OS === "ios" ? 'apple' : 'android',
                                receipt: currentPurchase.transactionReceipt,
                                token: currentPurchase?.purchaseToken,
                                item: currentPurchase.productId,
                                plan: choosenPlan?.name,
                            };

                            await checkPaymentData(payload);
                        }
                    }
                }
            } catch (error) {
                if (error instanceof PurchaseError) {
                    toast.show('Something went wrong, try again later.');
                    setLoading(false);
                    errorLog({message: `[${error.code}]: ${error.message}`, error});
                    console.log('buy error 1', error.message)
                } else {
                    toast.show('Something went wrong, try again later.');
                    setLoading(false);
                    console.log('buy error 2', error.message)
                }
            }
        };

        checkCurrentPurchase();
    }, [currentPurchase, finishTransaction]);

    const errorLog = (log) => {
        console.log('log', log);
    }

    const checkPaymentData = async(payload) => {
        console.log('check payment', payload);
        const res = await verifyPayment(payload);
        if(res?.success) {
            await refreshCurrentUser()
        }
        else{
            toast.show('Unable to complete the subscription.');
            setLoading(false);
        }
    }

    const refreshCurrentUser = async() => {
        const response = await getMe();
        dispatch(setUser(response));
        toast.show('Subscription successful');
        setLoading(false);
        setDisabled(true);
        redirectToAddBusiness();
    }

    useEffect(() => {
        (async() => {
            const response = await getMe();
            dispatch(setUser(response));
        })()
    }, [])

    const planToPurchase = subscriptions?.find(item => item.productId === subscriptionSkus[selectedPlan]);


    return(
        <Layout>
            {
                connecting ?
                    <View style={{flex: 1, minHeight: 400, alignItems: 'center', justifyContent: 'center'}}>
                        <ActivityIndicator color={theme.colors.primary}/>
                    </View>
                    :
                    <View style={{padding: 16}}>
                        <Text style={{fontSize: 20, fontWeight: 'bold', marginBottom: 16}}>Subscribe</Text>
                        <Text style={{marginBottom: 16}}>To add new business you need to subscribe to one of our subscription plan</Text>
                        <Text>Please choose one of the plan below</Text>
                        <View style={{marginTop: 16}}>
                            {
                                state.user?.active_plan ?
                                    <Text style={{fontWeight: 'bold'}}>Please upgrade your plan in order to add more business</Text>
                                    :
                                    <Text style={{fontWeight: 'bold', color: theme.colors.primary}}>You don't have an active plan</Text>
                            }
                        </View>


                        <View style={{marginVertical: 16}}>
                            {
                                pricingOptions.map((item, index) => {
                                    const isDisabled = item?.limit < state.user?.businesses_count;
                                    return(
                                        <Pressable disabled={isDisabled} key={item?.name} onPress={() => setSelectedPlan(index)} style={[styles.subscriptionItem, {position: 'relative', borderColor: selectedPlan === index ? theme.colors.primary : 'grey'}]}>
                                            {
                                                isDisabled &&
                                                <View style={[styles.subscriptionItem, {position: 'absolute', top: 0, zIndex: 9, left: 0, borderColor: 'transparent', width: '100%', margin: 0, height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.7)'}]}/>
                                            }
                                            <Text style={{fontWeight: 'bold'}}>{item?.name}</Text>
                                            <View style={{marginTop: 8}}>
                                                {
                                                    item.features.map(feature => {
                                                        return(
                                                            <Text key={feature} style={{marginVertical: 4}}>• {feature}</Text>
                                                        )
                                                    })
                                                }
                                            </View>
                                            <Text style={{fontWeight: 'bold', fontSize: 20, marginTop: 10}}>${item?.price} per month</Text>
                                        </Pressable>
                                    )
                                })
                            }
                        </View>
                        <View>
                            <AppButton onPress={() => isPlay
                                ? handleBuySubscription(
                                    subscriptions[selectedPlan].productId,
                                    subscriptions[selectedPlan].subscriptionOfferDetails[0]?.offerToken)
                                : handleBuySubscription(planToPurchase?.productId)} disabled={loading || disabled} loading={loading} title={"Subscribe"}/>
                        </View>
                    </View>
            }

        </Layout>
    )

}

const styles = StyleSheet.create({
    subscriptionItem: {
        padding: 16,
        borderWidth: 2,
        borderColor: 'grey',
        borderRadius: 16,
        marginVertical: 16
    }
})
