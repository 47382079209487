import {useNavigate} from "react-router";
import {Platform} from "react-native";
import {useNavigation} from "@react-navigation/native";

const webPaths = {
    Login: '/login',
    Signup: '/signup',
    ForgotPassword: 'forgot-password',
    Home: '/',
    Business: '/businesses',
    AddBusiness: '/business/setup',
    ViewBusiness: '/business/:slug',
    EditBusiness: '/business/:slug/edit',
    Profile: '/profile',
    ContactUs: '/contact-us',
    AboutUs: '/about-us'
}

const useAppNavigation = () => {

    const navigate = useNavigate();
    const navigation = useNavigation();

    const redirectTo = (path, segmentValue, params) => {
        if(path) {
            if(Platform.OS === 'web') {
                let route = webPaths[path] ? webPaths[path] : path;
                if(segmentValue) {
                    if(path === 'ViewBusiness') {
                        route = route.replace(':slug', segmentValue);
                    }
                    else if(path === 'EditBusiness') {
                        route = route.replace(':slug', segmentValue);
                    }
                }
                if(params) {
                    route = route +"?" + params;
                }
                navigate(route);
            }
            else{
               navigation.navigate(path, segmentValue ? {id: segmentValue} : {});
            }
        }
    }


    return{
        redirectTo
    }
}

export default useAppNavigation;
