import React from 'react';
import {View, StyleSheet, Text} from "react-native";

const styles = StyleSheet.create({

})

export const SplashScreen = () => {
    return(
        <View>
            <Text>Salsh</Text>
        </View>
    )
}
