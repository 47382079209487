import React from 'react';
import {View, Text, StyleSheet, TouchableOpacity} from 'react-native';
import {useTheme} from "@react-navigation/native";
import {Link} from "react-native-url-router";
import {useAppContext} from "../contexts/AppContext";
import {useNavigate} from "react-router";


const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    button: {
        marginHorizontal: 8,
    },
    buttonText: {
        fontWeight: 'bold'
    }
})

export const DesktopMenu = ({activePage = '', isTransparent = false}) => {
    const theme = useTheme();
    const color = isTransparent ? 'white' : 'black';
    const {state} = useAppContext();
    const navigate = useNavigate();
    const onAddBusinessPress = () => {
        if(state?.authToken) {
            window.location.href = ('/business/setup')
        }
        else{
            navigate('/login')
        }
    }

    return(
        <View style={styles.container}>
            <Link style={styles.button} to={'/'}>
                <Text style={[styles.buttonText, {color : activePage === 'home' ? theme.colors.primary : color }]}>Home</Text>
            </Link>
            <TouchableOpacity onPress={onAddBusinessPress} style={styles.button}>
                <Text style={[styles.buttonText, {color : activePage === 'home' ? theme.colors.primary : color }]}>Add a business</Text>
            </TouchableOpacity>

            <Link style={styles.button} to={'/about-us'}>
                <Text style={[styles.buttonText, {color : activePage === 'home' ? theme.colors.primary : color }]}>About Us</Text>
            </Link>
            <Link style={styles.button} to={'/contact-us'}>
                <Text style={[styles.buttonText, {color : activePage === 'home' ? theme.colors.primary : color }]}>Contact Us</Text>
            </Link>
        </View>
    )
}
