import React, {useEffect, useRef, useState} from 'react';
import {View, StyleSheet, ScrollView, Platform, Text} from 'react-native';
import {getIsMobileDevice} from "../utils/helpers";
import {DesktopHeader} from "./DesktopHeader";
import {MobileHeader} from "./MobileHeader";
import {Footer} from "./Footer";
import Constants from "expo-constants";
import * as Location from "expo-location";
import {useAppContext} from "../contexts/AppContext";
import {AppDialog} from "../components/AppDialog";
import {AppButton} from "../components/AppButton";
import useAppNavigation from "../hooks/useNavigation";
import useCategoriesQuery from "../hooks/useCategoriesQuery";
import {setCategories, updateLoginModalStatus} from "../contexts/reducers";
import {useNavigate} from "react-router";
import {ScrollView as ScrollViewWeb} from 'react-native-web';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#f3f3f3'
    },
    contentContainer: {

    }
})

export const Layout = ({children, banner, isTransparent, isStack, onEndReach}) => {
    const isMobile = getIsMobileDevice();
    const paddingTop = isTransparent ? 0 : 100;
    const scrollRef = useRef(null);
    const [showHeader, setShowHeader] = useState(isTransparent);
    const {state, dispatch} = useAppContext();
    const {redirectTo} = useAppNavigation();
    const {fetchCategories} = useCategoriesQuery();
    const navigate = useNavigate();
    const [bottomSpace, setBottomSpace] = useState(0);

    const isCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }) => {
        const paddingToBottom = bottomSpace;
        return layoutMeasurement.height + contentOffset.y >= contentSize.height - paddingToBottom
    }

    const handleScroll = (event) => {
        const scrolled = event.nativeEvent.contentOffset.y
        if(isTransparent) {
            if(scrolled > 100) {
                setShowHeader(false)
            }
            else{
                setShowHeader(true)
            }
        }
        if(isCloseToBottom(event.nativeEvent)) {
            if(onEndReach) {
                onEndReach();
            }
        }
    }

    const handleModalClose = () => {
        dispatch({
            type: 'UPDATE_LOGIN_MODAL_STATUS',
            payload: false
        })
    }

    useEffect(() => {
        if(state?.authToken && state.categories?.length === 0) {
            (async() => {
                const response = await fetchCategories();
                if(response?.error) {
                    return;
                }

                dispatch(setCategories(response))
            })()
        }
    }, [state?.authToken])

    const handleAddBusinessPress = () => {
        console.log('state.authToken', state.authToken)
        if(state.authToken) {
            window.location.href = ('/business/setup')
        }
        else{
            dispatch(updateLoginModalStatus(true))
        }

    }


    return(
        <View style={styles.container}>
            {
                isMobile ? <MobileHeader isStack={isStack}/> : <DesktopHeader onPressAddBusiness={handleAddBusinessPress} isTransparent={showHeader}/>
            }
            <ScrollView
                // onMomentumScrollEnd={({ nativeEvent }) => {
                //     if (isCloseToBottom(nativeEvent)) {
                //         if(onEndReach) {
                //             onEndReach();
                //         }
                //     }
                // }}
                scrollEventThrottle={16}
                ref={scrollRef}
                onScroll={handleScroll}
                showsVerticalScrollIndicator={false}
                contentContainerStyle={{flexGrow: 1}}>
                {
                    banner &&
                    <View>
                        {banner}
                    </View>
                }
                <View style={{zIndex: -1, flexDirection: 'row', justifyContent: 'center', alignItems: isMobile ? null :'center', paddingTop: isMobile ? 0 : paddingTop}}>
                    <View style={{flex: 1, maxWidth: 1140}}>
                        {children}
                    </View>
                </View>
                <View onLayout={(event) => {
                    const {x, y, width, height} = event.nativeEvent.layout;
                    console.log('height', height);
                    setBottomSpace(height);
                }}>
                    <Footer/>
                </View>

                <AppDialog isVisible={state?.isLoginModalVisible} onClose={handleModalClose}>
                    <Text style={{fontSize: 20, fontWeight: 'bold', marginBottom: 16}}>Hello!</Text>
                    <Text style={{marginBottom: 16, textAlign: 'center'}}>This feature is only available to members. Please login to access this feature</Text>
                    <View>
                        <AppButton onPress={() => redirectTo('Login')} title={"Login"}/>
                    </View>
                </AppDialog>
            </ScrollView>
        </View>
    )
}
