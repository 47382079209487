import {Collapse,CollapseHeader, CollapseBody, AccordionList} from 'accordion-collapse-react-native';
import React, {useState} from 'react';
import {View, StyleSheet, Text} from 'react-native';
import {ArrowDown2, ArrowDown3, ArrowUp, ArrowUp2} from "iconsax-react-native";
import useTheme from "../hooks/useTheme";

const styles = StyleSheet.create({
    faqHeader: {
        backgroundColor: '#590100',
        borderRadius: 10,
        padding: 16
    },
    faqBody: {
        backgroundColor: 'white',
        borderRadius: 10,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        padding: 16
    }
})

export const AppAccordion = ({title, content, backgroundColor}) => {
    const theme = useTheme();
    const [isExpanded, setIsExpanded] = useState(false);
    return(
        <View>
            <Collapse isExpanded={isExpanded} onToggle={(value) => setIsExpanded(value)}>
                <CollapseHeader style={[
                    styles.faqHeader,
                    {borderBottomRightRadius: isExpanded ? 0 : 10,
                        borderBottomLeftRadius: isExpanded ? 0 : 10,
                        backgroundColor: backgroundColor ? backgroundColor : theme.colors.primary
                    }
                    ]}>
                    <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Text style={{color: backgroundColor ? theme.colors.primary : 'white'}}>{title}</Text>
                        {
                            isExpanded ? <ArrowUp2 color={backgroundColor ? theme.colors.background : 'white'} size={20}/> : <ArrowDown2 color={backgroundColor ? theme.colors.background : 'white'} size={20}/>
                        }
                    </View>
                </CollapseHeader>
                <CollapseBody style={styles.faqBody}>
                    <Text>{content}</Text>
                </CollapseBody>
            </Collapse>
        </View>
    )
}
