import React from 'react';
import {View, Text, TouchableOpacity, StyleSheet, Platform} from 'react-native';
import {createDrawerNavigator, DrawerContentScrollView, DrawerItem} from "@react-navigation/drawer";
import {AppImage} from "../components/AppImage";
import {AppSpacing} from "../components/AppSpacing";
import {useDimension} from "../hooks/useDimension";
import {useNavigate} from "react-router";
import {DrawerActions, useNavigation} from "@react-navigation/native";
import {Bag, Copyright, Home, Logout, Shop, Sms, User} from "iconsax-react-native";
import useTheme from "../hooks/useTheme";
import useAppNavigation from "../hooks/useNavigation";
import {useAppContext} from "../contexts/AppContext";
import {setAuthToken, setUser, unsetAuthToken} from "../contexts/reducers";

const styles = StyleSheet.create({
    menuContainer: {

    },
    menuItemsCard: {

    },
    menu: {
        paddingVertical: 16,
        fontWeight: 'bold',
    },
    row: {
        alignItems:'center',
        flexDirection: 'row'
    }
})


export const MobileMenu = (props) => {
    const {redirectTo} = useAppNavigation();
    const navigation = useNavigation();
    const navigate = useNavigate();
    const handleRedirects = (path) => {
        navigation.dispatch(DrawerActions.closeDrawer());
       setTimeout(() => {
           redirectTo(path);
       }, 500)
    };
    const {height} = useDimension();
    const theme = useTheme();

    const {state, dispatch} = useAppContext();

    const logout = () => {
        dispatch(setUser(null));
        dispatch(unsetAuthToken());
        redirectTo("Login");
    }

    const handleLoginCheck = () => {
        navigation.dispatch(DrawerActions.closeDrawer());
        if(state.authToken) {
            if(Platform.OS === 'web') {
                window.location.href = ('/business/setup')
            }
            else{
                navigation.navigate('AddBusiness')
            }
        }
        else{
            redirectTo("Login")
        }
    }

    return(
        <DrawerContentScrollView {...props}>
            <View style={{padding: 16, height: height - 100}}>
                <View style={{flex: 1}}>
                    {
                        state.user?.id  ? <View style={{flexDirection: 'row', alignItems: 'center'}}>
                            <AppImage roundness={50} size={50} uri={state.user?.avatar ? state.user.avatar : require('../../assets/img/avatar.webp')} isLocal={!state.user?.avatar}/>
                            <AppSpacing gap={8} isHorizontal={true}/>
                            <View>
                                <Text style={{fontWeight: 'bold', color: '#22334D'}}>{state.user?.name}</Text>
                                <AppSpacing gap={4}/>
                                <Text style={{color: '#22334D'}}>{state.user?.email}</Text>
                            </View>
                        </View>
                            :
                            <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                <AppImage roundness={50} size={50} uri={require('../../assets/img/avatar-placeholder.jpeg')} isLocal={true}/>
                                <AppSpacing gap={8} isHorizontal={true}/>
                                <View>
                                    <Text style={{fontWeight: 'bold', color: '#22334D'}}>Guest</Text>
                                </View>
                            </View>
                    }

                    <AppSpacing gap={16}/>
                    <View style={styles.menuContainer}>
                        <TouchableOpacity style={styles.menu} onPress={() => handleRedirects('Home')}>
                            <View style={styles.row}>
                                <Home size={20} color={theme.colors.text}/>
                                <AppSpacing gap={8} isHorizontal={true}/>
                                <Text style={{fontWeight:'bold', color: theme.colors.text}}>Home</Text>
                            </View>
                        </TouchableOpacity>
                        {
                            state.user?.id &&
                            <TouchableOpacity style={styles.menu} onPress={() => handleRedirects('Profile')}>
                                <View style={styles.row}>
                                    <User size={20} color={theme.colors.text}/>
                                    <AppSpacing gap={8} isHorizontal={true}/>
                                    <Text style={{fontWeight:'bold', color: theme.colors.text}}>Profile</Text>
                                </View>
                            </TouchableOpacity>
                        }
                        {
                            <TouchableOpacity style={styles.menu} onPress={() => handleLoginCheck()}>
                                <View style={styles.row}>
                                    <Shop size={20} color={theme.colors.text}/>
                                    <AppSpacing gap={8} isHorizontal={true}/>
                                    <Text style={{fontWeight:'bold', color: theme.colors.text}}>Add Business</Text>
                                </View>
                            </TouchableOpacity>
                        }
                        {
                            !state.user?.id &&
                            <TouchableOpacity style={styles.menu} onPress={() => handleRedirects('Login')}>
                                <View style={styles.row}>
                                    <User size={20} color={theme.colors.text}/>
                                    <AppSpacing gap={8} isHorizontal={true}/>
                                    <Text style={{fontWeight:'bold', color: theme.colors.text}}>Login</Text>
                                </View>
                            </TouchableOpacity>
                        }
                        <TouchableOpacity style={styles.menu} onPress={() => handleRedirects('AboutUs')}>
                            <View style={styles.row}>
                                <Copyright size={20} color={theme.colors.text}/>
                                <AppSpacing gap={8} isHorizontal={true}/>
                                <Text style={{fontWeight:'bold', color: theme.colors.text}}>About Us</Text>
                            </View>
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.menu} onPress={() => handleRedirects('ContactUs')}>
                            <View style={styles.row}>
                                <Sms size={20} color={theme.colors.text}/>
                                <AppSpacing gap={8} isHorizontal={true}/>
                                <Text style={{fontWeight:'bold', color: theme.colors.text}}>Contact Us</Text>
                            </View>
                        </TouchableOpacity>
                    </View>
                </View>
                <View>
                    {
                        state.user?.id &&
                        <TouchableOpacity style={styles.menu} onPress={logout}>
                            <View style={styles.row}>
                                <Logout size={20} color={theme.colors.text}/>
                                <AppSpacing gap={8} isHorizontal={true}/>
                                <Text style={{fontWeight:'bold', color: theme.colors.text}}>Logout</Text>
                            </View>
                        </TouchableOpacity>
                    }

                </View>
            </View>


        </DrawerContentScrollView>
    )
}
