import React, {useState} from 'react';
import {View, Text, StyleSheet, Platform} from "react-native";
import {getIsMobileDevice} from "../../utils/helpers";
import {AppTextInput} from "../../components/AppTextInput";
import {AppCard} from "../../components/AppCard";
import {useDimension} from "../../hooks/useDimension";
import {AppSpacing} from "../../components/AppSpacing";
import {AppButton} from "../../components/AppButton";
import {AppImage} from "../../components/AppImage";
import {AppTextButton} from "../../components/AppTextButton";
import {useTheme} from "@react-navigation/native";
import Constants from 'expo-constants';
import {Layout} from "../Layout";
import {useNavigate} from "react-router";
import useAppNavigation from "../../hooks/useNavigation";
import useUsersQuery from "../../hooks/useUsersQuery";
import {useToast} from "react-native-toast-notifications";

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        textAlign: 'center'
    }
})

export const ForgotPasswordScreen = () => {
    const isMobile = getIsMobileDevice();
    return(
        <Layout>
            <View style={[styles.container, {paddingVertical: 60, justifyContent: 'center' , alignItems: 'center'}]}>
                {isMobile ? <MobileView/> : <WebView/>}
            </View>
        </Layout>
    )
}
const MobileView = () => {
    const {width} = useDimension();
    return(
        <View style={{width: width, padding: 16}}>
            <Form/>
        </View>
    )
}
const WebView = () => {
    const {width} = useDimension();
    return(
        <View >
            <AppCard>
                <View style={{padding: 16, width: width * 0.3}}>
                    <Form/>
                </View>
            </AppCard>
        </View>
    )
}

const Form = () => {
    const theme = useTheme();
    const [isLoading, setIsLoading] = useState(false);
    const isMobile = getIsMobileDevice();
    const [email, setEmail] = useState('');
   const {redirectTo} = useAppNavigation();
   const {forgotPassword} = useUsersQuery();
   const toast = useToast();

   const sendLink = async() => {
       setIsLoading(true);
       const response = await forgotPassword({email: email});
       setIsLoading(false);
       if(response?.error) {
           toast.show(response?.message);
           return;
       }

       toast.show(response?.message);
   }
    return(
        <View style={{flex: 1}}>
            <View style={{justifyContent: 'center', alignItems: 'center'}}>
                <Text style={[styles.title, {color: theme.colors?.primaryText}]}>Forgot Password</Text>
                <AppSpacing gap={16}/>
                <Text style={{color: 'grey', fontWeight: 'bold'}}>Please enter your email address to get reset link</Text>
            </View>
            <AppSpacing gap={64}/>
            <AppTextInput value={email} onChangeText={e => setEmail(e)}  autoComplete={'email'} autoCorrect={false} autoCapitalize={'none'}  height={Platform.OS === 'web' ? 40 : 50} label={"Email"}/>
            <AppSpacing gap={16}/>
            <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                <AppTextButton  onPress={() => redirectTo('Login')} title={<Text>Already have an account? <Text style={{color: theme.colors.primary}}>Login</Text></Text>}/>
            </View>
            <AppSpacing gap={32}/>
            <AppButton loading={isLoading} onPress={sendLink} title={"Send Link"}/>
        </View>
    )
}
