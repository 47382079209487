import {View, Text, TouchableOpacity, StyleSheet} from "react-native";

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    icon: {
        marginRight: 8,
    },
    buttonText: {
        textDecorationLine: 'underline'
    }
})


export const AppTextButton = (props) => {
    const {icon, title, isUnderline = true, onPress, color = 'black'} = props;

    return(
        <TouchableOpacity onPress={onPress}>
            {icon && <View>{icon}</View>}
            <Text style={[styles.buttonText, {color: color, textDecorationLine: isUnderline ? 'underline' : null} ]}>
                {title}
            </Text>
        </TouchableOpacity>
    )
}
