import useAddressQuery from "../../../hooks/useAddressQuery";
import {StyleSheet, Text, View} from "react-native";
import {AppTextInput} from "../../../components/AppTextInput";
import {AppSpacing} from "../../../components/AppSpacing";
import {useEffect, useState} from "react";
import {ResponsiveRow} from "./ResponsiveRow";
import {getIsMobileDevice} from "../../../utils/helpers";
import {useToast} from "react-native-toast-notifications";
import {AppButton} from "../../../components/AppButton";

export const AddressForm = ({initialValues, onFormChange, onContinuePress}) => {
    const [address, setAddress] = useState(initialValues?.address);
    const {validateAddress} = useAddressQuery();
    const isMobile = getIsMobileDevice();
    const toast = useToast();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1500)
    }, [])

    const checkAddress = async() => {
        setLoading(true);
        const response = await validateAddress(address);
        if(response?.error) {
            toast.show('Unable to validate the address, Please try again.');
            return;
        }

        onFormChange({...initialValues, ...response, latitude: response?.lat, longitude: response?.lng, address: response?.formatted_address});
        setAddress(response?.formatted_address);
        setLoading(false);

    }

    useEffect(() => {
        // if(address && !loading && address !== initialValues?.address) {
        //     const delayDebounceFn = setTimeout(async() => {
        //         await checkAddress()
        //     }, 3000)
        //
        //     return () => clearTimeout(delayDebounceFn)
        // }
    }, [address])

    const setValidAddress = () => {
        if(!address) {
            setAddress(initialValues?.address)
        }
    }

    return(
        <View style={{paddingVertical: 16}}>
            <ResponsiveRow isMobile={isMobile}>
                <AppTextInput onChangeText={e => onFormChange({...initialValues, building_number: e})} value={initialValues?.building_number} placeholder={'Building Number'}/>
                <AppTextInput onChangeText={e => onFormChange({...initialValues, address: e})} value={initialValues?.address} placeholder={'Address'}/>
            </ResponsiveRow>
            {
                !isMobile && <AppSpacing gap={16}/>
            }
            <ResponsiveRow isMobile={isMobile}>
                <AppTextInput onChangeText={e => onFormChange({...initialValues, zip: e})} value={initialValues?.zip} placeholder={'ZIP Code'}/>
                <AppTextInput onChangeText={e => onFormChange({...initialValues, city: e})} value={initialValues?.city} placeholder={'City'}/>
            </ResponsiveRow>
            {
                !isMobile && <AppSpacing gap={16}/>
            }
            <ResponsiveRow isMobile={isMobile}>
                <AppTextInput onChangeText={e => onFormChange({...initialValues, state: e})} value={initialValues?.state} placeholder={'State'}/>
                <AppTextInput onChangeText={e => onFormChange({...initialValues, country: e})} value={initialValues?.country} placeholder={'Country'}/>
            </ResponsiveRow>
            <View style={{marginVertical: 16}}>
                <AppButton onPress={onContinuePress} title={"Save"}/>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    accordionHeader: {
        backgroundColor: 'white',
        borderRadius: 10,
        padding: 16
    },
    accordionBody: {
        backgroundColor: 'white',
        borderRadius: 10,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        paddingHorizontal: 16

    },
    accordionContent: {
        borderTopWidth: 1,
        borderTopColor: '#Dadada',
        paddingVertical: 16
    },
    addLogo: {
        width: '100%',
        height: 200,
        borderColor: "#DADADA",
        justifyContent: 'center',
        alignItems: 'center',
        borderStyle: 'dashed',
        borderWidth: 1,
        borderRadius: 16,
    },
    removeImage: {
        position: 'absolute',
        top: 8,
        right: 16,
        zIndex: 9,
    },
    imageEdit: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        zIndex: 9,
        width: 30,
        height: 30,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'red',
        borderRadius: 15,
    }
})
