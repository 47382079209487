import useRequest from "./useRequest";

const useCategoriesQuery = () => {
    const request = useRequest();

    const fetchCategories = () => {
        return request.get('/categories').then(res => res);
    }

    return {
        fetchCategories
    }
}

export default useCategoriesQuery;
