import React from 'react';
import {View, Image} from 'react-native';

export const FacebookIcon = ({size = 25, white = false}) => {
    const src = white ? require('../../assets/icons/facebook-white.png') : require('../../assets/icons/facebook.png');
    return <BaseIcon src={src} size={size}/>
}

export const TwitterIcon = ({size = 25, white = false}) => {
    const src = white ? require('../../assets/icons/twitter-white.png') : require('../../assets/icons/twitter.png');
    return <BaseIcon src={src} size={size}/>
}

export const InstagramIcon = ({size = 25, white = false}) => {
    const src = white ? require('../../assets/icons/instagram-white.png') : require('../../assets/icons/instagram.png');
    return <BaseIcon src={src} size={size}/>
}

export const StarIcon = ({size = 25}) => {
    const src = require('../../assets/icons/star.png');
    return <BaseIcon src={src} size={size}/>
}

export const HalfStarIcon = ({size = 25}) => {
    const src = require('../../assets/icons/rating.png');
    return <BaseIcon src={src} size={size}/>
}

const BaseIcon = ({size, src}) => {
    return(
        <View>
            <Image source={src} style={{width: size, height: size}}/>
        </View>
    )
}
