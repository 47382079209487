
import React, {useEffect} from 'react';
import {StyleSheet, ActivityIndicator, View} from 'react-native';
import {StatusBar} from "expo-status-bar";
import {useState} from "react";
import GlobalNavigator from "../navigations/GlobalNavigator";
import useTheme from "../hooks/useTheme";
import useBusinessesQuery from "../hooks/useBusinessesQuery";
import {useAppContext} from "../contexts/AppContext";
import * as Location from "expo-location";
import {setUser, updateLoadingStatus} from "../contexts/reducers";
import {apiInstance} from "../utils/service";
import useUsersQuery from "../hooks/useUsersQuery";

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    loadingContainer: {
        flex: 1,
        backgroundColor: 'white',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

const Root = (props) => {
    const {theme} = props;
    const [isLoading, setLoading] = useState(false);
    const {state, dispatch} = useAppContext();
    const {fetchRecommendedBusiness, fetchNearestBusinesses} = useBusinessesQuery();
    const {getMe} = useUsersQuery();

    useEffect(() => {
        (async() => {
            if(state?.authToken) {
                const response = await getMe();
                dispatch(setUser(response));
            }
        })()
    }, [state?.authToken])

    useEffect(() => {
        apiInstance.defaults.headers.post['Content-Type'] = 'multipart/form-data';
    }, [])

    useEffect(() => {
        (async() => {
            await getRecommended();
            await getCurrentLocation();
        })()
    }, [])


    const getCurrentLocation = async() => {
        Location.setGoogleApiKey('AIzaSyA5ytI-OJv3OjKklLTVwlTB7jL5wRu3WD4')
        let { status } = await Location.requestForegroundPermissionsAsync();
        if (status !== 'granted') {
            console.log('Permission to access location was denied');
            return;
        }
        let location = await Location.getCurrentPositionAsync({});
        Location.reverseGeocodeAsync({
            latitude: location.coords.latitude,
            longitude: location.coords.longitude,
        }).then((res) => {
            console.log({
                latitude: location.coords.latitude,
                longitude: location.coords.longitude,
                countryCode: res[0]?.isoCountryCode,
                name: `${res[0]?.name}, ${res[0]?.city}`
            });
            dispatch({
                type: 'UPDATE_DEFAULT_LOCATION',
                payload: {
                    latitude: location.coords.latitude,
                    longitude: location.coords.longitude,
                    countryCode: res[0]?.isoCountryCode,
                    name: `${res[0]?.name}, ${res[0]?.city}`
                }
            })
        });
    }

    const getRecommended = async() => {
        dispatch(updateLoadingStatus({...state.loadingStatus, isRecommendedLoading: true}));
        const response = await fetchRecommendedBusiness();
        dispatch(updateLoadingStatus({...state.loadingStatus, isRecommendedLoading: false}));
        dispatch({
            type: 'UPDATE_RECOMMENDED_BUSINESSES',
            payload: response
        });
    }

    return(
        <View style={styles.container}>
            <StatusBar backgroundColor="white" barStyle="dark-content" />
            {isLoading ? (
                <View style={styles.loadingContainer}>
                    <ActivityIndicator color={theme.colors.primary} />
                </View>
            ) : (
                <GlobalNavigator
                    theme={theme}
                />
            )}
        </View>
    )
}

export default Root;
