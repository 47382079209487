import React, {useEffect} from "react";
import {ActivityIndicator, useWindowDimensions, View} from "react-native";
import {Colors} from "../../themes/colors";
import {useNavigate, useParams} from "react-router";
import AsyncStorage from "@react-native-async-storage/async-storage";

export const ReferralScreen = () => {
    const {width, height} = useWindowDimensions();
    const params = useParams();
    const navigate = useNavigate();
    const referralId = params?.id;
    console.log('params', params)

    useEffect(() => {
        (async() => {
            if(referralId) {
                await AsyncStorage.setItem('referralId', referralId);
                navigate('/');
            }
        })()
    }, [params])

    return(
        <View style={{flex: 1, height: height, width : width, justifyContent: 'center', alignItems: 'center'}}>
            <ActivityIndicator color={Colors.primary}/>
        </View>
    )
}
