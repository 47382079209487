import React from 'react';
import {View, Text, StyleSheet} from "react-native";
import {Layout} from "../Layout";
import {AppSpacing} from "../../components/AppSpacing";

export const AboutUsScreen = () => {
    return(
        <Layout isStack={true}>
            <View style={{paddingVertical: 32, paddingHorizontal: 24}}>
                <Text style={{fontSize: 24, fontWeight: 'bold'}}>About US</Text>
                <AppSpacing gap={16}/>
                <Text style={styles.text}>Africa One Stop is the premier online directory for African businesses and organizations. Our platform connects people with diverse businesses and services all around the world. Whether you are looking for a local restaurant, a grocery store, a salon, or a professional service, Africa One Stop makes it easy for you to find what you need.</Text>
                <Text style={styles.title}>Our Mission Statement</Text>
                <Text style={styles.text}>At Africa One Stop, our mission is to provide a one-stop shop for people looking for African businesses and services. We aim to connect businesses with new customers and to help people find the services they need wherever they are in the world with ease. Our goal is to become the go-to platform for anyone looking for African businesses and services.</Text>
                <Text style={styles.title}>Our Vision</Text>
                <Text style={styles.text}>Our vision is to empower African businesses and entrepreneurs by providing them a platform to showcase their services and products. We believe that by making it easy for people to find African businesses and services, we can help to create economic opportunities and empowerment for communities across and outside the continent.</Text>
                <Text style={styles.title}>Our Values</Text>
                <Text style={styles.text}>At Africa One Stop, we are committed to providing our users and business partners the best possible service. Our values include the following:</Text>
                <View style={{paddingLeft: 24}}>
                    <Text style={styles.text}>• Diversity: We celebrate the diversity of African businesses and cultures, and we believe this diversity is one of our greatest strengths.</Text>
                    <Text style={styles.text}>• Accessibility: We believe everyone should have access to the services they need, and we are committed to making our platform as user-friendly as possible.</Text>
                    <Text style={styles.text}>• Transparency: We are open and transparent in all our dealings with our users and business partners.</Text>
                    <Text style={styles.text}>• Innovation: We are always looking for new ways to improve our platform and provide better service to our users.</Text>
                    <Text style={styles.text}>• Customer Service: We are dedicated to providing the best possible customer service and are always happy to help our users with any questions or concerns.</Text>
                </View>
                <AppSpacing gap={8}/>
               <Text style={styles.title}>Our Services</Text>
               <View style={{paddingLeft: 24}}>
                   <Text style={styles.text}>At Africa One Stop, we are committed to helping users find the African businesses and services they need quickly and easily. Our platform offers a range of services designed to make it easy for users to find and connect with the businesses and services that matter to them.</Text>
                   <Text style={styles.subHeader}>• Comprehensive Directory of African Businesses and Organizations</Text>
                   <Text style={styles.text}>Our directory includes various African businesses and organizations, including grocery stores, restaurants, fashion designers, salons, professional services, and more. We work hard to ensure that our directory is comprehensive and up-to-date, so users can find the businesses they need quickly and easily.</Text>
                   <Text style={styles.subHeader}>• User-Friendly Search and Filtering Tools</Text>
                   <Text style={styles.text}>Our search and filtering tools are designed to make it easy for users to find the businesses and services they need. Users can search by location, category, or keyword and filter results based on user ratings, price, and other criteria.</Text>
                   <Text style={styles.subHeader}>• User Reviews and Ratings</Text>
                   <Text style={styles.text}>We understand users want to make informed decisions when choosing a business or service. That's why we allow users to leave reviews and ratings for the businesses and services listed on our platform. This helps users make more informed decisions and promotes transparency and accountability among businesses.</Text>
                   <Text style={styles.subHeader}>• Easy-to-Use Interface</Text>
                   <Text style={styles.text}>Our platform is designed to be easy for desktop and mobile devices. Users can access our platform from anywhere, anytime, and easily search for and connect with the businesses and services they need.</Text>
                   <Text style={styles.subHeader}>• Other Services</Text>
                   <Text style={styles.text}>In addition to our core services, we offer a range of services designed to help businesses and organizations connect with users. These include paid subscriptions for businesses that want additional features and visibility on our platform and advertising and promotional opportunities for businesses looking to reach a wider audience.</Text>
               </View>
                <Text style={styles.header}>What Sets Us Apart?</Text>
                <Text style={styles.content}>We are committed to providing the best possible experience for our users and businesses at Africa One Stop. We stand out from our competitors in several ways:</Text>
                <Text style={styles.bullet}>• The comprehensive and up-to-date directory of African businesses and organizations</Text>
                <Text style={styles.bullet}>• User-friendly search and filtering tools to help users find what they need quickly and easily</Text>
                <Text style={styles.bullet}>• User reviews and ratings to promote transparency and accountability among businesses</Text>
                <Text style={styles.bullet}>• An easy-to-use interface that works on desktop and mobile devices</Text>
                <Text style={styles.bullet}>• Additional services and opportunities for businesses to connect with users</Text>

                <Text style={styles.content}>Overall, Africa One Stop is the best platform for users and businesses looking to connect with the African community. Our commitment to transparency, accountability, and user satisfaction sets us apart from our competitors and makes us the go-to platform for African businesses and services.</Text>

                <Text style={styles.header}>Our Team</Text>
                <Text style={styles.content}>At Africa One Stop, we are proud of our dedicated team of professionals who work tirelessly to ensure that our platform provides the best possible service to our users and business partners. Our team includes web development, digital marketing, customer service, and business development experts.</Text>

                <Text style={styles.header}>Testimonials</Text>
                <View style={styles.testimonials}>
                    <Text style={styles.quote}>"Finding African businesses in my area has never been easier. Thanks, Africa One Stop!" - Naomi, Lagos</Text>
                    <Text style={styles.quote}>"I've found new customers for my business since I listed it on Africa One Stop. It's been a game-changer for me." - John, Dubai.</Text>
                    <Text style={styles.quote}>"Africa One Stop is the best thing that happened to my business this year!" - Amaka, Dallas</Text>
                </View>
            </View>
        </Layout>
    )
}

const styles= StyleSheet.create({
    text: {
        marginVertical: 4
    },
    subHeader: {
        fontWeight: 'bold',
        marginVertical: 8
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        marginVertical: 8
    },
    header: {
        fontSize: 20,
        fontWeight: 'bold',
        marginVertical: 8
    },
    content: {
        marginVertical: 4
    },
    bullet: {
        marginLeft: 16
    },
    testimonials: {
        marginVertical: 4
    },
    quote: {
        marginVertical: 4
    }
})
