import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {Layout} from "../Layout";


const PrivacyScreen = () => {
    return(
        <Layout>
            <View style={{padding: 24}}>
                <Text style={styles.header}>PRIVACY POLICY</Text>
                <Text style={styles.text}>At Africa One Stop, we are committed to protecting your privacy and the security of your personal information. This privacy policy explains how we collect, use, and share your information when you use this platform.</Text>
                <Text style={styles.text}>By using this platform, you agree to the terms of this privacy policy. You should not use this platform if you disagree with this privacy policy.</Text>

                <Text style={styles.subHeading}>Information We Collect</Text>
                <Text style={styles.text}>We may collect the following types of information when you use this platform:</Text>
                <View style={styles.bullet}>
                    <Text style={styles.bulletText}>- Personal Information: We may collect your name, email address, phone number, and other personal information when you create a user account or submit a feedback form.</Text>
                    <Text style={styles.bulletText}>- Usage Information: We may collect information about how you use our platforms, such as the pages you visit and your actions.</Text>
                    <Text style={styles.bulletText}>- Device Information: We may collect information about the device you use to access our platform, such as your IP address, browser type, and operating system.</Text>
                </View>

                <Text style={styles.subHeading}>How We Use Information</Text>
                <Text style={styles.text}>We use the information we collect to:</Text>
                <View style={styles.bullet}>
                    <Text style={styles.bulletText}>- Provide and improve our platform</Text>
                    <Text style={styles.bulletText}>- Communicate with you about your account and our platform</Text>
                    <Text style={styles.bulletText}>- Respond to your inquiries and feedback</Text>
                    <Text style={styles.bulletText}>- Analyze usage of our platform to improve our services</Text>
                </View>
                <Text style={styles.header}>Information Sharing</Text>
                <Text style={styles.content}>
                    We may share your information with third-party service providers that help our platform offer you effective business directory services. We may also share your information with other platform users in certain circumstances, such as when you submit a review or rating of a business.
                    We may also share your information as required by law or to protect our legal rights.
                </Text>
                <Text style={styles.header}>Security Measures</Text>
                <Text style={styles.content}>
                    We take appropriate measures to protect your personal information from unauthorized access, disclosure, or destruction. We use industry-standard security technologies and procedures to safeguard your information.
                </Text>
                <Text style={styles.header}>Children's Privacy</Text>
                <Text style={styles.content}>
                    Our platform is not intended for use by children under the age of 18. We do not knowingly collect personal information from children under 18.
                </Text>
                <Text style={styles.header}>Changes To Privacy Policy</Text>
                <Text style={styles.content}>
                    We may update this privacy policy from time to time. We will notify you by email or posting a notice on our platform if we make any material changes to this policy.
                </Text>
                <Text style={styles.content}>Thank you for using Africa One Stop. If you have any questions or concerns about this privacy policy, please contact us using the information provided on our contact page.</Text>

            </View>
        </Layout>
    )
}

const styles = StyleSheet.create({
    subHeading: {
        fontWeight: 'bold',
        marginVertical: 8
    },
    bulletText: {
        marginVertical: 4
    },
    text: {
        marginVertical: 4
    },
    subHeader: {
        fontWeight: 'bold',
        marginVertical: 8
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        marginVertical: 8
    },
    header: {
        fontSize: 20,
        fontWeight: 'bold',
        marginVertical: 8
    },
    content: {
        marginVertical: 4
    },
    bullet: {
        marginLeft: 16
    },
    testimonials: {
        marginVertical: 4
    },
    quote: {
        marginVertical: 4
    }
})

export default PrivacyScreen;
