export const updateLoginModalStatus = (data) => {
    return {
        type: 'UPDATE_LOGIN_MODAL_STATUS',
        payload: data
    }
}

export const updateLoadingStatus = (data) => {
    return{
        type: 'UPDATE_LOADING_STATUS',
        payload: data
    }
}

export const setAuthToken = (data) => {
    return{
        type: 'SET_TOKEN',
        payload: data
    }
}

export const unsetAuthToken = (data) => {
    return{
        type: 'UNSET_TOKEN',
        payload: null
    }
}

export const setUser = (data) => {
    return{
        type: 'SET_USER',
        payload: data
    }
}

export const setCategories = (data) => {
    return{
        type: 'SET_CATEGORIES',
        payload: data
    }
}
