import React from 'react';
import {View, StyleSheet, Image, TouchableOpacity, Platform} from 'react-native';
import {MobileMenu} from "../navigations/MobileMenu";
import {ArrowLeft, ArrowLeft2, HambergerMenu, Menu} from "iconsax-react-native";
import {DrawerActions, useNavigation, useTheme} from "@react-navigation/native";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import {AppSpacing} from "../components/AppSpacing";
import {Dimensions} from "react-native";
import {useNavigate} from "react-router";

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 100,
        width: '100%',
        paddingHorizontal: 8
    },
    logo: {
        height: 40,
        width: 40,
    },
    menuButton: {
        height: 40,
        width: 40,
        justifyContent: 'center',
        alignItems: 'center'
    }
})

export const MobileHeader = ({isStack = false}) => {
    const theme = useTheme();
    const insets = useSafeAreaInsets();
    const navigation = useNavigation();
    const navigate = useNavigate();
    const onPressMenu = () => {
        navigation.dispatch(DrawerActions.openDrawer());
    }

    const onBackPress = () => {
        if(Platform.OS === 'web') {
            navigate('/');
        }
        else{
            navigation.goBack();
        }
    }

    return(
        <View style={[styles.container, {backgroundColor: theme.colors.primary, paddingTop: insets.top ? insets.top : 16}]}>
           <View style={{flex: 1}}>
               {
                   isStack
                       ?
                       <TouchableOpacity activeOpacity={0.8} onPress={onBackPress} style={styles.menuButton}>
                           <ArrowLeft2 size={25} color={'white'}/>
                       </TouchableOpacity>
                       :
                       <TouchableOpacity activeOpacity={0.8} onPress={onPressMenu} style={styles.menuButton}>
                           <HambergerMenu size={25} color={'white'}/>
                       </TouchableOpacity>
               }

           </View>
            <Image style={{width: 100, height: 40}} resizeMode='contain' source={require('../../assets/logo-cropped.png')}/>
            <View style={{flex: 1}}/>
        </View>
    )
}
