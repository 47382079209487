import React, {useEffect, useRef, useState} from "react";
import {View, Text, StyleSheet, TouchableOpacity, ActivityIndicator, Platform, Modal, Pressable} from 'react-native';
import {AppButton} from "../../components/AppButton";
import {Layout} from "../Layout";
import {AppSpacing} from "../../components/AppSpacing";
import {
    ArrowDown2,
    ArrowUp2,
    CloseCircle,
    Edit,
    Edit2,
    Gallery,
    GalleryExport,
    More,
    PenClose
} from "iconsax-react-native";
import {AppCard} from "../../components/AppCard";
import {AppImage} from "../../components/AppImage";
import {getImagePayload, getIsMobileDevice} from "../../utils/helpers";
import * as ImagePicker from 'expo-image-picker';
import useAttachmentsQuery from "../../hooks/useAttachmentsQuery";
import {useToast} from "react-native-toast-notifications";
import useBusinessesQuery from "../../hooks/useBusinessesQuery";
import {useRoute} from "@react-navigation/core";
import {redirect, useNavigate, useParams} from "react-router";
import useTheme from "../../hooks/useTheme";
import {BusinessStatus} from "../../components/BusinessStatus";
import {GeneralForm} from "./components/GeneralForm";
import {AddressForm} from "./components/AddressForm";
import {SocialForm} from "./components/SocialForm";
import {TimingForm} from "./components/TimingForm";
import {useNavigation} from "@react-navigation/native";
import useAppNavigation from "../../hooks/useNavigation";
import {useAppContext} from "../../contexts/AppContext";
import {Colors} from "../../themes/colors";
import useUsersQuery from "../../hooks/useUsersQuery";

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    accordionHeader: {
        backgroundColor: 'white',
        borderRadius: 10,
        padding: 16
    },
    accordionBody: {
        backgroundColor: 'white',
        borderRadius: 10,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        paddingHorizontal: 16

    },
    accordionContent: {
        borderTopWidth: 1,
        borderTopColor: '#Dadada',
        paddingVertical: 16
    },
    addLogo: {
        width: '100%',
        height: 200,
        borderColor: "#DADADA",
        justifyContent: 'center',
        alignItems: 'center',
        borderStyle: 'dashed',
        borderWidth: 1,
        borderRadius: 16,
    },
    removeImage: {
        position: 'absolute',
        top: 8,
        right: 16,
        zIndex: 9,
    },
    imageEdit: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        zIndex: 9,
        width: 30,
        height: 30,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'red',
        borderRadius: 15,
    },
    moreButton: {
        width: 50,
        height: 50,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        borderRadius: 25
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.4)'
    },
    modalView: {
        margin: 20,
        backgroundColor: 'white',
        alignItems: 'center',
        borderRadius: 10,
        overflow: 'hidden'
    },
    modalButton: {
        padding: 10,
        flex: 1,
        backgroundColor: '#f3f3f3',
        alignItems: 'center',
    },
    textStyle: {
        fontWeight: 'bold'
    }
})

export const AddBusinessScreen = () => {
    const isMobile = getIsMobileDevice();
    const [currentIndex, setActiveIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [confirmModalVisible, setConfirmModalVisible] = useState(false);
    const {state, dispatch} = useAppContext();
    const navigation = useNavigation();
    const navigate = useNavigate();
    const [isDeleting, setIsDeleting] = useState(false);
    const [isPublishing, setIsPublishing] = useState(false);
    let id = '';
    if(Platform.OS === 'web') {
        const params = useParams();
        id = params?.id
    }
    else{
        const route = useRoute();
        const params = route?.params;
        id = params?.id
    }

    const initialValues = {
        name: '',
        category_id: '',
        contact_person: '',
        email: '',
        about: '',
        address: '',
        building_number: '',
        country: '',
        city: '',
        state: '',
        zip: '',
        longitude: '',
        latitude: '',
        cover_images: [],
        logo: '',
        phone: '',
        alternative_phone: '',
        tags: [],
        timing: {
            "SUN": "",
            "MON" : "",
            "TUE" : "",
            "WED" : "",
            "THU" : "",
            "FRI" : "",
            "SAT" : "",
        },
        facebook: '',
        instagram: '',
        twitter: '',
        website: ''
    }
    const [business, setBusiness] = useState(initialValues);
    const [coverImageChanged, setIsCoverImageChanged] = useState(false);

    const {getBusinessById, togglePublish} = useBusinessesQuery();
    const {redirectTo} = useAppNavigation();
    const {getUpgradeStatus} = useUsersQuery();

    useEffect(() => {
        if(id) {
            (async() => {
                await refreshBusiness(id);
                setLoading(false);
            })()
        }
        else{
            setLoading(false);
        }

    }, [id])

    const refreshBusiness = async(id) => {
        const response = await getBusinessById(id);
        setBusiness(response);
    }

    const toast = useToast();
    const {addBusiness, updateBusiness, deleteBusiness, addPublishableBusiness} = useBusinessesQuery();
    const theme = useTheme();

    const onContinuePress = async() => {
        if(!business?.name) {
            toast.show('Business Name is required');
            return;
        }

        if(!business?.category_id) {
            toast.show('Category is required');
            return;
        }

        if(!business?.phone) {
            toast.show('Phone is required');
            return;
        }

        if(!business?.email) {
            toast.show('Email is required');
            return;
        }

        if(!business?.about) {
            toast.show('About is required');
            return;
        }

        if(!business?.logo) {
            toast.show('Please add a logo');
            return;
        }

        if(business?.id && !business?.zip) {
            toast.show('Please add a valid ZIP code');
            return;
        }

        if(!business?.id) {
            delete business?.zip;
        }

        let response = {};
        if(business?.id) {
            response = await updateBusiness(business, business?.id);
        }
        else{
            response = await addBusiness(business);

        }

        if(response?.error) {
            toast.show(response?.message);
            return;
        }

        setBusiness(response);
        if(currentIndex === 3 ) {
            setActiveIndex(null)
        }
        else{
            setActiveIndex(currentIndex + 1)
        }
    }

    const onFormChange = (data) => {
        if(business?.cover_images !== data?.cover_images) {
            setIsCoverImageChanged(true);
        }
        else{
            setIsCoverImageChanged(false);
        }

        setBusiness({
            ...business,
            ...data
        })
    }

    useEffect(() => {
        (async() => {
            if(business?.id && coverImageChanged) {
                const response = await updateBusiness(business, business?.id);
                if(response?.error) {
                    toast.show(response?.message);
                    return;
                }
                setBusiness(response);
                setIsCoverImageChanged(false);
            }
        })()
    }, [business?.cover_images])

    let statusColor = 'grey';
    if(business?.status === 'Draft') {
        statusColor = 'orange'
    }
    else if(business?.status === 'Published') {
        statusColor = 'green'
    }

    const isPublishable = business?.id && business?.cover_images?.length > 0 && business?.logo;

    const onPublishPress = async() => {
        console.log('business?.is_active', business?.is_active);
        if(!business?.is_active) {
            const upgradeResponse = await getUpgradeStatus();
            if(upgradeResponse?.error) {
                toast.show("Something went wrong.");
                return;
            }

            const upgradeStatus = upgradeResponse?.data;
            console.log('upgradeStatus 1', upgradeStatus === 'upgrade_required')
            console.log('upgradeStatus 2', upgradeStatus.toString() === 'upgrade_required')

            if(upgradeStatus === 'upgrade_required') {
                const response = await addPublishableBusiness(business?.id, {test: 1});
                console.log('response', response);
                if(Platform.OS === 'web') {
                    navigate('/payments/subscription')
                }
                else{
                    navigation.navigate('Subscription')
                }
            }
            else{
                await initPublish();
            }
        }
        else{
            console.log('cehck 2');
            await initPublish();
        }
    }

    const initPublish = async () => {
        setIsPublishing(true);
        const response = await togglePublish(business?.id, business?.is_active === 1 ? 0 :1);
        setIsPublishing(false);
        if(response?.error) {
            toast.show(response?.message);
            return;
        }

        await refreshBusiness(business?.id);
    }

    const confirmDelete = async() => {
        setConfirmModalVisible(true);
    }
    const onDeletePress = async() => {
        setConfirmModalVisible(false);
        setIsDeleting(true);
        const response = await deleteBusiness(business?.id);
        if(response?.error) {
            toast.show(response?.message);
            setIsDeleting(false);
            return;
        }

        await refreshBusiness(business?.id);
        setIsDeleting(false);

        if(Platform.OS === 'web') {
            window.location.href = ('/profile');
        }
        else{
            navigation.navigate('Profile', {refresh: true})
        }
    }

    return(
        <Layout isStack={true}>
            {
                loading ?
                    <View style={{justifyContent: 'center', alignItems: 'center', minHeight: 300}}>
                        <ActivityIndicator color={theme.colors.primary}/>
                    </View>
                    :
                    <>
                        <View style={[styles.row, {padding: 16, justifyContent: 'space-between'}]}>
                            <View>
                                <Text style={{fontSize: 20, marginBottom: 4, fontWeight: 'bold'}}>Setup Business</Text>
                                {!isMobile && <BusinessStatus status={business?.status}/>}
                            </View>
                        </View>
                        <View style={{padding: 16, paddingBottom: 0}}>
                            {isMobile && <BusinessStatus status={business?.status}/>}
                        </View>
                        <View style={{flexDirection: isMobile ? 'column' : 'row', alignItems: isMobile ? null : 'flex-start', paddingHorizontal: 16, paddingVertical: 32}}>
                            <View style={{flex: isMobile ? null : 2}}>
                                <CollapsableView  onPress={() => setActiveIndex(0)} showContent={currentIndex === 0} title={"General Information"}>
                                    <GeneralForm
                                        initialValues={business}
                                        onContinuePress={onContinuePress}
                                        onFormChange={onFormChange}
                                    />
                                </CollapsableView>
                                <AppSpacing gap={16}/>
                                <CollapsableView onPress={() => setActiveIndex(business?.id ? 1 : 0)} showContent={currentIndex === 1} title={"Location"}>
                                    <AddressForm initialValues={business}
                                                 onContinuePress={onContinuePress}
                                                 onFormChange={onFormChange}/>
                                </CollapsableView>
                                <AppSpacing gap={16}/>
                                <CollapsableView onPress={() => setActiveIndex(business?.id ? 2 : 0)} showContent={currentIndex === 2} title={"Open and close timing"}>
                                    <TimingForm initialValues={business}
                                                onContinuePress={onContinuePress}
                                                onFormChange={onFormChange}/>
                                </CollapsableView>
                                <AppSpacing gap={16}/>
                                <CollapsableView onPress={() => setActiveIndex(business?.id ? 3 : 0)} showContent={currentIndex === 3} title={"Social Media"}>
                                    <SocialForm initialValues={business}
                                                onContinuePress={onContinuePress}
                                                onFormChange={onFormChange}/>
                                </CollapsableView>

                                <AppSpacing gap={16}/>
                            </View>
                            <AppSpacing gap={8} isHorizontal={true}/>
                            <View style={{flex: isMobile ? null :1}}>
                                {
                                    !isMobile && business?.id &&
                                    <View style={{paddingHorizontal: 16, marginBottom: 16}}>
                                        <ActionArea
                                            isDeleting={isDeleting}
                                            isPublishing={isPublishing}
                                            business={business}
                                            onDeletePress={confirmDelete}
                                            onPublish={onPublishPress}/>
                                    </View>

                                }
                                <BusinessImages initialValues={business}
                                                onContinuePress={onContinuePress}
                                                onFormChange={onFormChange}
                                                isMobile={isMobile}
                                                isDeleting={isDeleting}
                                                isPublishing={isPublishing}
                                                business={business}
                                                onDeletePress={confirmDelete}
                                                onPublish={onPublishPress}/>
                            </View>

                            {
                                isMobile && business?.id &&
                                <View style={{marginTop: 32}}>
                                    <ActionArea
                                        isDeleting={isDeleting}
                                        isPublishing={isPublishing}
                                        business={business}
                                        onDeletePress={confirmDelete}
                                        onPublish={onPublishPress}/>
                                </View>
                            }


                        </View>
                    </>
            }

            <Modal
                animationType="fade"
                transparent={true}
                visible={confirmModalVisible}
                onRequestClose={() => setConfirmModalVisible(false)}>
                <View style={styles.centeredView}>
                    <View style={[styles.modalView, { width: 350}]}>
                       <View style={{padding: 16, width: '100%'}}>
                           <Text style={{fontWeight: 'bold', marginBottom: 16}}>Delete Business?</Text>
                           <Text style={{marginBottom: 16}}>This action is permanent, Once you delete the business you won't be able to restore it.</Text>
                       </View>
                        <View style={{flexDirection: 'row', width: '100%', alignItems: 'center'}}>
                            <TouchableOpacity
                                style={styles.modalButton}
                                onPress={() => setConfirmModalVisible(!confirmModalVisible)}>
                                <Text style={styles.textStyle}>Cancel</Text>
                            </TouchableOpacity>
                            <TouchableOpacity
                                style={[styles.modalButton]}
                                onPress={onDeletePress}>
                                <Text style={[styles.textStyle, {color: 'red'}]}>Confirm</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            </Modal>
        </Layout>

    )
}

const CollapsableView = ({children, title, showContent, onPress}) => {
    return(
        <View>
            <TouchableOpacity activeOpacity={0.8} onPress={onPress} style={[styles.accordionHeader, {borderBottomLeftRadius: showContent ? 0 : 8, borderBottomRightRadius: showContent ? 0 : 8}]}>
                <View style={[styles.row, {justifyContent: 'space-between'}]}>
                    <Text style={{fontWeight: 'bold'}}>{title}</Text>
                    {
                        showContent ? <ArrowUp2 size={20}/> : <ArrowDown2 size={20}/>
                    }
                </View>
            </TouchableOpacity>
            {
                showContent &&  <View>
                    {children}
                </View>
            }
        </View>
    )
}


const BusinessImages = (
    {initialValues,
        onFormChange,
        isMobile}) => {
    const {upload} = useAttachmentsQuery();
    const toast = useToast();
    const [imageUploading, setImageUploading] = useState(false);
    const theme = useTheme();

    const pickImages = async () => {
        console.log('pic');
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            allowsMultipleSelection: true,
            allowsEditing: true,
            quality: 0.6,
            base64: true
        });

        console.log('result', result);

        if (!result.canceled) {
            console.log('result', result);
            const attachments = [];
            if(result.assets?.length > 0) {
                result.assets.forEach((item, index) => {
                    attachments[index] = item?.base64
                })
            }
            setImageUploading(true);
            const uploadResult = await upload({attachments: attachments});
            setImageUploading(false);
            if(uploadResult?.error) {
                toast.show('Image upload failed, try again');
                return;
            }
            const coverImages = uploadResult.map(item => item[0].banner);
            const existingImages = initialValues?.cover_images?.length > 0 ? initialValues?.cover_images : [];
            onFormChange({...initialValues, cover_images: [...existingImages, ...coverImages]})
        }
    };

    const handleRemove = (index) => {
        const coverImages = initialValues?.cover_images;
        coverImages.splice(index, 1);
        onFormChange({...initialValues, cover_images: coverImages})
    }

    return(
        <View>
            <AppCard marginHorizontal={isMobile ? 0 : 16} marginVertical={0}>
                <View style={{justifyContent: 'center', alignItems: 'center'}}>
                    <AppSpacing gap={16}/>
                    <Text style={{fontSize: 20, fontWeight: 'bold'}}>Cover Images</Text>
                    <AppSpacing gap={16}/>
                    <Text style={{color: 'grey', textAlign: 'center', width: '90%', marginLeft: '5%'}}>Add some cover images which represents your business and what you offer, this will be visible to business details page</Text>
                    <AppSpacing gap={32}/>
                    <TouchableOpacity onPress={pickImages} activeOpacity={0.8} style={styles.addLogo}>
                        {
                            imageUploading ?
                                <ActivityIndicator color={theme.colors.primary}/>
                                :
                                <Gallery size={50} color={'grey'}/>
                        }

                    </TouchableOpacity>
                    <AppSpacing gap={16}/>
                </View>
            </AppCard>
            {
                initialValues.cover_images?.length > 0 &&
                <View>
                    {
                        initialValues.cover_images.map((item, index) => <CoverImage onRemoveImage={() => handleRemove(index)} key={`co-${index}`} image={item}/>)
                    }

                </View>
            }


        </View>
    )
}

const CoverImage = ({image, onRemoveImage}) => {
    const isMobile = getIsMobileDevice();
    return(
        <View style={{width: '100%', paddingHorizontal: isMobile ? 0 : 16, marginVertical: 8, height: 200, borderRadius: 10}}>
            <AppImage isLocal={false} uri={image} width={'100%'} height={200}/>
            <TouchableOpacity onPress={onRemoveImage} activeOpacity={0.8} style={styles.removeImage}>
                <CloseCircle color={'red'} size={20}/>
            </TouchableOpacity>
        </View>
    )
}

const ActionArea = ({business, onDeletePress, onPublish, isPublishing, isDeleting}) => {
    return(
        <View>
            {
                business.is_active
                ?
                    <AppButton loading={isPublishing} color={'black'} onPress={onPublish} title={"Un Publish Business"}/>
                    :
                    <AppButton loading={isPublishing} color={'green'} onPress={onPublish} title={"Publish Business"}/>
            }

            <AppSpacing gap={16}/>
            <AppButton loading={isDeleting} color={'red'} onPress={onDeletePress} title={"Delete Business"}/>
        </View>
    )
}

const Subscription = () => {

}
