import {View} from "react-native";
import {getIsMobileDevice} from "../../../utils/helpers";

export const ResponsiveRow = ({children}) => {
    const isMobile = getIsMobileDevice();
    return(
        <View style={{flexDirection: isMobile ? 'column' : 'row'}}>
            {
                children.map((child, index) => {
                    return <View key={`ch-${index}`} style={{flex: isMobile ? null : 1, marginVertical: isMobile ? 8 : 0, marginLeft: isMobile ? 0 : (index === 0 ? 0 : 8), marginRight: isMobile  ? 0 : (index === 1 ? 0 : 8)}}>
                        {child}
                    </View>
                })
            }
        </View>
    )
}
