import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {Layout} from "../Layout";

const TermsScreen = () => {
    return(
        <Layout>
            <View style={{padding: 24}}>
                <Text style={{fontSize: 22, fontWeight: 'bold', marginVertical: 16}}>Terms & Conditions</Text>

                <Text>Welcome to Africa One Stop. Our platform provides a directory of African businesses and organizations and offers services to help users find the businesses and services they need. By using this platform, you agree to the following terms and conditions:</Text>

                <Text style={styles.title}>1. Acceptance of Terms</Text>
                <Text style={styles.content}>By using this platform, you agree to these terms and conditions and our privacy policy. You should not use this platform if you do not agree to these terms and conditions or our privacy policy.</Text>

                <Text style={styles.title}>2. User Accounts</Text>
                <Text style={styles.content}>To use certain features of this platform, you may be required to create a user account. You are responsible for maintaining the confidentiality of your account information, including your username and password. You are also responsible for all activities that occur under your account.</Text>

                <Text style={styles.title}>3. User Conduct</Text>
                <Text style={styles.content}>You agree to use this platform only for lawful purposes and in a manner that does not infringe on the rights of others. You agree not to use this platform to:</Text>
                <Text style={styles.bullet}>- Post any content that is unlawful, harmful, or offensive</Text>
                <Text style={styles.bullet}>- Use our platform to send spam or unsolicited messages</Text>
                <Text style={styles.bullet}>- Impersonate another person or entity</Text>
                <Text style={styles.bullet}>- Intentionally or unintentionally violate any applicable laws or regulations</Text>
                <Text style={styles.bullet}>- Engage in any activity that interferes with the proper functioning of our platform</Text>

                <Text style={styles.title}>4. Intellectual Property Rights</Text>
                <Text style={styles.content}>All content on our platform, including text, graphics, logos, and images, is the property of Africa One Stop or its content suppliers and is protected by copyright and other intellectual property laws. You may not use any of our content without our express written consent.</Text>

                <Text style={styles.title}>5. Liability</Text>
                <Text style={styles.content}>Africa One Stop is not responsible for any losses or damages that may result from your use of this platform. We do not warrant that our platform will be error-free or uninterrupted, and we are not responsible for any disruptions or interruptions that may occur.</Text>

                <Text style={styles.title}>6. Termination and Suspension</Text>
                <Text style={styles.content}>We reserve the right to terminate or suspend your account at any time and for any reason, including if we believe you have violated these terms and conditions. We also reserve the right to stop providing this service in the case of technical issues that are common with technological platforms without being liable to anyone.</Text>

                <Text style={styles.title}>Miscellaneous</Text>
                <Text style={styles.content}>These terms and conditions represent the entire agreement between you and Africa One Stop and supersede any other agreements or understandings between you and us. We reserve the right to modify these terms and conditions at any time, and your continued use of our platform after any changes will be deemed acceptance of those changes.</Text>

                <Text style={styles.content}>Thank you for using Africa One Stop. If you have any questions or concerns about these terms and conditions, please contact us using the information provided on our contact page.</Text>
            </View>
        </Layout>
    )
}

const styles = StyleSheet.create({
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        marginVertical: 8
    },
    header: {
        fontSize: 20,
        fontWeight: 'bold',
        marginVertical: 8
    },
    content: {
        marginVertical: 4
    },
    bullet: {
        marginLeft: 16
    },
})

export default TermsScreen;
