import React, {useEffect, useState} from 'react';
import {View, Text, StyleSheet, Platform} from "react-native";
import {getIsMobileDevice} from "../../utils/helpers";
import {AppTextInput} from "../../components/AppTextInput";
import {AppCard} from "../../components/AppCard";
import {useDimension} from "../../hooks/useDimension";
import {AppSpacing} from "../../components/AppSpacing";
import {AppButton} from "../../components/AppButton";
import {AppImage} from "../../components/AppImage";
import {AppTextButton} from "../../components/AppTextButton";
import {useTheme} from "@react-navigation/native";
import Constants from 'expo-constants';
import {Layout} from "../Layout";
import {redirect, useNavigate} from "react-router";
import useAppNavigation from "../../hooks/useNavigation";
import {useToast} from "react-native-toast-notifications";
import useUsersQuery from "../../hooks/useUsersQuery";
import {useAppContext} from "../../contexts/AppContext";
import {setAuthToken} from "../../contexts/reducers";
import {useRoute} from "@react-navigation/core";
import AsyncStorage from "@react-native-async-storage/async-storage";

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        textAlign: 'center'
    }
})

export const SignupScreen = () => {
    let params = {};
    if(Platform.OS === 'web') {
        const urlSearchParams = new URLSearchParams(window.location.search);
        params = Object.fromEntries(urlSearchParams.entries());
    }
    else{
        const route = useRoute();
        params = route.params;
    }
    const prefilledEmail = params?.email;
    const isMobile = getIsMobileDevice();
    const [formData, setFormData] = useState({
        email: prefilledEmail ? prefilledEmail : null,
        password: null,
        password_confirmation: null,
    })
    const [loading, setLoading] = useState(false);

    const toast = useToast();
    const {register} = useUsersQuery();
    const {redirectTo} = useAppNavigation();

    const {state, dispatch} = useAppContext();

    useEffect(() => {
        if(Platform.OS === 'web'){
            document.title = "Create Account"
        }
    }, [])

    const onSignupPress = async() => {
        // if(!formData?.name) {
        //     toast.show('Name is required');
        //     return;
        // }
        //
        // if(!formData?.email) {
        //     toast.show('Email address is required');
        //     return;
        // }
        //
        // if(!formData?.password) {
        //     toast.show('Password is required');
        //     return;
        // }
        //
        // if(formData?.password !== formData?.password_confirmation) {
        //     toast.show('Password & Confirm Password does not match');
        //     return;
        // }
        //
        // setLoading(true);

        const referralId =  await AsyncStorage.getItem('referralId');
        if(referralId) {
            formData.referral_id = referralId;
        }

        const response = await register(formData);
        setLoading(false);
        if(response?.error) {
            toast.show(response?.message);
            return;
        }

        dispatch(setAuthToken(response?.token));
        if(referralId) {
            await AsyncStorage.removeItem('referralId');
        }
        redirectTo("Home");
    }

    return(
        <Layout isStack={true}>
            <View style={[styles.container, {paddingVertical: 60, justifyContent: 'center' , alignItems: 'center'}]}>
                {isMobile
                    ?
                    <MobileView prefilledEmail={prefilledEmail} onFormChange={(data) => setFormData(data)} onSignupPress={onSignupPress} isButtonDisable={loading}/>
                    :
                    <WebView prefilledEmail={prefilledEmail} onFormChange={(data) => setFormData(data)} onSignupPress={onSignupPress} isButtonDisable={loading}/>}
            </View>
        </Layout>
    )
}
const MobileView = ({onFormChange, onSignupPress, isButtonDisable, prefilledEmail}) => {
    const {width} = useDimension();
    return(
        <View style={{width: width, padding: 16}}>
            <Form prefilledEmail={prefilledEmail} onFormChange={onFormChange} onSignupPress={onSignupPress} isButtonDisable={isButtonDisable}/>
        </View>
    )
}
const WebView = ({onFormChange, onSignupPress, isButtonDisable, prefilledEmail}) => {
    const {width} = useDimension();
    return(
        <View >
            <AppCard>
                <View style={{padding: 16, width: width * 0.3}}>
                    <Form
                        prefilledEmail={prefilledEmail}
                        onFormChange={onFormChange}
                        onSignupPress={onSignupPress}
                        isButtonDisable={isButtonDisable}/>
                </View>
            </AppCard>
        </View>
    )
}

const Form = ({onFormChange, onSignupPress, isButtonDisable, prefilledEmail}) => {
    const theme = useTheme();
    const {redirectTo} = useAppNavigation();

    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState(prefilledEmail ? prefilledEmail : '');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');

    useEffect(() => {
        onFormChange({
            name: name,
            email: email,
            password: password,
            password_confirmation: passwordConfirmation
        })
    }, [email, password, name, passwordConfirmation])

    return(
        <View style={{flex: 1}}>
            <View style={{justifyContent: 'center', alignItems: 'center'}}>
                <Text style={[styles.title, {color: theme.colors?.primaryText}]}>Let's create an account</Text>
                <AppSpacing gap={16}/>
                <Text style={{textAlign: 'center', color: 'grey', fontWeight: 'bold'}}>You will receive newsletters, notifications about businesses near you and much more</Text>
            </View>
            <AppSpacing gap={64}/>
            <AppTextInput onChangeText={e => setName(e)} autoCorrect={false} height={Platform.OS === 'web' ? 40 : 50} label={"Full Name"}/>
            <AppSpacing gap={16}/>
            <AppTextInput value={email} onChangeText={e => setEmail(e)} autoComplete={'email'} autoCorrect={false} autoCapitalize={'none'} height={Platform.OS === 'web' ? 40 : 50} label={"Email"}/>
            <AppSpacing gap={16}/>
            <AppTextInput onChangeText={e => setPassword(e)} autoComplete={'email'} autoCorrect={false} autoCapitalize={'none'} secureTextEntry={true} height={Platform.OS === 'web' ? 40 : 50} label={"Password"}/>
            <AppSpacing gap={16}/>
            <AppTextInput onChangeText={e => setPasswordConfirmation(e)} autoComplete={'email'} autoCorrect={false} autoCapitalize={'none'} secureTextEntry={true} height={Platform.OS === 'web' ? 40 : 50} label={"Confirm Password"}/>
            <AppSpacing gap={16}/>
            <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                <AppTextButton  onPress={() => redirectTo('Login')} title={<Text>Already have an account? <Text style={{color: theme.colors.primary}}>Login</Text></Text>}/>
            </View>
            <AppSpacing gap={32}/>
            <AppButton disable={isButtonDisable} onPress={onSignupPress} title={"Signup"}/>
        </View>
    )
}
