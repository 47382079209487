import useAttachmentsQuery from "../../../hooks/useAttachmentsQuery";
import {useToast} from "react-native-toast-notifications";
import {useAppContext} from "../../../contexts/AppContext";
import * as ImagePicker from "expo-image-picker";
import {getImagePayload, getIsMobileDevice} from "../../../utils/helpers";
import {StyleSheet, Text, TouchableOpacity, View, ActivityIndicator} from "react-native";
import {AppSpacing} from "../../../components/AppSpacing";
import {AppImage} from "../../../components/AppImage";
import {Edit2} from "iconsax-react-native";
import {AppTextInput} from "../../../components/AppTextInput";
import {AppDropdown} from "../../../components/AppDrodown";
import {AppButton} from "../../../components/AppButton";
import {ResponsiveRow} from "./ResponsiveRow";
import {useEffect, useState} from "react";
import useTheme from "../../../hooks/useTheme";

export const GeneralForm = ({initialValues, onFormChange, onContinuePress}) => {
    const {upload, uploadBase64} = useAttachmentsQuery();
    const toast = useToast();
    const {state} = useAppContext();
    const isMobile = getIsMobileDevice();
    const tagsString = initialValues?.tags && initialValues?.tags?.length > 0 ? initialValues?.tags.join(',') : '';
    const [tags, setTags] = useState(tagsString);
    const [isImageUploading, setIsImageUploading] = useState(false);
    const theme = useTheme();

    const pickImage = async () => {
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            allowsEditing: true,
            quality: 0.6,
            base64: true
        });

        if (!result.canceled) {
            setIsImageUploading(true);
            const uploadResult = await upload({attachment: result.assets[0]?.base64});
            setIsImageUploading(false);
            if(uploadResult?.error) {
                toast.show('Image upload failed, try again');
                return;
            }
            onFormChange({...initialValues, logo: uploadResult[0]?.square})
        }
    };

    const items = state.categories.map((item) => {
        return{
            label: item?.name,
            value: item?.id
        }
    })

    useEffect(() => {
        const values = tags && tags.split(',')?.length > 0 ? tags.split(',') : [];
        onFormChange({...initialValues, tags: values})
    }, [tags])

    return(
        <View style={{paddingVertical: 16}}>
            <AppSpacing gap={16}/>
            <View style={{flexDirection: 'row', justifyItems: 'space-between', alignItems: 'center'}}>
                <View style={{flex: 1}}>
                    <Text style={{fontWeight: 'bold'}}>Add a logo</Text>
                    <AppSpacing gap={16}/>
                    <Text>Add a logo which represents your business, this will be visible to public searches.</Text>
                </View>
                <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                    <View style={{position: 'relative', width: 150, height: 150}}>
                        {
                            isImageUploading &&
                            <View style={{position: 'absolute', left: 70, zIndex: 999, top: 70}}><ActivityIndicator color={theme.colors.primary}/></View>
                        }

                        <AppImage width={150} height={150} isLocal={!initialValues?.logo} uri={initialValues?.logo ? initialValues?.logo : require('../../../../assets/img/placeholder.png')}/>

                        {
                            !isImageUploading &&
                            <TouchableOpacity onPress={pickImage} style={styles.imageEdit}>
                                <Edit2 size={15} color={'white'}/>
                            </TouchableOpacity>
                        }

                    </View>
                </View>

            </View>
            <AppSpacing gap={32}/>
            <ResponsiveRow isMobile={isMobile}>
                <AppTextInput value={initialValues?.name} onChangeText={e => onFormChange({...initialValues, name: e})} placeholder={'Business Name'}/>
                <AppDropdown selectedValue={initialValues?.category_id} onChange={e => onFormChange({...initialValues, category_id: e})} items={items}/>
            </ResponsiveRow>
            {
                !isMobile &&   <AppSpacing gap={16}/>
            }
            <ResponsiveRow isMobile={isMobile}>
                <AppTextInput value={initialValues?.email} onChangeText={e => onFormChange({...initialValues, email: e})} placeholder={'Contact Email'}/>
                <AppTextInput value={initialValues?.phone} onChangeText={e => onFormChange({...initialValues, phone: e})} placeholder={'Contact Phone'}/>
            </ResponsiveRow>
            <AppSpacing gap={isMobile ? 8 :16}/>
            <AppTextInput value={initialValues?.website} onChangeText={e => onFormChange({...initialValues, website: e})} placeholder={'Website'}/>
            <AppSpacing gap={16}/>
            <AppTextInput value={initialValues?.about} onChangeText={e => onFormChange({...initialValues, about: e})} placeholder={'About Your Business'} multiline={true} height={150}/>
            <AppSpacing gap={16}/>
            <AppTextInput value={tags} onChangeText={e => setTags(e)} placeholder={'Tags, use comma to separate them eg(restaurant, chinese...)'} multiline={true} height={150}/>

            <View style={{marginVertical: 16}}>
                <Text style={{color: 'red', marginBottom: 8, fontWeight: 'bold'}}>Disclaimer:</Text>
                <Text style={{color: 'black'}}>Only businesses owned or authorized by you will be published in our app and website. We verify ownership before approving submissions. Non-compliant businesses will not be published.</Text>
            </View>

            <View style={{marginVertical: 16}}>
                <AppButton onPress={onContinuePress} title={initialValues?.id ? "Save" : "Continue"}/>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    accordionHeader: {
        backgroundColor: 'white',
        borderRadius: 10,
        padding: 16
    },
    accordionBody: {
        backgroundColor: 'white',
        borderRadius: 10,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        paddingHorizontal: 16

    },
    accordionContent: {
        borderTopWidth: 1,
        borderTopColor: '#Dadada',
        paddingVertical: 16
    },
    addLogo: {
        width: '100%',
        height: 200,
        borderColor: "#DADADA",
        justifyContent: 'center',
        alignItems: 'center',
        borderStyle: 'dashed',
        borderWidth: 1,
        borderRadius: 16,
    },
    removeImage: {
        position: 'absolute',
        top: 8,
        right: 16,
        zIndex: 9,
    },
    imageEdit: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        zIndex: 9,
        width: 30,
        height: 30,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'red',
        borderRadius: 15,
    }
})
