import React from 'react';
import {View, Text, StyleSheet, TouchableOpacity, Platform, Linking, Pressable} from 'react-native';
import {useNavigation, useTheme} from "@react-navigation/native";
import {Facebook, Heart, Instagram} from "iconsax-react-native";
import {useDimension} from "../../../hooks/useDimension";
import {getIsMobileDevice, parseUrl} from "../../../utils/helpers";
import {AppCard} from "../../../components/AppCard";
import {AppImage} from "../../../components/AppImage";
import {AppSpacing} from "../../../components/AppSpacing";
import {Ratings} from "./Ratings";
import {FacebookIcon, InstagramIcon, TwitterIcon} from "../../../components/Icons";
import {useNavigate} from "react-router";
import useAppNavigation from "../../../hooks/useNavigation";
import useAnalyticsQuery from "../../../hooks/useAnanlyticsQuery";

const styles = StyleSheet.create({
    category: {
        alignItems: 'center',
        justifyContent: 'center',
        height: 40,
    },
    likeContainer: {
        position: 'absolute',
        top: 15,
        right: 16,
        flexDirection: 'row',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        borderRadius: 8,
        paddingVertical: 2,
        paddingHorizontal: 8,
        alignItems: 'center',
        justifyContent: 'center'
    }
})

export const BusinessCard = ({business, parentWidth, likeBusiness}) => {
    const theme = useTheme();
    const {width} = useDimension();
    const isMobile = getIsMobileDevice();
    const  {redirectTo} = useAppNavigation();
    const cardsPerRow = isMobile ? 1 : 4;
    const cardWidth = (parentWidth/ cardsPerRow) - 16;
    const navigation = useNavigation();
    const navigate = useNavigate()
    const {addAnalyticEvent} = useAnalyticsQuery();

    const handleClick = () => {
        if(Platform.OS === 'web') {
            navigate(`/business/${business?.slug}`)
        }
        else{
            navigation.navigate('ViewBusiness', {slug: business?.slug})
        }
    }

    const onWebsiteClick = async(link) => {
        console.log('link', link);
        const payload = {
            event: 'website_click',
            business_id: business?.id
        }
        addAnalyticEvent(payload)
        if(link) {
            Linking.openURL(parseUrl(link));
        }
    }

    const onClickSocial = (link) => {
        if(link) {
            Linking.openURL(parseUrl(link));
        }
    }


    const makeCall = (phone) => {
        Linking.openURL(`tel:${phone.toString().replace(/\\s/g, "")}`);
    }


    return(
        <Pressable activeOpacity={0.8} onPress={handleClick}>
            <AppCard padding={0}>
                <View style={[styles.category, {backgroundColor: theme.colors.primary}]}>
                    <Text style={{fontWeight: 'bold', color: 'white'}}>{business?.category_name}</Text>
                </View>
                <View style={{position: 'relative', backgroundColor: '#f3f3f3'}}>
                    <AppImage width={cardWidth}
                              resizeMode={'cover'}
                              height={150}
                              roundness={0}
                              isLocal={false} uri={business?.cover_images?.length > 0 ? business?.cover_images[0] : `https://ui-avatars.com/api/?size=256&name=${business?.name}`}
                    />
                    <Pressable activeOpacity={0.8} onPress={likeBusiness} style={styles.likeContainer}>
                        <Heart variant={business?.has_liked ? 'Bold' : 'Linear'}  size={20} color={'#F9595F'}/>
                        <AppSpacing gap={4} isHorizontal={true}/>
                        <Text style={{lineHeight: 20}}>{business?.likes_count}</Text>
                    </Pressable>
                </View>
                <View style={{width: cardWidth, padding: 16, justifyContent: 'center', alignItems: 'center'}}>
                    <Text style={{fontWeight: 'bold'}}>{business?.name}</Text>
                    <AppSpacing gap={8}/>
                    <View style={{flexDirection: 'row'}}>
                        <Text style={{fontWeight: 'bold'}}>{business?.meta?.avg_rating}</Text>
                        <AppSpacing isHorizontal={true} gap={8}/>
                        <Ratings ratings={business?.meta?.avg_rating}/>
                        <AppSpacing isHorizontal={true} gap={8}/>
                        <Text style={{textDecorationLine: 'underline'}}>{business?.reviews_count} Reviews</Text>
                    </View>
                    {
                        business?.website &&
                        <>
                            <AppSpacing gap={8}/>
                            <TouchableOpacity onPress={() => onWebsiteClick(business?.website)}>
                                <Text style={{textAlign:'center', fontWeight: 'bold'}}>Visit Our Website</Text>
                            </TouchableOpacity>
                        </>

                    }

                    <AppSpacing gap={8}/>
                    <Pressable onPress={() => Linking.openURL(`https://maps.google.com/maps?q=${encodeURIComponent(business?.formatted_address)}`)} style={{width: '100%'}}>
                        <Text style={{textAlign: 'center'}}>
                            {business?.full_address}
                        </Text>
                    </Pressable>
                    <AppSpacing gap={8}/>
                    <Pressable onPress={() => makeCall(business?.phone)}>
                        <Text style={{fontWeight: 'bold'}}>Call Us - {business?.phone}</Text>
                    </Pressable>
                    <AppSpacing gap={8}/>
                    <View style={{justifyContent: 'center', alignItems: 'center'}}>
                        <Text>Follow Us</Text>
                        <AppSpacing gap={8}/>
                        <View style={{flexDirection: 'row'}}>
                            <Pressable onPress={() => onClickSocial(business?.facebook)}>
                                <FacebookIcon/>
                            </Pressable>
                            <AppSpacing gap={16} isHorizontal={true}/>
                            <Pressable onPress={() => onClickSocial(business?.twitter)}>
                                <TwitterIcon size={25}/>
                            </Pressable>
                            <AppSpacing gap={16} isHorizontal={true}/>
                            <Pressable onPress={() => onClickSocial(business?.instagram)}>
                                <InstagramIcon size={25}/>
                            </Pressable>
                        </View>
                    </View>
                </View>
            </AppCard>
        </Pressable>

    )
}
