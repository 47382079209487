import React from 'react';
import {View, StyleSheet} from 'react-native';

const styles = StyleSheet.create({
    card: {
        borderRadius: 12,
    },
    cardInner: {
        borderRadius: 12,
        overflow: 'hidden',
    },
})

export const AppCard = ({
                            children,
                            shadow = true,
                            padding = 16,
                            backgroundColor = 'white',
                            marginVertical = 8,
                            marginHorizontal = 8,
    overflow = 'hidden'
                        }) => {
    return (
        <View
            style={[
                styles.card,
                {
                    backgroundColor: backgroundColor,
                    marginHorizontal: marginHorizontal,
                    marginVertical: marginVertical,
                    zIndex: 999
                }
            ]}>
            <View style={[styles.cardInner, {overflow: overflow, padding: padding}]}>{children}</View>
        </View>
    )
}
