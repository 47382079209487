import {Layout} from "../Layout";
import {Text, View} from "react-native";
import {AppAccordion} from "../../components/AppAccordion";
import {AppSpacing} from "../../components/AppSpacing";

const FaqScreen = () => {
    return(
        <Layout>
            <View style={{padding: 16}}>
                <Text style={{fontWeight: 'bold', marginVertical: 16}}>Account and Profile </Text>
                <AppAccordion title={'How do I create a user account on Africa One Stop?'} content={'To create a user account on Africa One Stop, click the "Login" button on the homepage and follow the prompts.'}/>
                <AppSpacing gap={16}/>
                <AppAccordion backgroundColor={'white'} title={'How do I update my account information? '} content={'To update your account information, log in to your account and click the “Profile” button, then "Edit Profile" button.'}/>
                <AppSpacing gap={16}/>

                <Text style={{fontWeight: 'bold', marginVertical: 16}}>Adding a Business </Text>
                <AppAccordion  title={'How do I add my business to Africa One Stop?'} content={'To add your business to Africa One Stop, click the "Add Business" button on the homepage and follow the prompts. You cannot proceed to the next field until preceding questions are answered. You cannot proceed to add a location until business logo and at least one cover image has been added to your profile.'}/>
                <AppSpacing  gap={16}/>
                <AppAccordion backgroundColor={'white'} title={'What types of businesses can I add to Africa One Stop? '} content={'You can add African businesses or organizations to Africa One Stop, including grocery stores, restaurants, fashion designers, salons, professional services, and more.'}/>
                <AppSpacing gap={16}/>
                <AppAccordion title={'Do I have to pay to add my business to Africa One Stop?'} content={'Although it is free to use Africa One stop to locate African businesses and organizations near you, you need to subscribe monthly or annually to add your business to the platform.'}/>
                <AppSpacing gap={16}/>
                <AppAccordion backgroundColor={'white'} title={'How long does it take for my business to be listed on Africa One Stop after I submitted it?'} content={'We strive to review and approve new business listings as quickly as possible. The process typically takes a few business days but may take longer during periods of high volume.'}/>
                <AppSpacing gap={16}/>
                <AppAccordion title={'What if my business has more than one physical location and I need to include each of them?'} content={'Africa One Stop allows you to add up to 50 businesses on your profile. This includes businesses with more than one physical location, outlets or subsidiaries.'}/>
                <AppSpacing gap={16}/>
                <AppAccordion backgroundColor={'white'} title={'Is this free to use?'} content={'Yes, This application is free to use.'}/>
                <AppSpacing gap={16}/>

                <Text style={{fontWeight: 'bold', marginVertical: 16}}>Subscription</Text>

                <AppAccordion title={'What are the subscription options for Africa One Stop? '} content={'Africa One Stop offers monthly and annual subscriptions for businesses and organizations that want to list their services on our platform.'}/>
                <AppSpacing gap={16}/>
                <AppAccordion backgroundColor={'white'} title={'How do I cancel my subscription?'} content={"If you have purchased the subscription from Apple, you can manage the subscription from the Settings app of iPhone, If you have purchased the subscription from Google you can cancel it from Google Play app of Android Phone. If you have purchase the subscription from Web you can cancel it under your profile."}/>
                <AppSpacing gap={16}/>
                <AppAccordion title={'Do subscriptions automatically renew?'} content={'Yes, subscriptions are set to automatically renew unless canceled by the user.'}/>
                <AppSpacing gap={16}/>

                <Text style={{fontWeight: 'bold', marginVertical: 16}}>User Conduct </Text>

                <AppAccordion backgroundColor={'white'} title={'What is the code of conduct for using Africa One Stop? '} content={'We expect all users of Africa One Stop to conduct themselves in a respectful and professional manner. Please see our terms and conditions for more information.'}/>
                <AppSpacing gap={16}/>
                <AppAccordion title={'What should I do if I encounter inappropriate behavior on Africa One Stop?'} content={'If you encounter inappropriate behavior on Africa One Stop, please contact our support team using the information provided on our contact us page.'}/>
                <AppSpacing gap={16}/>
                <AppAccordion backgroundColor={'white'} title={'Can I leave a negative review of a business on Africa One Stop?'} content={'Yes, you can leave a review of a business on Africa One Stop. However, we expect all reviews to be honest and respectful, and we reserve the right to remove reviews that violate our code of conduct.'}/>
                <AppSpacing gap={16}/>

                <Text style={{fontWeight: 'bold', marginVertical: 16}}>Technical Support</Text>

                <AppAccordion title={'What should I do if I am having technical issues with Africa One Stop? '} content={'Please check our troubleshooting page for common issues and solutions. If you still need assistance, please contact our support team using the information provided on our contact us page.'}/>
                <AppSpacing gap={16}/>
                <AppAccordion backgroundColor={'white'} title={'What should I do if I forget my password for Africa One Stop?'} content={'If you forget your password for Africa One Stop, you can reset it by clicking the "Forgot Password" link on the login page.'}/>
                <AppSpacing gap={16}/>
                <AppAccordion title={'What web browsers are compatible with Africa One Stop?'} content={'Africa One Stop is compatible with all major web browsers, including Chrome, Safari, Firefox, and Edge.'}/>
                <AppSpacing gap={16}/>
                <AppAccordion backgroundColor={'white'} title={'Is there an app for African One Stop?'} content={'Yes, Africa One Stop is available on both App store and play store.'}/>
                <AppSpacing gap={16}/>

                <Text style={{fontWeight: 'bold', marginVertical: 16}}>Miscellaneous</Text>

                <AppAccordion title={'Does Africa One Stop endorse any businesses or organizations listed on its platform?'} content={'No, Africa One Stop does not endorse any businesses or organizations listed on its platform. We provide a directory of African businesses and organizations for informational purposes only.'}/>
                <AppSpacing gap={16}/>
                <AppAccordion backgroundColor={'white'} title={'How can I provide feedback or suggestions to Africa One Stop?'} content={' Please send us feedback using the information provided on our contact us page. We look forward to hearing from you!'}/>
                <AppSpacing gap={16}/>
                <AppAccordion title={'Is Africa One Stop available outside of Africa? '} content={' Yes, Africa One Stop is available worldwide as long as businesses and organizations are listed on our platform.'}/>
                <AppSpacing gap={16}/>
                <Text>
                    Thank you for using Africa One Stop. We hope these FAQs have been helpful. If you have any further questions or concerns, please contact our support team using the information provided on our support page.
                </Text>
            </View>
        </Layout>
    )
}

export default FaqScreen;
