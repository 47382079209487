import React from 'react';
import{View} from 'react-native';
import {BarChart, Grid, XAxis, YAxis} from 'react-native-svg-charts'
import {getIsMobileDevice} from "../../../utils/helpers";
export const Chart = ({chartData}) => {
    const fill = 'rgb(134, 65, 244)'
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    console.log('chartData', chartData);

    const data = chartData ? chartData : [];

    const contentInset = { top: 20, bottom: 20 }
    const isMobile = getIsMobileDevice();

    return (
        <View>
            <View style={{flexDirection: 'row' }}>
                <YAxis
                    data={data}
                    contentInset={contentInset}
                    svg={{
                        fill: 'grey',
                        fontSize: 10,
                    }}
                    numberOfTicks={10}
                    formatLabel={(value) => `${value}`}
                />
                <View style={{flex: 1}}>
                    <BarChart style={{ height: 200 }} data={data} svg={{ fill }} contentInset={{ top: 30, bottom: 30 }}>
                        <Grid />
                    </BarChart>
                </View>
            </View>

            <View style={{paddingRight: 16, paddingLeft: 26}}>
                <XAxis
                    style={{ marginHorizontal: isMobile ? -8 : 0 }}
                    data={data}
                    formatLabel={(value, index) => months[index]}
                    contentInset={{ left: 10, right: 10 }}
                    svg={{ fontSize: 10, fill: 'black' }}
                />
            </View>

        </View>

    )
}
