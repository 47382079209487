import useRequest from "./useRequest";

const useAttachmentsQuery = () => {
    const request = useRequest();

    const upload = async(payload) => {
        return await request.post(
            '/attachments',
            payload,
            {
                Accept: "application/json",
                "Content-Type": "multipart/form-data"
            }
            ).then(res => res);
    }

    const uploadBase64 = async(payload) => {
        return await request.post(
            '/attachments',
            JSON.stringify(payload),
            {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        ).then(res => res);
    }

    return {
        upload,
        uploadBase64
    }
}

export default useAttachmentsQuery;
