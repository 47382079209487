import React, {useEffect, useState} from 'react';
import {ImageBackground, StyleSheet, Text, TextInput, TouchableOpacity, View} from "react-native";
import {getIsMobileDevice} from "../../../utils/helpers";
import {SearchNormal} from "iconsax-react-native";
import {AppSpacing} from "../../../components/AppSpacing";
import {useDimension} from "../../../hooks/useDimension";
import {useTheme} from "@react-navigation/native";
import {AppCard} from "../../../components/AppCard";
import {AppTextInput} from "../../../components/AppTextInput";
import {AppButton} from "../../../components/AppButton";
import {AutoCompleteSearchInput} from "../../../components/AutocompleteSearchInput";
import {GoogleMapAutocomplete} from "../../../components/GoogleMapAutocomplete";

const styles = StyleSheet.create({
    banner: {
        flex: 1,
        backgroundColor: 'white'
    },
    overlay: {
        flex: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        justifyContent: 'center',
        alignItems: 'center',
    },
    bannerText: {
        fontSize: 30,
        fontWeight: 'bold',
        textAlign: 'center',
        color: 'white',
    },
    input: {
        height: 40,
        width: 350,
        backgroundColor: 'white',
        borderColor: 'white',
        outlineStyle: 'none',
        paddingLeft: 16,
    },
    inputContainer: {
        flexDirection: 'row',
        marginTop: 16
    },
    separator: {
        position: 'absolute',
        left: 0,
        height: 30,
        marginTop: 5,
        width: 1,
        backgroundColor: '#E0E0E0'
    },
    button: {
        height: 40,
        width: 150,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f3f3f3',
        paddingHorizontal: 16
    },
    roundedBorder: {
        borderRadius: 8
    }
})


export const ManinBanner = ({onChangeSearch, onSearchPress, initialValues}) => {
    const {height, width} = useDimension();
    const image = require('../../../../assets/img/main-banner.jpeg');
    const isMobile = getIsMobileDevice();
    const theme = useTheme();

    const elementWidth = width - 32

    return isMobile ?
        <MobileLayout
            initialValues={initialValues}
            onChangeSearch={onChangeSearch}
            onSearchPress={onSearchPress}
            theme={theme}/>
        :
        <DesktopLayout
            isMobile={isMobile}
            image={image}
            elementWidth={elementWidth}
            onSearchPress={onSearchPress}
            onChangeSearch={onChangeSearch}
            initialValues={initialValues}
            theme={theme}
            height={height}/>
}


const DesktopLayout = ({
                           isMobile,
                           image,
                           elementWidth,
                           onSearchPress,
                           initialValues,
                           onChangeSearch,
                           theme,
                           height
}) => {
    const handleSearchParamChange = (key, value) => {
        onChangeSearch({...initialValues, key:value})
    }
    return(
    <View style={{minHeight: isMobile ? height * 0.4 : height * 0.5}}>
        <ImageBackground source={image} resizeMode="cover" style={[styles.banner]}>
            <View style={styles.overlay}>
                <Text style={styles.bannerText}>Find Trusted Businesses Near You</Text>
                <AppSpacing gap={32}/>
                <View style={[styles.inputContainer, {
                    flexDirection: isMobile ? 'column' : 'row',
                    alignItems: 'center'
                }]}>
                    <TextInput
                        onChangeText={e => onChangeSearch({...initialValues, text:e})}
                        placeholder={"What are you looking for?"}
                        mode={'outlined'}
                        style={[styles.input, {
                        width: isMobile ? elementWidth : 350,
                        borderTopLeftRadius: 8,
                        borderBottomLeftRadius: 8
                    }, isMobile ? styles.roundedBorder : {}]}/>

                    {isMobile && <AppSpacing gap={16}/>}

                    <View style={{position: 'relative', width: isMobile ? elementWidth : 200}}>
                        {!isMobile && <View style={styles.separator}/>}
                        <TextInput onChangeText={e => onChangeSearch({...initialValues, area:e})} placeholder={"Type city or locality"} mode={'outlined'}
                                   style={[styles.input, {width: isMobile ? elementWidth : 200}, isMobile ? styles.roundedBorder : {}]}/>
                    </View>

                    {isMobile && <AppSpacing gap={16}/>}

                    <TouchableOpacity onPress={onSearchPress} activeOpacity={0.8} style={[styles.button, {
                        backgroundColor: theme.colors.primary,
                        borderTopRightRadius: 8,
                        borderBottomRightRadius: 8
                    }, isMobile ? styles.roundedBorder : {}]}>
                        <View style={{flexDirection: 'row', alignItems: 'center'}}>
                            <SearchNormal color={'white'}/>
                            <AppSpacing gap={16} isHorizontal={true}/>
                            <Text style={{fontWeight: 'bold', color: 'white'}}>Search</Text>
                        </View>
                    </TouchableOpacity>
                </View>
            </View>
        </ImageBackground>
    </View>
)
}

const MobileLayout = ({onSearchPress,  initialValues,
                          onChangeSearch,}) => {
   return(
       <AppCard overflow={'visible'} marginVertical={16}>
           <AppSpacing gap={16}/>
           <Text style={{textAlign: 'center', fontSize: 20, fontWeight: 'bold'}}>Find Trusted Businesses Near You</Text>
           <AppSpacing gap={32}/>
           <View style={{zIndex: 99}}>
               <AppTextInput  onChangeText={e => onChangeSearch({...initialValues, text:e})} placeholder={"Type keywords"} />
               <AppSpacing gap={16}/>
               {/*<GoogleMapAutocomplete border={true} width={'100%'}/>*/}
               {/*<AutoCompleteSearchInput onSelect={(coo) => console.log(coo)}/>*/}
               <AppTextInput  onChangeText={e => onChangeSearch({...initialValues, area:e})} placeholder={"Type city or locality"} />
           </View>
           <AppSpacing gap={16}/>
           <View>
               <AppButton onPress={onSearchPress} title={"Search"} icon={<SearchNormal color={'white'} size={20}/>}/>
           </View>
           <AppSpacing gap={16}/>
       </AppCard>
   )
}
