import {AppTextInput} from "./AppTextInput";
import {TouchableOpacity, View, Text, StyleSheet, TextInput} from "react-native";
import {getIsMobileDevice} from "../utils/helpers";
import {useEffect, useState} from "react";
import useAddressQuery from "../hooks/useAddressQuery";
import {useAppContext} from "../contexts/AppContext";
import {useToast} from "react-native-toast-notifications";

export const GoogleMapAutocomplete = ({width, onSelect}) => {
    const isMobile = getIsMobileDevice();
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [places, setPlaces] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState('');

    const toast = useToast();

    const {state} = useAppContext();

    const {autoCompleteSearch, validateAddress} = useAddressQuery();

    useEffect(() => {
        if(search && !loading) {
            const delayDebounceFn = setTimeout(() => {
                (async() => {
                   await getPlaces()
                })()
            }, 3000)

            return () => clearTimeout(delayDebounceFn)
        }
    }, [search, loading])

    const getPlaces = async() => {
        setSelectedAddress('');
        const location = state?.defaultLocation;
        const countryCode = location?.countryCode.toLowerCase();
        setLoading(true);
        const response = await autoCompleteSearch({
            country: countryCode,
            lat: location?.latitude,
            lon: location?.longitude,
            search: search
        });
        setLoading(false);

        if(response?.error) {
            return;
        }

        setPlaces(response);
    }

    const handleAddressSelect = async(address) => {
        setLoading(true);
        setSelectedAddress(address);
        setLoading(false);
        // const response = await validateAddress(address);
        // if(response?.error){
        //     toast.show('Something went wrong');
        //     return;
        // }
        //
        // onSelect(response);
        // setPlaces([]);
    }

    return(
        <View style={{position: 'relative'}}>
            <TextInput value={selectedAddress ? selectedAddress : search} onChangeText={e => setSearch(e)} placeholder={"Type city or locality"} mode={'outlined'}
                       style={[styles.input, {width: isMobile ? width : 200}, isMobile ? styles.roundedBorder : {}]}/>

            {
                places?.length > 0 &&
                <View style={[styles.searchResult, {top: isMobile ? 52 : 42}]}>
                    {
                        places?.map((item, index) => {
                            return(
                                <TouchableOpacity onPress={() => handleAddressSelect(item)} key={`add-${index}`} style={styles.searchItem}>
                                    <Text>{item}</Text>
                                </TouchableOpacity>
                            )
                        })
                    }

                </View>
            }

        </View>
    )
}

const styles = StyleSheet.create({
    searchResult: {
        position: 'absolute',
        right: 0,
        left: 0,
        zIndex: 9999,
        backgroundColor: 'white',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 3,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
    },
    searchItem: {
        paddingVertical: 8,
        paddingHorizontal: 16,
    },
    input: {
        height: 40,
        width: 350,
        backgroundColor: 'white',
        borderColor: 'white',
        outlineStyle: 'none',
        paddingLeft: 16,
    },
    roundedBorder: {
        borderRadius: 8,
        borderWidth: 1,
        borderColor: 'grey',
        height: 50
    }
})
