import useRequest from "./useRequest";

const useAnalyticsQuery = () => {
    const request = useRequest();

    const addAnalyticEvent = async(payload) => {
        console.log('add event');
        return await request.post('/analytics', payload).then(res => res);
    }

    const getAnalytic = async(businessId) => {
        return await request.get(`/analytics/${businessId}`).then(res => res);
    }

    return {
        addAnalyticEvent,
        getAnalytic
    }
}

export default useAnalyticsQuery;
