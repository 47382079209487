import React from 'react';
import {View, Text, StyleSheet} from 'react-native';

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    line: {
        height: 1,
        backgroundColor: 'black',
        flex: 1
    },
    textContainer: {
        marginHorizontal: 16
    }
})

export const SectionTitle = ({children, width = '100%'}) => {
    return(
        <View style={[styles.container, {width: width}]}>
            <View style={styles.line}/>
            <View style={styles.textContainer}>{children}</View>
            <View style={styles.line}/>
        </View>
    )
}
