import {GooglePlacesAutocomplete} from "react-native-google-places-autocomplete";
import {View, StyleSheet} from 'react-native';
import {useAppContext} from "../contexts/AppContext";
import {useState} from "react";

export const AutoCompleteSearchInput = ({onSelect}) => {
    const {state} = useAppContext();
    const countryCode = state?.defaultLocation?.countryCode.toLowerCase();
    const defaultLocationName = state?.defaultLocation?.name ? state?.defaultLocation?.name : '';
    const [value, setValue] = useState(defaultLocationName)

    const myLocation = {
        description: 'Your Location',
        geometry: { location: { lat: state?.defaultLocation?.latitude, lng: state?.defaultLocation?.longitude } },
    };

    return(
        <View style={styles.input}>
            <GooglePlacesAutocomplete
                fetchDetails={true}
                keyboardShouldPersistTaps={'handled'}
                keepResultsAfterBlur={true}
                onFail={(err) => console.log('err', err)}
                onNotFound={() => console.log('not found')}
                onTimeout={() => console.log('timeout')}
                enablePoweredByContainer={false}
                textInputProps={{
                    value: value,
                    onChangeText: (v) => {
                        setValue(v);
                    },
                    onBlur: () => {
                        if(!value) {
                            setValue(defaultLocationName);
                        }
                    }
                }}
                placeholderTextColor={ 'grey'}
                styles={{
                    textInput: {
                        paddingVertical: 0,
                        color: '#5d5d5d',
                        borderRadius: 10,
                        height: 44,
                        fontSize: 14,
                    }
                }}
                placeholder='Type city or locality'
                onPress={(data, details = null) => {
                    setValue(details?.formatted_address);
                    const coordinates = details?.geometry?.location;
                    onSelect({
                        address: details?.formatted_address,
                        ...coordinates
                    });
                }}
                query={{
                    key: 'AIzaSyA5ytI-OJv3OjKklLTVwlTB7jL5wRu3WD4',
                    language: 'en',
                    components: countryCode ? `country:${countryCode}` : 'country:us',
                }}
                // requestUrl={{
                //     useOnPlatform: 'all', // or "all"
                //     url: 'https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api',
                //     // headers: {
                //     //     Authorization: `an auth token`, // if required for your proxy
                //     // },
                // }}
            />
        </View>

    )
}

const styles = StyleSheet.create({
    input: {
        borderWidth:1,
        borderColor: 'grey',
        borderRadius: 10,
        padding: 0,
        overflow: 'hidden',
    }
})
