import React, {useEffect} from 'react';
import { StatusBar } from 'expo-status-bar';
import {StyleSheet, Text, View, LogBox, Platform, YellowBox} from 'react-native';
import colors from "./src/themes/colors";
import {ToastProvider} from "react-native-toast-notifications";
import {GestureHandlerRootView} from "react-native-gesture-handler";
import {SafeAreaProvider} from "react-native-safe-area-context/src/SafeAreaContext";
import Root from "./src/screens/Root";
import 'react-native-reanimated';
import 'react-native-gesture-handler';
import {DefaultTheme} from "@react-navigation/native";
import {AppProvider} from "./src/contexts/AppContext";
import {clearTransactionIOS, withIAPContext} from "react-native-iap";
import LogRocket from '@logrocket/react-native';

LogRocket.init('ubeube/africa-one-stop')

const App = () => {
    const theme = {
        ...DefaultTheme,
        colors: {
            ...DefaultTheme.colors,
            primary: '#590100',
            text: '#22334D',
            primaryText: '#22334D'
        },
    };

    // useEffect(() => {
    //     if(Platform.OS !== 'web') {
    //         (async() => {
    //             await initConnection().then(() => {
    //                 flushFailedPurchasesCachedAsPendingAndroid().catch((error) => {
    //                     console.log('purchaseErrorListener', error);
    //                 });
    //             });
    //         })()
    //     }
    // }, [])


    useEffect(() => {
        if (Platform.OS === 'ios'){
            void clearTransactionIOS();
        }
    }, [])




    return (
        <ToastProvider offsetBottom={40} offsetTop={40}>
            <GestureHandlerRootView style={{flex: 1}}>
                <SafeAreaProvider>
                    <ToastProvider>
                        <AppProvider>
                            <Root theme={theme}/>
                        </AppProvider>
                    </ToastProvider>
                </SafeAreaProvider>
            </GestureHandlerRootView>
        </ToastProvider>
);
}

export default Platform.OS === 'web' ? App : withIAPContext(App)

const styles = StyleSheet.create({
        container: {
            flex: 1,
                backgroundColor: '#fff',
                alignItems: 'center',
                justifyContent: 'center',
        },
    });
