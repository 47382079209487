import React from 'react';
import {
    View,
    Modal,
    Text,
    StyleSheet,
    Image,
    TouchableOpacity
} from 'react-native';
import {useDimension} from "../hooks/useDimension";
import {CloseCircle} from "iconsax-react-native";
import {getIsMobileDevice} from "../utils/helpers";

export const AppDialog = ({
                              title,
                              description,
                              children,
                              onClose,
                              isVisible,
                              image,
    showCloseButton = true
                          }) => {
    const {width} = useDimension();
    const isMobile = getIsMobileDevice();

    return (
        <View style={styles.centeredView}>
            <Modal
                animationType="fade"
                transparent={true}
                visible={isVisible}
                onRequestClose={onClose}>
                <View style={[styles.centeredView, { backgroundColor: 'rgba(0, 0, 0, 0.7)',}]}>
                    <View style={[styles.modalView, {width: isMobile ? width - 32 : 450}]}>
                        {
                            showCloseButton &&     <TouchableOpacity onPress={onClose} style={styles.closeButton}>
                                <CloseCircle color={'black'}/>
                            </TouchableOpacity>
                        }

                        {image && (
                            <Image source={image} height={50} resizeMethod={'container'} />
                        )}
                        {
                            title && <Text style={styles.modalText}>{title}</Text>
                        }
                        {description && (
                            <Text style={styles.modalDescription}>{description}</Text>
                        )}
                        <View style={{marginTop: 16}} />

                        {children}
                    </View>
                </View>
            </Modal>
        </View>
    );
};

const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalView: {
        margin: 16,
        backgroundColor: 'white',
        borderRadius: 20,
        padding: 32,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    button: {
        borderRadius: 20,
        padding: 10,
        elevation: 2,
    },
    buttonOpen: {
        backgroundColor: '#F194FF',
    },
    buttonClose: {
        backgroundColor: '#2196F3',
    },
    textStyle: {
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    modalText: {
        marginBottom: 15,
        fontSize: 20,
        fontWeight: '500',
        textAlign: 'center',
    },
    modalDescription: {
        marginBottom: 15,
        textAlign: 'center',
    },
    closeButton: {
        position: 'absolute',
        zIndex: 99,
        right: 16,
        top: 16,
    }
});
