import React, {useEffect, useRef} from 'react';
import {StyleSheet, View, Dimensions, Animated, Platform, Text, TouchableOpacity} from 'react-native';
import {AppCard} from "../../../components/AppCard";
// import SkeletonPlaceholder from 'expo-react-native-skeleton-placeholder';
import {AppImage} from "../../../components/AppImage";
import {Heart} from "iconsax-react-native";
import {AppSpacing} from "../../../components/AppSpacing";
import {Ratings} from "./Ratings";
import {FacebookIcon, InstagramIcon, TwitterIcon} from "../../../components/Icons";
import {getIsMobileDevice} from "../../../utils/helpers";
import useAppNavigation from "../../../hooks/useNavigation";
import {useTheme} from "@react-navigation/native";
export const BusinessSkeleton = ({parentWidth}) => {
    const theme = useTheme();
    const isMobile = getIsMobileDevice();
    const  {redirectTo} = useAppNavigation();
    const cardsPerRow = isMobile ? 1 : 4;
    const cardWidth = (parentWidth/ cardsPerRow) - 16;

    return(
        <AppCard>
            <View style={{height: 300, width: cardWidth}}>
                {/*<SkeletonPlaceholder borderRadius={4}>*/}
                {/*    <View style={{width: cardWidth, height: 170}}/>*/}
                {/*    <View style={{width: cardWidth, height: 20, marginVertical: 8}}/>*/}
                {/*    <View style={{width: cardWidth, height: 20, marginVertical: 8}}/>*/}
                {/*    <View style={{width: cardWidth, height: 20, marginVertical: 8}}/>*/}
                {/*    <View style={{width: cardWidth, height: 20, marginVertical: 8}}/>*/}
                {/*</SkeletonPlaceholder>*/}
            </View>

        </AppCard>


    )
}

const styles = StyleSheet.create({
    category: {
        alignItems: 'center',
        justifyContent: 'center',
        height: 40,
    },
    likeContainer: {
        position: 'absolute',
        top: 15,
        right: 16,
        flexDirection: 'row',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        borderRadius: 8,
        paddingVertical: 2,
        paddingHorizontal: 8,
        alignItems: 'center',
        justifyContent: 'center'
    }
})
