import React, {useEffect, useState} from 'react';
import {
    View,
    Text,
    StyleSheet,
    FlatList,
    Switch,
    Platform,
    TouchableOpacity,
    ActivityIndicator, Modal
} from "react-native";
import {Layout} from "../Layout";
import {AppImage} from "../../components/AppImage";
import {AppCard} from "../../components/AppCard";
import {AppSpacing} from "../../components/AppSpacing";
import {useNavigation, useTheme} from "@react-navigation/native";
import {AppButton} from "../../components/AppButton";
import {Add, Call, Edit2, Global, Sms} from "iconsax-react-native";
import {getImagePayload, getIsMobileDevice} from "../../utils/helpers";
import {useAppContext} from "../../contexts/AppContext";
import useBusinessesQuery from "../../hooks/useBusinessesQuery";
import {AppTextInput} from "../../components/AppTextInput";
import useUsersQuery from "../../hooks/useUsersQuery";
import {useToast} from "react-native-toast-notifications";
import * as ImagePicker from "expo-image-picker";
import useAttachmentsQuery from "../../hooks/useAttachmentsQuery";
import {setUser, unsetAuthToken} from "../../contexts/reducers";
import {useNavigate} from "react-router";
import {AppTextButton} from "../../components/AppTextButton";
import useAppNavigation from "../../hooks/useNavigation";
import {BusinessStatus} from "../../components/BusinessStatus";
import {useRoute} from "@react-navigation/core";
import {Colors} from "../../themes/colors";

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    imageEditButton: {
        position: 'absolute',
        right: -10,
        bottom: -10,
        backgroundColor: 'white',
        width: 35,
        height: 35,
        borderRadius: 20,
        alignItems: 'center',
        justifyContent: 'center'
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.4)'
    },
    modalView: {
        margin: 20,
        backgroundColor: 'white',
        alignItems: 'center',
        borderRadius: 10,
        overflow: 'hidden'
    },
    modalButton: {
        padding: 10,
        flex: 1,
        backgroundColor: '#f3f3f3',
        alignItems: 'center',
    },
})


export const ProfileScreen = () => {
    const isMobile = getIsMobileDevice();
    const [businesses, setBusinesses] = useState([]);
    const navigate = useNavigate();
    const navigation = useNavigation();
    const route = useRoute();
    const {params} = route;
    const shouldRefresh = params?.refresh;
    const {getMe, deleteAccount, cancelStripeSubscription} = useUsersQuery();
    const {dispatch} = useAppContext();
    const {state} = useAppContext();
    const toast = useToast();

    const [confirmModalVisible, setConfirmModalVisible] = useState(false);
    const [subscriptionLoading, setSubscriptionLoading] = useState(false);

    useEffect(() => {
        (async() => {
            const response = await getMe();
            dispatch(setUser(response));
        })()
    }, [])

    useEffect(() => {
        if(shouldRefresh) {
            (async() => {
                await refreshBusinesses();
            })()
        }
    }, [shouldRefresh])

    const  {getMyBusinesses} = useBusinessesQuery();

    useEffect(() => {
        if(Platform.OS !== 'web') {
            const unsubscribe = navigation.addListener('focus', async() => {
                refreshBusinesses();
            });

            return unsubscribe;
        }

    }, [navigation]);

    useEffect(() => {
        if(Platform.OS === 'web') {
            refreshBusinesses();
        }
    }, [])

    const refreshBusinesses = async() => {
        const response = await getMyBusinesses();
        console.log('response', response);
        if(response?.error) {
            return;
        }

        setBusinesses(response);
    }

    const handleAddBusinessPress = () => {
        if(Platform.OS === 'web') {
            window.location.href = ('/business/setup')
        }
        else{
            navigation.navigate('AddBusiness')
        }
    }

    const onDeletePress = async() => {
        const response = await deleteAccount(state?.user.id);
        console.log('response', response);
        if(response?.error) {
            toast.show(response?.message);
            return;
        }

        dispatch(setUser(null));
        dispatch(unsetAuthToken());
        navigation.navigate("Login");
    }

    const onPressCancel = async() => {
        setConfirmModalVisible(true);
    }

    const continueCancellingSubscription = async() => {
        setConfirmModalVisible(false);
        setSubscriptionLoading(true);
        const response = await cancelStripeSubscription(user?.id);
        console.log('response', response);
        setSubscriptionLoading(false);
        if(response?.error) {
            toast.show('Unable to cancel the subscription');
            return;
        }

        const user = await getMe();
        dispatch(setUser(user))
    }

    return(
        <Layout isStack={true}>
            <View style={{paddingVertical: 16, flexDirection: isMobile ? 'column' : 'row'}}>
                <View style={{flex: isMobile ? null : 1}}>
                    <Profile loadingSubscription={subscriptionLoading} onPressCancel={onPressCancel}/>
                    <Settings/>
                </View>
                {isMobile && <AppSpacing gap={16}/>}
                <View style={{flex: isMobile ? null : 2}}>
                    <View style={[styles.row, {paddingHorizontal: 16, justifyContent: 'space-between'}]}>
                        <Text style={{fontSize: 20, fontWeight: 'bold'}}>My Businesses</Text>
                        <AppButton onPress={handleAddBusinessPress} width={170} icon={ <Add size={20} color={'white'}/>} title={"Add Business"}/>
                    </View>
                    <AppSpacing gap={16}/>
                    {
                        businesses?.length > 0 &&
                        <View>
                            {
                                businesses?.map((item) => <MyBusiness business={item} key={`b-${item?.id}`}/>)
                            }
                        </View>
                    }

                </View>
                {isMobile && <AppSpacing gap={16}/>}
                {
                    isMobile && <DeleteAccount onDeletePress={onDeletePress}/>
                }

                {isMobile && <AppSpacing gap={16}/>}
            </View>
            <Modal
                animationType="fade"
                transparent={true}
                visible={confirmModalVisible}
                onRequestClose={() => setConfirmModalVisible(false)}>
                <View style={styles.centeredView}>
                    <View style={[styles.modalView, { width: 350}]}>
                        <View style={{padding: 16, width: '100%'}}>
                            <Text style={{fontWeight: 'bold', marginBottom: 16}}>Cancel Subscription?</Text>
                            <Text style={{marginBottom: 16}}>This action is permanent, Once you cancel the subscription your all active business will be unpublished.</Text>
                        </View>
                        <View style={{flexDirection: 'row', width: '100%', alignItems: 'center'}}>
                            <TouchableOpacity
                                style={styles.modalButton}
                                onPress={() => setConfirmModalVisible(!confirmModalVisible)}>
                                <Text style={styles.textStyle}>Cancel</Text>
                            </TouchableOpacity>
                            <TouchableOpacity
                                style={[styles.modalButton]}
                                onPress={continueCancellingSubscription}>
                                <Text style={[styles.textStyle, {color: 'red'}]}>Confirm</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            </Modal>
        </Layout>

    )
}

const Profile = ({loadingSubscription, onPressCancel}) => {
    const theme = useTheme();
    const {state, dispatch} = useAppContext();
    const {user} = state;
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState('');
    const {redirectTo} = useAppNavigation();

    const toast = useToast();

    const {updateProfile, getMe} = useUsersQuery();
    const {upload} = useAttachmentsQuery();

    const handleUpdatePress = async() => {
        const payload = {};
        if(name ) {
            payload['name'] = name;
        }

        if(phone ) {
            payload['phone'] = phone;
        }

        if(image) {
            payload['image'] = image;
        }

        if(name || phone || image) {
            setLoading(true);
            const response = await updateProfile(payload);
            setLoading(false);
            if(response?.error) {
                toast.show(response?.message);
                return;
            }

            const user = await getMe();
            dispatch(setUser(user))
        }
        toast.show('Profile Updated.');
        setIsEditing(false);
    }

    const pickImage = async () => {
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            allowsEditing: true,
            quality: 0.6,
            base64: true,
        });

        if (!result.canceled) {
            setLoading(true);
            const uploadResult = await upload({attachment: result.assets[0]?.base64});
            setLoading(false);
            if(uploadResult?.error) {
                toast.show('Image upload failed, try again');
                return;
            }

            setImage(uploadResult[0]?.square);
        }
    };

    const logout = () => {
        dispatch(setUser(null));
        dispatch(unsetAuthToken());
        redirectTo("Login");
    }

    return(
        <AppCard>
            <View style={{justifyContent: 'center', alignItems: 'center'}}>
                <AppSpacing gap={16}/>
                <View style={{position: 'relative'}}>
                    <AppImage size={100} uri={image ? image : user?.avatar} isLocal={false}/>
                    {
                        isEditing &&
                        <TouchableOpacity onPress={pickImage} style={styles.imageEditButton}>
                            <Edit2 size={20} color={theme.colors.primary}/>
                        </TouchableOpacity>
                    }

                </View>
                <AppSpacing gap={16}/>

                {
                    isEditing ?
                        <View style={{width: '100%'}}>
                            <AppSpacing gap={8}/>
                            <AppTextInput value={name ? name : user?.name} onChangeText={e => setName(e)} placeholder={"Name"}/>
                            <AppSpacing gap={8}/>
                            <AppTextInput value={phone ? phone : user?.phone} onChangeText={e => setPhone(e)} placeholder={"Phone"}/>
                        </View>
                        :
                        <View>
                            <Text style={{fontWeight: 'bold', textAlign: 'center'}}>{user?.name}</Text>
                            <AppSpacing gap={8}/>
                            <Text style={{textAlign: 'center'}}>{user?.email}</Text>
                            <AppSpacing gap={8}/>
                            <Text style={{textAlign: 'center'}}>{user?.phone}</Text>
                            <AppSpacing gap={8}/>
                            <Text style={{textAlign: 'center'}}>Account Type: <Text style={{color: theme.colors.primary}}>{user?.businesses_count > 0 ? 'Business' : 'Individual' }</Text></Text>
                            {
                                user?.active_plan &&
                                <>
                                    <AppSpacing gap={8}/>
                                    <Text style={{textAlign: 'center'}}>Subscription: {user?.active_plan}</Text>
                                    <AppSpacing gap={8}/>
                                    <Text style={{textAlign: 'center'}}>Plan Usage: {user?.usage_count} / {user?.plan_quota}</Text>
                                    {
                                        user?.subscription_id
                                        && user?.subscription_id?.toString()?.includes('sub') &&
                                        <View style={{alignItems: 'center'}}>
                                            <AppSpacing gap={16}/>
                                            {
                                                loadingSubscription ?
                                                    <ActivityIndicator color={Colors.primary}/>
                                                    :
                                                    <AppTextButton onPress={() => onPressCancel()} title={"Cancel Subscription"}/>
                                            }

                                            <AppSpacing gap={16}/>
                                        </View>
                                    }

                                </>
                            }

                        </View>
                }

                <AppSpacing gap={16}/>
                <View>
                    {
                        isEditing
                            ?
                            <AppButton disabled={!!loading} onPress={handleUpdatePress} width={200} title={"Update Profile"}/>
                            :
                            <AppButton onPress={() => setIsEditing(true)} width={200} title={"Edit Profile"}/>
                    }

                </View>
                <AppSpacing gap={16}/>
                <View>
                    <AppTextButton onPress={() => logout()} title={"Logout"}/>
                </View>
            </View>
        </AppCard>
    )
}

const Settings = () => {
    const [isEnabled, setIsEnabled] = useState(false);
    return(
        <AppCard>
            <AppSpacing gap={16}/>
            <Text style={{fontSize: 20, fontWeight: 'bold'}}>Settings</Text>
            <AppSpacing gap={16}/>
            <View style={{width: '100%'}}>
                <View style={[styles.row, {justifyContent: 'space-between'}]}>
                    <Text>Receive Newsletters</Text>
                    <Switch
                        trackColor={{false: '#767577', true: '#81b0ff'}}
                        thumbColor={isEnabled ? '#f5dd4b' : '#f4f3f4'}
                        ios_backgroundColor="#3e3e3e"
                        onValueChange={() => setIsEnabled(!isEnabled)}
                        value={isEnabled}
                    />
                </View>
                <AppSpacing gap={16}/>
                <View style={[styles.row, {justifyContent: 'space-between'}]}>
                    <Text>Receive Notifications</Text>
                    <Switch
                        trackColor={{false: '#767577', true: '#81b0ff'}}
                        thumbColor={isEnabled ? '#f5dd4b' : '#f4f3f4'}
                        ios_backgroundColor="#3e3e3e"
                        onValueChange={() => setIsEnabled(!isEnabled)}
                        value={isEnabled}
                    />
                </View>
            </View>
            <AppSpacing gap={16}/>
        </AppCard>
    )
}

const DeleteAccount = ({onDeletePress}) => {
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);

    return(
        <>
            <AppCard backgroundColor={'red'}>
                <AppSpacing gap={16}/>
                <Text style={{fontSize: 20, fontWeight: 'bold', color: 'white'}}>Delete Account</Text>
                <AppSpacing gap={16}/>
                <Text style={{color: 'white'}}>Delete all of your data and businesses. This will remove your account permanently and you can signup again.</Text>
                <AppSpacing gap={16}/>
                <AppButton onPress={() => setDeleteModalVisible(true)} title={'Delete Account'} buttonTextColor={'red'} color={'white'}/>
            </AppCard>
            <Modal
                animationType="fade"
                transparent={true}
                visible={deleteModalVisible}
                onRequestClose={() => setDeleteModalVisible(false)}>
                <View style={styles.centeredView}>
                    <View style={[styles.modalView, { width: 350}]}>
                        <View style={{padding: 16, width: '100%'}}>
                            <Text style={{fontWeight: 'bold', marginBottom: 16}}>Confirm Delete</Text>
                            <Text style={{marginBottom: 16}}>Are you sure you want to delete your account?</Text>
                        </View>
                        <View style={{flexDirection: 'row', width: '100%', alignItems: 'center'}}>
                            <TouchableOpacity
                                style={styles.modalButton}
                                onPress={() => setDeleteModalVisible(!deleteModalVisible)}>
                                <Text style={styles.textStyle}>Cancel</Text>
                            </TouchableOpacity>
                            <TouchableOpacity
                                style={[styles.modalButton]}
                                onPress={onDeletePress}>
                                <Text style={[styles.textStyle, {color: 'red'}]}>Confirm</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            </Modal>
        </>
    )
}


const MyBusiness = ({business}) => {
    const theme = useTheme();
    const navigation = useNavigation();
    const isMobile = getIsMobileDevice();
    const navigate = useNavigate();

    const handleViewPress = () => {
        if(Platform.OS === 'web') {
            navigate(`/business/${business?.slug}`)
        }
        else{
            navigation.navigate('ViewBusiness', {slug: business?.slug})
        }
    }

    const handleEditPress = () => {
        if(Platform.OS === 'web') {
            navigate(`/business/${business?.id}/edit`)
        }
        else{
            navigation.navigate('AddBusiness', {id: business?.id})
        }
    }

    return(
        <AppCard padding={0}>
            <View style={[styles.row, {padding: 16, flexDirection: isMobile ? 'column' : 'row'}]}>
                <View style={{width: 200}}>
                    <AppImage isLocal={false} width={200} height={200} uri={business?.logo}/>
                </View>
                <AppSpacing gap={16} isHorizontal={!isMobile}/>
                <View style={{flex: isMobile ? null : 4}}>
                    <Text style={{fontWeight: 'bold'}}>{business?.name}</Text>
                    <AppSpacing gap={8}/>
                    <Text>{business?.full_address}</Text>
                    <AppSpacing gap={8}/>
                    <View>
                        <View style={styles.row}>
                            <Call color={theme.colors.text} size={20}/>
                            <AppSpacing gap={4} isHorizontal={true}/>
                            <Text>{business?.phone}</Text>
                        </View>
                        <AppSpacing gap={8}/>
                        <View style={styles.row}>
                            <Sms color={theme.colors.text} size={20}/>
                            <AppSpacing gap={4} isHorizontal={true}/>
                            <Text>{business?.email}</Text>
                        </View>
                        <AppSpacing gap={8}/>
                        <View style={styles.row}>
                            <Global color={theme.colors.text} size={20}/>
                            <AppSpacing gap={4} isHorizontal={true}/>
                            <Text>{business?.website}</Text>
                        </View>
                        <AppSpacing gap={8}/>
                        <View>
                            <BusinessStatus status={business?.status}/>
                            {/*<Text>Status: <Text style={{fontWeight: 'bold'}}>{business?.status}</Text></Text>*/}
                        </View>
                    </View>
                </View>
                {/*<AppSpacing gap={16} isHorizontal={!isMobile}/>*/}
                {/*<View style={[styles.row, {flex: isMobile ? null :1, alignItems: 'flex-end', justifyContent: 'flex-end'}]}>*/}
                {/*    <AppButton onPress={() => handleEditPress(business)} title={"Edit"} color={theme.colors.text}/>*/}
                {/*    <AppSpacing gap={16} isHorizontal={true}/>*/}
                {/*    <AppButton onPress={() => handleViewPress(business)} title={"View"}/>*/}
                {/*</View>*/}
            </View>
            <View style={{flexDirection: 'row', alignItems:'center',}}>
                <TouchableOpacity onPress={() => handleEditPress(business)} style={{flex: 1, alignItems: 'center', padding: 16, backgroundColor: 'rgb(250,250,250)'}}>
                    <Text style={{fontWeight: 'bold'}}>Edit</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => handleViewPress(business)} style={{flex: 1, alignItems: 'center',  padding: 16, backgroundColor: 'rgb(250,250,250)'}}>
                    <Text style={{fontWeight: 'bold'}}>View</Text>
                </TouchableOpacity>
            </View>
        </AppCard>
    )
}
