import React from 'react';
import {View} from 'react-native';

export const AppSpacing = ({gap = 4, isHorizontal = false}) => {
    return (
        <View
            style={isHorizontal ? {width: gap, height: 1} : {height: gap, width: 1}}
        />
    );
};
