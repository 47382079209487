import {Text, View, StyleSheet, Platform, TouchableOpacity, FlatList, Image, ActivityIndicator} from "react-native";
import {useEffect, useState} from "react";
import {Layout} from "../Layout";
import {ManinBanner} from "./components/MainBanner";
import {SectionTitle} from "../../components/SectionTitle";
import {AppSpacing} from "../../components/AppSpacing";
import {getIsMobileDevice} from "../../utils/helpers";
import {AppButton} from "../../components/AppButton";
import {AppTextInput} from "../../components/AppTextInput";
import {AppAccordion} from "../../components/AppAccordion";
import {useDimension} from "../../hooks/useDimension";
import useTheme from "../../hooks/useTheme";
import {BusinessCard} from "./components/BusinessCard";
import {useNavigate} from "react-router";
import useAppNavigation from "../../hooks/useNavigation";
import {AppDialog} from "../../components/AppDialog";
import {BusinessSkeleton} from "./components/BusinessSkeleton";
import {useAppContext} from "../../contexts/AppContext";
import { useNavigation } from '@react-navigation/native';
import useBusinessesQuery from "../../hooks/useBusinessesQuery";
import {useToast} from "react-native-toast-notifications";
import AsyncStorage from "@react-native-async-storage/async-storage";
// import useMessageQuery from "../../hooks/useMessageQuery";
// import {View as WView} from 'react-native-web';
// import {
//     APPLE_PLAN_1,
//     APPLE_PLAN_2,
//     APPLE_PLAN_3,
//     APPLE_PLAN_4,
//     APPLE_PLAN_5,
//     GOOGLE_PLAN_1, GOOGLE_PLAN_2, GOOGLE_PLAN_3, GOOGLE_PLAN_4, GOOGLE_PLAN_5
// } from "../../utils/constants";
// import {useIAP} from "react-native-iap";

const items = [...new Array(8).keys()];

const styles = StyleSheet.create({
    mainBanner: {

    },
    row: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    banner2: {
        flex: 1,
        backgroundColor: 'white',
        height: 300,
    },
    downloadAppContainer: {
        paddingVertical: 100,
        flex: 1,
        position: 'relative'
    },
    faqHeader: {
        backgroundColor: 'red',
        padding: 16,
    }
})

const HomeScreen = () => {
    const isMobile = getIsMobileDevice();
    const {width} = useDimension();
    const theme = useTheme();
    const [viewportWidth, setViewportWidth] = useState(0);
    const {redirectTo} = useAppNavigation();
    const [searchParams, setSearchParams] = useState({
        text: '',
        area: ''
    })
    const [isNearestLoading, setIsNearestLoading] = useState(true);
    // const subscriptionSkus = Platform.select({
    //     ios: [APPLE_PLAN_1, APPLE_PLAN_2, APPLE_PLAN_3, APPLE_PLAN_4, APPLE_PLAN_5],
    //     android: [GOOGLE_PLAN_1, GOOGLE_PLAN_2, GOOGLE_PLAN_3, GOOGLE_PLAN_4, GOOGLE_PLAN_5],
    //     default: [],
    // })

    // const {
    //     getSubscriptions,
    // } = useIAP();

    const navigation = useNavigation();
    const navigate = useNavigate();
    const {addLikeOrUnlike, fetchNearestBusinesses} = useBusinessesQuery();

    const placeholderItems = items.map((item, index) => {
        return {id: index}
    })

    const toast = useToast();

    const {state, dispatch} = useAppContext();

    const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);

    // useEffect(() => {
    //    (async() => {
    //        try {
    //            console.log('subscriptionSkus', subscriptionSkus);
    //            const response = await getSubscriptions({skus:  subscriptionSkus});
    //            console.log('response', response);
    //        } catch (error) {
    //            console.log('error getSubscriptions', error?.message)
    //        }
    //    })()
    // }, [])

    useEffect(() => {
        if(Platform.OS === 'web'){
            document.title = "Home"
        }
    }, [])

    useEffect(() => {
        (async() => {
            const token  = await AsyncStorage.getItem('apiToken');
            console.log('token', token);
            if(!token) {
                setTimeout(() => {
                    displayLoginPopup()
                }, 5000)
            }
        })()
    }, [])

    const displayLoginPopup = () => {
        if(!state.authToken) {
            if(!isLoginModalVisible) {
                setIsLoginModalVisible(true);
            }
        }
    }

    useEffect(() => {
        (async() => {
            if(state.defaultLocation?.latitude && state.defaultLocation?.longitude) {
                setIsNearestLoading(true);
                const response = await fetchNearestBusinesses(state?.defaultLocation.latitude, state?.defaultLocation.longitude);
                if(response?.error) {
                    return;
                }
                setIsNearestLoading(false);
                dispatch({
                    type: 'UPDATE_NEAREST_BUSINESSES',
                    payload: response
                });
            }
        })()
    }, [state.defaultLocation])

    const onSearchPress = () => {
        if(Platform.OS === 'web') {
            navigate(`/businesses?search=${searchParams?.text}&area=${searchParams.area}`)
        }
        else{
            navigation.navigate("BusinessesList", {
                search: searchParams?.text,
                area: searchParams.area
            })
        }
    }

    const handleLikeBusiness = async(business, isRecommended = false) => {
        if(state?.authToken) {
            const response = await addLikeOrUnlike(business?.id);
            if(response?.error) {
                toast.show(response?.message);
                return;
            }

            if(isRecommended) {
                const existingRecommendedBusinesses = state?.recommendedBusinesses;
                const index = existingRecommendedBusinesses.findIndex(item => item.id === business?.id);
                existingRecommendedBusinesses[index]['has_liked'] = !business?.has_liked;
                if(existingRecommendedBusinesses[index]['has_liked']) {
                    existingRecommendedBusinesses[index]['likes_count'] = existingRecommendedBusinesses[index]['likes_count'] + 1
                }
                else{
                    existingRecommendedBusinesses[index]['likes_count'] = existingRecommendedBusinesses[index]['likes_count'] - 1
                }
                dispatch({
                    type: 'UPDATE_RECOMMENDED_BUSINESSES',
                    payload: existingRecommendedBusinesses
                });
            }
            else{
                const existingNearestBusiness = state?.nearestBusinesses;
                const index = existingNearestBusiness.findIndex(item => item.id === business?.id);
                existingNearestBusiness[index]['has_liked'] = !business?.has_liked;
                if(existingNearestBusiness[index]['has_liked']) {
                    existingNearestBusiness[index]['likes_count'] = existingNearestBusiness[index]['likes_count'] + 1
                }
                else{
                    existingNearestBusiness[index]['likes_count'] = existingNearestBusiness[index]['likes_count'] - 1
                }
                dispatch({
                    type: 'UPDATE_NEAREST_BUSINESSES',
                    payload: existingNearestBusiness
                });
            }

        }
        else{
            redirectTo('Login');
        }
    }

    return(
        <Layout isTransparent={true}
                banner={<ManinBanner initialValues={searchParams} onChangeSearch={(params) => setSearchParams({...params})} onSearchPress={onSearchPress}/>}>
            <AppSpacing gap={16}/>
            <View style={[styles.row, {padding: 16, justifyContent: 'center', alignItems: 'center'}]}>
                <Text style={{color: theme.colors.text, fontSize: 20, fontWeight: 'bold'}}>
                    Recommended Business
                </Text>
                {/*<AppButton onPress={() => redirectTo('Business', '', 'search=recommended')} title={'View All'}/>*/}
            </View>

            <AppSpacing gap={16}/>
            <View onLayout={(event) => {
                const {x, y, width, height} = event.nativeEvent.layout;
                setViewportWidth(width);
            }} >
                {
                    state.loadingStatus.isRecommendedLoading &&
                    <View style={{flex: 1, width: '100%', minHeight: 300, justifyContent: 'center', alignItems: 'center'}}>
                        <ActivityIndicator color={theme.colors.primary}/>
                    </View>
                }
                {
                    !state.loadingStatus.isRecommendedLoading && state.recommendedBusinesses?.length > 0 &&

                        <View style={{flexDirection: 'row', flexWrap: 'wrap'}}>
                            {
                                state.recommendedBusinesses?.map((item, index) =>
                                    <BusinessCard key={`rec-${index}`}
                                                  parentWidth={viewportWidth}
                                                  business={item}
                                                  imagePriority={index}
                                                  likeBusiness={() => handleLikeBusiness(item, true)}/>)
                            }
                        </View>

                }

            </View>

            <AppSpacing gap={16}/>
            <View style={[styles.row, {padding: 16, justifyContent: 'center', alignItems: 'center'}]}>
                <Text style={{color: theme.colors.text, fontSize: 20, fontWeight: 'bold'}}>
                    Businesses Near You
                </Text>
                {/*<AppButton onPress={() => redirectTo('Business', '', 'search=near-you')} title={'View All'}/>*/}
            </View>
            <AppSpacing gap={16}/>
            {
                isNearestLoading &&
                <View style={{flex: 1, width: '100%', minHeight: 300, justifyContent: 'center', alignItems: 'center'}}>
                    <ActivityIndicator color={theme.colors.primary}/>
                </View>
            }
            {
                !isNearestLoading && state.nearestBusinesses?.length > 0 &&

                    <View style={{flexDirection: 'row', flexWrap: 'wrap'}}>
                        {
                            state.nearestBusinesses?.map((item, index) => <BusinessCard
                                key={`rec-${index}`}
                                parentWidth={viewportWidth}
                                business={item}
                                likeBusiness={() => handleLikeBusiness(item)}/>)
                        }
                    </View>
            }
            <AppSpacing gap={32}/>
            <GetStarted theme={theme}/>
            <View style={{marginVertical: 32, justifyContent: 'center', alignItems: 'center'}}>
                <SectionTitle width={isMobile ? width * 0.7 : width* 0.5}>
                    <Text style={{fontSize: 25, fontWeight: 'bold', color: '#22334D'}}>FAQ</Text>
                </SectionTitle>
            </View>
            <View>
                <FAQ/>
            </View>

            <AppDialog isVisible={isLoginModalVisible} onClose={() => setIsLoginModalVisible(false)}>
                <View style={{alignItems: 'center'}}>
                    <Image style={{width: 100, height: 100}} source={require('../../../assets/logo.png')}/>
                    <Text style={{fontSize: 20, fontWeight: 'bold', marginBottom: 16}}>Welcome!</Text>
                    <Text style={{marginBottom: 16, textAlign: 'center'}}>To access all the feature and special offers and promotions near you become a member</Text>
                    <View>
                        <AppButton onPress={() => {
                            setIsLoginModalVisible(false)
                            redirectTo('Signup')
                        }} title={"Create Account"}/>
                    </View>
                    <View style={{marginTop: 16, flexDirection: 'row', alignItems: 'center'}}>
                        <Text>Already have an account? </Text>
                        <TouchableOpacity onPress={() => {
                            setIsLoginModalVisible(false)
                            redirectTo('Login')
                        }}><Text style={{fontWeight: 'bold', color: theme.colors.primary}}>Login</Text></TouchableOpacity>
                    </View>
                </View>
            </AppDialog>
        </Layout>
    )
}

export default HomeScreen;

const GetStarted = ({theme}) => {
    const isMobile = getIsMobileDevice();
    const navigate = useNavigate();
    const navigation = useNavigation();
    const [email, setEmail] = useState('');
    const redirectToSignup = () => {
        if(Platform.OS === 'web') {
            navigate('/signup?email=' + email);
        }
        else{
            navigation.navigate('Signup', {email: email});
        }
    }
    return(
        <View style={{flexDirection: isMobile ? null : 'row', justifyContent: isMobile ? null : 'center', alignItems: isMobile ? null : 'center'}}>
            <View style={{flex: isMobile ? null : 1}}>
                <Image style={{height: 350, width: '100%'}} source={require('../../../assets/img/banner-2.jpeg')} resizeMode="cover" />
            </View>
            <View style={{flex: isMobile ? null : 1}}>
                <View style={{height: 350, justifyContent: 'center', padding: 16, paddingRight: 50, width: isMobile ? '100%' : '90%', backgroundColor: theme.colors.primary, borderTopRightRadius: isMobile ? 0 : 120, borderBottomRightRadius: isMobile ? 0 : 120}}>
                    <Text style={{width: '80%', textTransform: 'uppercase', fontSize: 20, color: 'white', fontWeight: '500'}}>WE HELP MILLIONS OF SMALL BUSINESSES GET FOUND BY MORE CUSTOMERS</Text>
                    <AppSpacing gap={16}/>
                    <Text style={{color: 'white'}}>Don't Wait! Gain More Exposure for Your Business Today!</Text>
                    <AppSpacing gap={16}/>
                    <AppTextInput value={email} onChangeText={e => setEmail(e)} placeholder={'Enter your email'}/>
                    <AppSpacing gap={16}/>
                    <AppButton width={150} color={'#AE2012'} onPress={redirectToSignup} title={"Get Started"}/>
                </View>
            </View>
        </View>
    )
}

const FAQ = () => {
    return (
        <View style={{padding: 16}}>
            <Text style={{fontWeight: 'bold', marginVertical: 16}}>Account and Profile </Text>
            <AppAccordion title={'How do I create a user account on Africa One Stop?'} content={'To create a user account on Africa One Stop, click the "Login" button on the homepage and follow the prompts.'}/>
            <AppSpacing gap={16}/>
            <AppAccordion backgroundColor={'white'} title={'How do I update my account information? '} content={'To update your account information, log in to your account and click the “Profile” button, then "Edit Profile" button.'}/>
            <AppSpacing gap={16}/>

            <Text style={{fontWeight: 'bold', marginVertical: 16}}>Adding a Business </Text>
            <AppAccordion  title={'How do I add my business to Africa One Stop?'} content={'To add your business to Africa One Stop, click the "Add Business" button on the homepage and follow the prompts. You cannot proceed to the next field until preceding questions are answered. You cannot proceed to add a location until business logo and at least one cover image has been added to your profile.'}/>
            <AppSpacing  gap={16}/>
            <AppAccordion backgroundColor={'white'} title={'What types of businesses can I add to Africa One Stop? '} content={'You can add African businesses or organizations to Africa One Stop, including grocery stores, restaurants, fashion designers, salons, professional services, and more.'}/>
            <AppSpacing gap={16}/>
            <AppAccordion title={'Do I have to pay to add my business to Africa One Stop?'} content={'Although it is free to use Africa One stop to locate African businesses and organizations near you, you need to subscribe monthly or annually to add your business to the platform.'}/>
            <AppSpacing gap={16}/>
            <AppAccordion backgroundColor={'white'} title={'How long does it take for my business to be listed on Africa One Stop after I submitted it?'} content={'We strive to review and approve new business listings as quickly as possible. The process typically takes a few business days but may take longer during periods of high volume.'}/>
            <AppSpacing gap={16}/>
            <AppAccordion title={'What if my business has more than one physical location and I need to include each of them?'} content={'Africa One Stop allows you to add up to 50 businesses on your profile. This includes businesses with more than one physical location, outlets or subsidiaries.'}/>
            <AppSpacing gap={16}/>
            <AppAccordion backgroundColor={'white'} title={'Is this free to use?'} content={'Yes, This application is free to use.'}/>
            <AppSpacing gap={16}/>

            <Text style={{fontWeight: 'bold', marginVertical: 16}}>Subscription</Text>

            <AppAccordion title={'What are the subscription options for Africa One Stop? '} content={'Africa One Stop offers monthly and annual subscriptions for businesses and organizations that want to list their services on our platform.'}/>
            <AppSpacing gap={16}/>
            <AppAccordion backgroundColor={'white'} title={'How do I cancel my subscription?'} content={'If you have purchased the subscription from Apple, you can manage the subscription from the Settings app of iPhone, If you have purchased the subscription from Google you can cancel it from Google Play app of Android Phone. If you have purchase the subscription from Web you can cancel it under your profile.'}/>
            <AppSpacing gap={16}/>
            <AppAccordion title={'Do subscriptions automatically renew?'} content={'Yes, subscriptions are set to automatically renew unless canceled by the user.'}/>
            <AppSpacing gap={16}/>

            <Text style={{fontWeight: 'bold', marginVertical: 16}}>User Conduct </Text>

            <AppAccordion backgroundColor={'white'} title={'What is the code of conduct for using Africa One Stop? '} content={'We expect all users of Africa One Stop to conduct themselves in a respectful and professional manner. Please see our terms and conditions for more information.'}/>
            <AppSpacing gap={16}/>
            <AppAccordion title={'What should I do if I encounter inappropriate behavior on Africa One Stop?'} content={'If you encounter inappropriate behavior on Africa One Stop, please contact our support team using the information provided on our contact us page.'}/>
            <AppSpacing gap={16}/>
            <AppAccordion backgroundColor={'white'} title={'Can I leave a negative review of a business on Africa One Stop?'} content={'Yes, you can leave a review of a business on Africa One Stop. However, we expect all reviews to be honest and respectful, and we reserve the right to remove reviews that violate our code of conduct.'}/>
            <AppSpacing gap={16}/>

            <Text style={{fontWeight: 'bold', marginVertical: 16}}>Technical Support</Text>

            <AppAccordion title={'What should I do if I am having technical issues with Africa One Stop? '} content={'Please check our troubleshooting page for common issues and solutions. If you still need assistance, please contact our support team using the information provided on our contact us page.'}/>
            <AppSpacing gap={16}/>
            <AppAccordion backgroundColor={'white'} title={'What should I do if I forget my password for Africa One Stop?'} content={'If you forget your password for Africa One Stop, you can reset it by clicking the "Forgot Password" link on the login page.'}/>
            <AppSpacing gap={16}/>
            <AppAccordion title={'What web browsers are compatible with Africa One Stop?'} content={'Africa One Stop is compatible with all major web browsers, including Chrome, Safari, Firefox, and Edge.'}/>
            <AppSpacing gap={16}/>
            <AppAccordion backgroundColor={'white'} title={'Is there an app for African One Stop?'} content={'Yes, Africa One Stop is available on both App store and play store.'}/>
            <AppSpacing gap={16}/>

            <Text style={{fontWeight: 'bold', marginVertical: 16}}>Miscellaneous</Text>

            <AppAccordion title={'Does Africa One Stop endorse any businesses or organizations listed on its platform?'} content={'No, Africa One Stop does not endorse any businesses or organizations listed on its platform. We provide a directory of African businesses and organizations for informational purposes only.'}/>
            <AppSpacing gap={16}/>
            <AppAccordion backgroundColor={'white'} title={'How can I provide feedback or suggestions to Africa One Stop?'} content={' Please send us feedback using the information provided on our contact us page. We look forward to hearing from you!'}/>
            <AppSpacing gap={16}/>
            <AppAccordion title={'Is Africa One Stop available outside of Africa? '} content={' Yes, Africa One Stop is available worldwide as long as businesses and organizations are listed on our platform.'}/>
            <AppSpacing gap={16}/>
            <Text>
                Thank you for using Africa One Stop. We hope these FAQs have been helpful. If you have any further questions or concerns, please contact our support team using the information provided on our support page.
            </Text>
        </View>
    )
}
