import React from 'react';
import {View, Text} from 'react-native';
import {HalfStarIcon, StarIcon} from "../../../components/Icons";

export const Ratings = ({ratings}) => {
    ratings = parseFloat(ratings).toFixed(2).toString();
    const perfectRating = ratings.split('.')[0];
    // const fractionRating = parseInt(ratings.split('.')[1]);
    // const starArr = perfectRating ?  Array.from(Array(parseInt(perfectRating)).keys()) : [];

    return(
        <View style={{flexDirection: 'row'}}>
            {/*{starArr.map((item, index) => {*/}
            {/*    return(<StarIcon key={`star-${index}`} size={15}/>)*/}
            {/*})}*/}
            {/*{*/}
            {/*    fractionRating >= 25 && fractionRating < 75 && <HalfStarIcon size={15}/>*/}
            {/*}*/}
            {/*{*/}
            {/*    fractionRating >= 75 && <StarIcon size={15}/>*/}
            {/*}*/}
            <StarIcon size={14}/>
        </View>
    )
}
