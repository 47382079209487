import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {Layout} from "../Layout";

const DisclaimersScreen = () => {
    return(
        <Layout>
            <View style={{padding: 24}}>
                <Text style={{fontSize: 22, fontWeight: 'bold', marginVertical: 16}}>Disclaimers</Text>

                <Text style={styles.text}>
                    Africa One Stop is a directory that connects users with African-owned or African-themed businesses. We do not endorse any business listed on our platform. We are not responsible for any interactions or transactions between users and businesses. Users are solely responsible for conducting their due diligence before transacting with any business listed on this platform. Please read this disclaimer carefully before using this platform. By using this platform, you agree to the terms of this disclaimer.
                </Text>
                <Text style={styles.header}>
                    Liability
                </Text>
                <Text style={styles.text}>
                    Africa One Stop is not responsible for any losses or damages that may result from your use of our platform. We do not warrant that our platform will be error-free or uninterrupted, and we are not responsible for any disruptions or interruptions that may occur.
                </Text>
                <Text style={styles.header}>
                    Accuracy of Information
                </Text>
                <Text style={styles.text}>
                    We strive to provide accurate and up-to-date information on our platform, but we make no guarantees as to the accuracy, completeness, or reliability of any information on our platform. We are not responsible for any errors or omissions that may be present on our platform.
                </Text>
                <Text style={styles.header}>
                    Endorsement Disclaimer
                </Text>
                <Text style={styles.text}>
                    Africa One Stop does not endorse any businesses or services on our platform. We provide a directory of businesses and services for informational purposes only. Users of our platform are responsible for conducting their own research and due diligence before engaging with any businesses or services listed on our platform.
                </Text>
                <Text style={styles.header}>
                    External Links
                </Text>
                <Text style={styles.text}>
                    Our platform may contain links to external websites not owned or controlled by Africa One Stop. We are not responsible for these external websites' content or privacy practices. By using our platform, you acknowledge that Africa One Stop is not responsible for any losses or damages that may result from your use of these external websites.
                </Text>
                <Text style={styles.text}>
                    Thank you for using Africa One Stop. If you have any questions or concerns about this disclaimer, please contact us using the information provided on our contact page.
                </Text>
            </View>
        </Layout>
    )
}

const styles = StyleSheet.create({
    header: {
        fontSize: 20,
        fontWeight: 'bold',
        marginVertical: 8
    },
    text: {
        marginVertical: 4
    }
})

export default DisclaimersScreen;
