import React, {useEffect, useRef, useState} from 'react';
import {View, StyleSheet, Text, TouchableOpacity, Linking, ActivityIndicator, Platform} from "react-native";
import {Layout} from "../Layout";
import {ArrowLeft, ArrowRight, Call, Global, Heart, InfoCircle, Lovely, Map, Message, Sms} from "iconsax-react-native";
import {AppImage} from "../../components/AppImage";
import {AppCard} from "../../components/AppCard";
import {AppSpacing} from "../../components/AppSpacing";
import Carousel from "react-native-reanimated-carousel/src/Carousel";
import {useDimension} from "../../hooks/useDimension";
import {getIsMobileDevice, parseUrl} from "../../utils/helpers";
import {AppTextInput} from "../../components/AppTextInput";
import {AppButton} from "../../components/AppButton";
import {Ratings} from "../home/components/Ratings";
import {FacebookIcon, InstagramIcon, TwitterIcon} from "../../components/Icons";
import {useRoute} from "@react-navigation/core";
import useBusinessesQuery from "../../hooks/useBusinessesQuery";
import useTheme from "../../hooks/useTheme";
import {updateLoginModalStatus} from "../../contexts/reducers";
import {useAppContext} from "../../contexts/AppContext";
import {useParams} from "react-router";
import {useToast} from "react-native-toast-notifications";
import useAppNavigation from "../../hooks/useNavigation";
import {AppTextButton} from "../../components/AppTextButton";
import useAnalyticsQuery from "../../hooks/useAnanlyticsQuery";
import {Chart} from "./components/Chart";

const styles = StyleSheet.create({
    fullScreenSlider: {
        position: 'relative',
        width: '100%'
    },
    column: {
        flexDirection: 'column',
        justifyContent: null
    },
    row:{
        flexDirection: 'row',
        alignItems: 'center'
    },
    logoContainer: {
        flexDirection: 'row',
        position: 'absolute',
        bottom:0,
        marginBottom: -75,
        width: '100%',
        paddingHorizontal: 16,
        alignItems: 'flex-end',
        justifyContent: 'space-between',
    },
    logo: {
        borderWidth: 5,
        borderRadius: 75,
        width: 150,
        height: 150,
        borderColor: 'white',
    },
    mapContainer: {
        position: 'relative',
        width: '100%',
        height: 300,
    },
    mapOverlay: {
        position: 'absolute',
        top:0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        width: '100%',
        zIndex: 99,
        justifyContent: 'center',
        alignItems: 'center',
    },
    mapOverlayText: {
        fontSize: 20,
        color: 'white',
        fontWeight: 'bold'
    },
    reviewItem: {
        marginVertical: 8,
        borderTopWidth: 1,
        paddingVertical: 8,
        borderTopColor: '#Dadada'
    },
    likeButton: {
        width: 40,
        height: 40,
        justifyContent: 'center',
        alignItems: 'center'
    },
    likeCounter: {
        marginBottom: 30
    },
    leftArrow: {
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 9,
    },
    rightArrow: {
        position: 'absolute',
        right: 0,
        top: 0,
        zIndex: 9,
    },
    arrowButton: {
        height: 100,
        width: 50,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        justifyContent: 'center',
        alignItems: 'center'
    }
})

export const BusinessViewScreen = () => {
    const {width} = useDimension();
    const isMobile = getIsMobileDevice();
    const [imageIndex, setImageIndex]  = useState(0);
    const carousalRef = useRef();
    const [viewportWidth, setViewportWidth] = useState(0);
    const theme = useTheme();
    const {state, dispatch} = useAppContext();
    const {redirectTo} = useAppNavigation();
    const {addAnalyticEvent} = useAnalyticsQuery();
    let slug = '';
    if(Platform.OS === 'web') {
        const params = useParams();
        slug = params?.slug;
    }
    else{
        const route = useRoute();
        const {params} = route;
        slug = params?.slug;
    }

    const toast = useToast();

    const {fetchBusinessDetails, addReview, deleteReview, addLikeOrUnlike} = useBusinessesQuery();

    const [business, setBusiness] = useState({});
    const [loading, setLoading] = useState(true);
    const isOwner = state?.user?.id && business?.owner_id === state?.user?.id;
    const isAdmin = !!state?.user?.is_admin;

    const [userReview, setUserReview] = useState({
        rating: null,
        comment: null
    })

    const coverImages = business?.cover_images ? business?.cover_images : [];

    useEffect(() => {
        if(slug) {
            (async() => {
                setLoading(true);
                await refreshBusinessDetails(slug);
                setLoading(false);
            })()
        }
    }, [slug])

    const refreshBusinessDetails = async(slug) => {
        console.log('slug', slug);
        const response = await fetchBusinessDetails(slug);
        console.log('response', response);
        setBusiness(response);
    }

    const handleLeftPress = () => {
        if(imageIndex > 0) {
            carousalRef?.current.scrollTo({index: imageIndex - 1, animated: true})
            setImageIndex(imageIndex - 1);
        }
    }

    const handleRightPress = () => {
        if(imageIndex < coverImages?.length - 1) {
            carousalRef?.current.scrollTo({index: imageIndex + 1, animated: true})
            setImageIndex(imageIndex + 1)
        }
    }

    const handleReviewSubmit = async() => {
        if(state?.authToken) {
            if(userReview?.rating && userReview?.comment) {
                const response = await addReview(business?.id, userReview);
                if(response?.error) {
                    return;
                }
                toast.show('Review added');
                setUserReview({
                    rating: null,
                    comment: null
                })

                await refreshBusinessDetails(slug);
            }
        }
        else{
            dispatch(updateLoginModalStatus(true))
        }

    }

    const handleLikeUnlike = async() => {
        if(state?.authToken) {
            const response = await addLikeOrUnlike(business?.id);
            if(response?.error) {
                return;
            }

            await refreshBusinessDetails(slug);
        }
        else{
            redirectTo('Login')
        }
    }

    const handleDeleteReview = async(review) => {
        const response = await deleteReview(review?.business_id, review?.id);
        if(response?.error) {
            return;
        }

        toast.show('Review has been deleted');

        await refreshBusinessDetails(slug);
    }

    const makeCall = (phone) => {

        const strippedPhone = phone.toString().replace(/\s/g, "");
        Linking.openURL(`tel:${strippedPhone}`);
    }

    const handleSocialRedirect = (link) => {
        if(link) {
            Linking.openURL(parseUrl(link));
        }

    }

    const onWebsiteClick = async(link) => {
        const payload = {
            event: 'website_click',
            business_id: business?.id
        }
        addAnalyticEvent(payload)
        Linking.openURL(parseUrl(link));
    }


    return(
        <Layout isStack={true}>
            {
                loading ?
                    <View style={{width: '100%', height: 400, alignItems: 'center', justifyContent: 'center'}}>
                        <ActivityIndicator color={theme.colors.primary}/>
                    </View>
                    :
                    <View style={{flex: 1, width: '100%'}}>
                        <View style={styles.fullScreenSlider} onLayout={(event) => {
                            const {x, y, width, height} = event.nativeEvent.layout;
                            setViewportWidth(width);
                        }} >
                            {
                                viewportWidth > 0 ?
                                    <Carousel
                                        ref={carousalRef}
                                        loop={false}
                                        width={viewportWidth}
                                        height={isMobile ? viewportWidth * 0.6 : viewportWidth * 0.25}
                                        autoPlay={false}
                                        data={coverImages}
                                        scrollAnimationDuration={500}
                                        defaultIndex={0}
                                        onSnapToItem={(index) => setImageIndex(index)}
                                        renderItem={({ index }) => (
                                            <View
                                                key={`cover-${index}`}
                                                style={{
                                                    flex: 1,
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <AppImage
                                                    isLocal={false}
                                                    width={viewportWidth}
                                                    roundness={0}
                                                    height={isMobile ? viewportWidth * 0.6 : viewportWidth * 0.25}
                                                    uri={coverImages[index]}/>
                                            </View>
                                        )}
                                    />
                                    :
                                    <View style={{height: isMobile ? viewportWidth * 0.6 : viewportWidth * 0.25}}/>
                            }

                            <View style={[styles.leftArrow, {marginTop: isMobile ? (viewportWidth * 0.6 /2) - 50 : (viewportWidth * 0.25/ 2) - 50}]}>
                                <TouchableOpacity onPress={handleLeftPress} activeOpacity={0.7} style={styles.arrowButton}>
                                    <ArrowLeft color={'white'}/>
                                </TouchableOpacity>
                            </View>
                            <View style={[styles.rightArrow, {marginTop: isMobile ? (viewportWidth * 0.6/ 2) - 50 : (viewportWidth * 0.25 / 2) - 50}]}>
                                <TouchableOpacity onPress={handleRightPress} activeOpacity={0.7} style={styles.arrowButton}>
                                    <ArrowRight color={'white'}/>
                                </TouchableOpacity>
                            </View>
                            <View style={[styles.logoContainer, {justifyContent: isMobile ? 'space-between' : null}]}>
                                <View style={styles.logo}>
                                    <AppImage
                                        width={'100%'}
                                        resizeMode={'cover'}
                                        roundness={100}
                                        height={'100%'}
                                        uri={business?.logo}
                                        isLocal={false}/>
                                </View>
                                {
                                    !isMobile &&  <AppSpacing gap={16} isHorizontal={true}/>
                                }

                                <View style={[styles.row, styles.likeCounter]}>
                                    <TouchableOpacity onPress={handleLikeUnlike} activeOpacity={0.5} style={styles.likeButton}>
                                        <Heart variant={business?.has_liked ? 'Bold' : 'Linear'} color={'red'}/>
                                    </TouchableOpacity>
                                    <Text>{business?.likes_count}</Text>
                                    {
                                        isMobile ? <Text> {business?.likes_count > 1 ? 'Likes' : 'Like'}</Text> :  <Text> Peoples likes this business</Text>
                                    }
                                </View>
                            </View>
                        </View>
                        <AppSpacing gap={75}/>
                        <View style={{flexDirection: isMobile ? 'column' : 'row', padding: 8}}>
                            <View style={{flex: 2}}>
                                <AppCard>
                                    <Text style={{fontSize: 20, fontWeight: 'bold'}}>{business?.name}</Text>
                                    <AppSpacing gap={8}/>
                                    <View style={styles.row}>
                                        <Text style={{fontWeight: 'bold'}}>{business?.meta?.avg_rating ? business?.meta?.avg_rating : '0.0'}</Text>
                                        <AppSpacing isHorizontal={true} gap={4}/>
                                        <Ratings ratings={business?.meta?.avg_rating}/>
                                    </View>
                                    <AppSpacing gap={16}/>
                                    <View>
                                        <TouchableOpacity onPress={() => Linking.openURL(`https://maps.google.com/maps?q=${encodeURIComponent(business?.formatted_address)}`)} style={styles.row}>
                                            <Map size={20} color={'black'}/>
                                            <AppSpacing isHorizontal={true} gap={8}/>
                                            <Text>{business?.full_address}</Text>
                                        </TouchableOpacity>
                                        <AppSpacing gap={8}/>
                                        <TouchableOpacity onPress={() => onWebsiteClick(business?.website)} style={styles.row}>
                                            <Global size={20} color={'black'}/>
                                            <AppSpacing isHorizontal={true} gap={8}/>
                                            <Text>{business?.website}</Text>
                                        </TouchableOpacity>
                                        <AppSpacing gap={8}/>
                                        <TouchableOpacity activeOpacity={0.8} onPress={() => makeCall(business?.phone)} style={styles.row}>
                                            <Call size={20} color={'black'}/>
                                            <AppSpacing isHorizontal={true} gap={8}/>
                                            <Text>{business?.phone}</Text>
                                        </TouchableOpacity>
                                        <AppSpacing gap={8}/>
                                        <TouchableOpacity onPress={() => Linking.openURL(`mailto:${business?.email}`)} style={styles.row}>
                                            <Sms size={20} color={'black'}/>
                                            <AppSpacing isHorizontal={true} gap={8}/>
                                            <Text>{business?.email}</Text>
                                        </TouchableOpacity>
                                    </View>
                                    <AppSpacing gap={16}/>
                                    <View>
                                        <View style={{flexDirection: 'row'}}>
                                            <TouchableOpacity onPress={() => handleSocialRedirect(business?.facebook)}>
                                                <FacebookIcon/>
                                            </TouchableOpacity>
                                            <AppSpacing gap={16} isHorizontal={true}/>
                                            <TouchableOpacity onPress={() => handleSocialRedirect(business?.twitter)}>
                                                <TwitterIcon size={25}/>
                                            </TouchableOpacity>
                                            <AppSpacing gap={16} isHorizontal={true}/>
                                            <TouchableOpacity onPress={() => handleSocialRedirect(business?.instagram)}>
                                                <InstagramIcon size={25}/>
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                    <AppSpacing gap={16}/>
                                    {
                                        business?.timing &&  <Text style={{fontWeight: 'bold'}}>Timings</Text>
                                    }

                                    {
                                        business?.timing  && Object.keys(business?.timing).map((item, index) => {
                                            return(
                                                <>
                                                    <AppSpacing gap={8}/>
                                                    <View style={[styles.row, {justifyContent: 'space-between', width: isMobile ? '100%' : '50%'}]}>
                                                        <Text>{item}</Text>
                                                        <Text>{business?.timing[item]}</Text>
                                                    </View>
                                                </>
                                            )
                                        })
                                    }
                                </AppCard>
                                <AnalyticsCard isMobile={isMobile} isOwner={isOwner || isAdmin} business={business}/>
                                <AppCard >
                                    <View>
                                        <View style={styles.row}>
                                            <InfoCircle size={20}/>
                                            <AppSpacing gap={8} isHorizontal={true}/>
                                            <Text style={{fontWeight: 'bold'}}>About</Text>
                                        </View>
                                        <AppSpacing gap={16}/>
                                        <Text>{business?.about}</Text>
                                    </View>
                                </AppCard>
                                {
                                    isMobile &&  <MapCard onPress={() => Linking.openURL(`https://maps.google.com/maps?q=${encodeURIComponent(business?.formatted_address)}`)}/>
                                }
                                <View style={{paddingVertical: 8, paddingHorizontal: 16}}>
                                    <Text style={{fontSize: 20, fontWeight: 'bold', color: 'grey'}}>Reviews</Text>
                                </View>
                                <AppCard>
                                    {
                                        business?.reviews?.length > 0 ?
                                            <View>
                                                {
                                                    business?.reviews?.length > 0 && business?.reviews.map((item, index) => {
                                                        return <ReviewItem deleteReview={() => handleDeleteReview(item)} isOwner={business?.owner_id === state?.user?.id} review={item} hasBorder={index !== 0} key={`review-${index}`}/>
                                                    })
                                                }
                                            </View>
                                            :
                                            <Text>No reviews available</Text>
                                    }

                                </AppCard>
                            </View>
                            <View style={{flex: isMobile ? null : 1}}>
                                {
                                    (isOwner || isAdmin) ? !isMobile && <AnalyticStats business={business}/> : null
                                }
                                {
                                    !isMobile &&  <MapCard onPress={() => Linking.openURL(`https://maps.google.com/maps?q=${encodeURIComponent(business?.formatted_address)}`)}/>
                                }


                                {
                                    (isOwner || isAdmin) ?
                                        <View>
                                        </View>
                                        :
                                        <View>
                                            {
                                                business?.has_review ?
                                                    <Text style={{padding: 16}}>You've already rate this business</Text>
                                                    :
                                                    <AppCard padding={0}>
                                                        <AppSpacing gap={16}/>
                                                        <View style={{padding: 16}}>
                                                            <Text>Rate this business</Text>
                                                        </View>
                                                        <View style={{
                                                            flexDirection: 'row',
                                                            padding: 8,
                                                            justifyContent: 'space-between'
                                                        }}>
                                                            {
                                                                [...new Array(5).keys()].map((item, index) => {
                                                                    return (
                                                                        <TouchableOpacity onPress={() => {
                                                                            setUserReview({
                                                                                ...userReview,
                                                                                rating: item + 1,
                                                                            })
                                                                        }} style={{
                                                                            paddingVertical: 10,
                                                                            marginHorizontal: 8,
                                                                            paddingHorizontal: 16,
                                                                            borderRadius: 8,
                                                                            backgroundColor: userReview?.rating === item + 1 ? theme.colors.primary : '#f3f3f3'
                                                                        }}>
                                                                            <Text
                                                                                style={{color: userReview?.rating === item + 1 ? 'white' : 'black'}}>{item + 1}</Text>
                                                                        </TouchableOpacity>
                                                                    )
                                                                })
                                                            }
                                                        </View>
                                                        <View style={{
                                                            paddingHorizontal: 16,
                                                            paddingTop: 8,
                                                            paddingBottom: 16
                                                        }}>
                                                            <Text>Write your review</Text>
                                                            <AppSpacing gap={16}/>
                                                            <AppTextInput onChangeText={(v) => setUserReview({
                                                                ...userReview,
                                                                comment: v,
                                                            })} multiline={true}
                                                                          numberOfLines={4} height={150}/>
                                                            <AppSpacing gap={16}/>
                                                            <AppButton onPress={handleReviewSubmit}
                                                                       title={"Submit Review"}/>
                                                        </View>
                                                    </AppCard>
                                            }
                                        </View>
                                }
                            </View>
                        </View>
                    </View>
            }

        </Layout>
    )
}

const AnalyticsCard = ({isMobile, business, isOwner}) => {
    const [ad, setAd] = useState([]);
    const {getAnalytic} = useAnalyticsQuery();
    useEffect(() => {
        (async() => {
           const response =  await getAnalytic(business?.id);
           if(!response?.error) {
               setAd(response);
           }
        })()
    }, [])
    return( isOwner &&
        <View>
            {isMobile && <AnalyticStats business={business}/>}
            <AppCard>
                <Text style={{fontWeight: 'bold', marginBottom: 16}}>Profile Views</Text>
                <View>
                    {ad.length > 0 && <Chart chartData={ad}/>}
                    {ad.length === 0 && <Text>No analytics data available to display</Text>}
                </View>
            </AppCard>
        </View>
    )
}

const AnalyticStats = ({business}) => {
    return(
        <View style={{flexDirection: 'row'}}>
           <View style={{flex: 2}}>
               <AppCard>
                   <Text style={{fontWeight: 'bold', marginBottom: 8}}>Views</Text>
                   <Text style={{fontWeight: 'bold', fontSize: 20}}>{business?.profile_views ? business?.profile_views : 0 }</Text>
               </AppCard>
           </View>
            <View style={{flex: 3}}>
            <AppCard>
                <Text style={{fontWeight: 'bold', marginBottom: 8}}>Website Clicks</Text>
                <Text style={{fontWeight: 'bold', fontSize: 20}}>{business?.website_clicks ? business?.website_clicks : 0}</Text>
            </AppCard>
            </View>
            <View style={{flex: 2}}>
            <AppCard>
                <Text style={{fontWeight: 'bold', marginBottom: 8}}>Reviews</Text>
                <Text style={{fontWeight: 'bold', fontSize: 20}}>{business?.reviews_count ? business?.reviews_count : 0}</Text>
            </AppCard>
            </View>
        </View>
    )
}

const MapCard = ({onPress}) => {
    return(
        <TouchableOpacity onPress={onPress} activeOpacity={0.8}>
            <AppCard padding={0}>
                <View style={styles.mapContainer}>
                    <View style={styles.mapOverlay}>
                        <Text style={styles.mapOverlayText}>Open in Map</Text>
                    </View>
                    <AppImage height={'100%'} width={'100%'} uri={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlnQNrN0H7qc-Q_bv_kl8V9O6WUUIXz9ypnQ&usqp=CAU'} isLocal={false}/>
                </View>
            </AppCard>
        </TouchableOpacity>
    )
}

const ReviewItem = ({hasBorder, review, isOwner, deleteReview}) => {
    const {state} = useAppContext();
    if(!review?.user) {
        return null;
    }

    return(
        <View style={[styles.reviewItem, {borderTopWidth: hasBorder ? 1 : 0, marginTop: hasBorder ? 8 : 0, paddingTop: hasBorder ? 8 : 0}]}>
            <View style={styles.row}>
                <AppImage width={35} height={35} roundness={16} uri={review?.user && review?.user.avatar ? review?.user?.avatar : require('../../../assets/img/avatar-placeholder.jpeg')} isLocal={!review?.user.avatar}/>
                <AppSpacing gap={8} isHorizontal={true}/>
                <Text>{review?.user?.name}</Text>
            </View>
            <AppSpacing gap={8}/>
            <View style={[styles.row, {alignItems: 'center'}]}>
                <Text style={{fontWeight: 'bold'}}>{review?.rating}</Text>
                <AppSpacing gap={4} isHorizontal={true}/>
                <Ratings ratings={review?.rating}/>
                <AppSpacing gap={8} isHorizontal={true}/>
                <Text>{review?.time}</Text>
            </View>
            <AppSpacing gap={8}/>
            <View>
                <Text>{review?.comment}</Text>
            </View>
            {
                (isOwner || state.user?.id === review?.user_id) &&
                <View style={{marginTop: 8}}>
                    <AppTextButton onPress={deleteReview} color={'red'}  title={"Delete"}/>
                </View>
            }
        </View>
    )
}
