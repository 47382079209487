import useRequest from "./useRequest";

const useBusinessesQuery = () => {
    const request = useRequest();

    const fetchRecommendedBusiness = () => {
        return request.get('/businesses/recommended').then(res => res);
    }

    const fetchNearestBusinesses = (lat, lon) => {
        return request.get(`/businesses/nearest?lat=${lat}&lon=${lon}`).then(res => res);
    }

    const fetchBusinesses = (query) => {
        return request.get(`/businesses?${query}`).then(res => res);
    }

    const fetchBusinessDetails = (slug) => {
        return request.get(`/businesses/${slug}`).then(res => res);
    }

    const addReview = (id, payload) => {
        return request.post(`/businesses/${id}/review`, payload).then(res => res);
    }

    const deleteReview = (businessId, reviewId) => {
        return request.remove(`/businesses/${businessId}/review/${reviewId}`).then(res => res);
    }

    const addLikeOrUnlike = (id) => {
        return request.post(`/businesses/${id}/like`, {dummy: 'a'}).then(res => res);
    }

    const addBusiness = async(payload) => {
        return request.post(`/businesses`, payload).then(res => res);
    }

    const updateBusiness = async(payload, id) => {
        return request.put(`/businesses/${id}`, payload).then(res => res);
    }

    const getMyBusinesses = async() => {
        return request.get('/my-businesses').then(res => res);
    }

    const getBusinessById = (id) => {
        return request.get(`/businesses/${id}/find`).then(res => res);
    }

    const togglePublish = (id, shouldPublish) => {
        console.log('shouldPublish', shouldPublish);
        return request.put(`/businesses/${id}`, {is_draft: 0, is_active: shouldPublish}).then(res => res);
    }

    const deleteBusiness = (id) => {
        return request.remove(`/businesses/${id}`).then(res => res);
    }

    const addPublishableBusiness = (businessId, payload) => {
        return request.post(`/businesses/${businessId}/publishable`, payload).then(res => res);
    }

    return {
        fetchRecommendedBusiness,
        fetchNearestBusinesses,
        fetchBusinesses,
        fetchBusinessDetails,
        addReview,
        deleteReview,
        addLikeOrUnlike,
        addBusiness,
        updateBusiness,
        getMyBusinesses,
        getBusinessById,
        togglePublish,
        deleteBusiness,
        addPublishableBusiness
    }
}

export default useBusinessesQuery;
