import {View} from "react-native";
import {ResponsiveRow} from "./ResponsiveRow";
import {AppTextInput} from "../../../components/AppTextInput";
import {AppSpacing} from "../../../components/AppSpacing";
import {AppButton} from "../../../components/AppButton";

export const SocialForm = ({initialValues, onFormChange, onContinuePress}) => {
    return(
        <View style={{paddingVertical: 16}}>
            <ResponsiveRow>
                <AppTextInput disabled value={'Facebook'}/>
                <AppTextInput autoCapitalize={false} value={initialValues?.facebook} onChangeText={e => onFormChange({...initialValues, facebook: e})} placeholder={''}/>
            </ResponsiveRow>
            <AppSpacing gap={16}/>
            <ResponsiveRow>
                <AppTextInput disabled value={'Instagram'}/>
                <AppTextInput autoCapitalize={false} value={initialValues?.instagram} onChangeText={e => onFormChange({...initialValues, instagram: e})} placeholder={''}/>
            </ResponsiveRow>
            <AppSpacing gap={16}/>
            <ResponsiveRow >
                <AppTextInput disabled value={'Twitter'}/>
                <AppTextInput autoCapitalize={false} value={initialValues?.twitter} onChangeText={e => onFormChange({...initialValues, twitter: e})} placeholder={''}/>
            </ResponsiveRow>
            <View style={{marginVertical: 16}}>
                <AppButton onPress={onContinuePress} title={"Save"}/>
            </View>
        </View>
    )
}
