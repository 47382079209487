import useRequest from "./useRequest";

const useAddressQuery = () => {
    const request = useRequest();

    const validateAddress = async(address) => {
        return await request.post('/address/validate', {address: address}).then(res => res);
    }

    const autoCompleteSearch = async(payload) => {
        return await request.post('/address/autocomplete', payload).then(res => res);
    }

    return {
        validateAddress,
        autoCompleteSearch
    }
}

export default useAddressQuery;
