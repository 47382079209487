export const PAYMENT_LINK_SILVER = "https://buy.stripe.com/test_28o7uj7Akcw25q03cd"
export const PAYMENT_LINK_GOLD="https://buy.stripe.com/test_fZedSH2g01RoaKk002"
// export const PLAN_1="https://buy.stripe.com/test_cN23fHdoJ08MauQfYY";
// export const PLAN_2="https://buy.stripe.com/test_6oE4jL1G1f3G5awdQR";
// export const PLAN_3="https://buy.stripe.com/test_14k5nPdoJ6xaauQ002";
// export const PLAN_4="https://buy.stripe.com/test_8wMcQh5Wh8Fi5aw3cf";
// export const PLAN_5="https://buy.stripe.com/test_cN203vgAV8FigTe7sw";

export const PLAN_1="https://buy.stripe.com/3cs4gDfUn3JNdVK000";
export const PLAN_2="https://buy.stripe.com/4gwbJ5gYr94718Y7st";
export const PLAN_3="https://buy.stripe.com/9AQ00n5fJbcfdVKfZ0";
export const PLAN_4="https://buy.stripe.com/6oEbJ523x6VZbNC7sv";
export const PLAN_5="https://buy.stripe.com/5kA5kH0Zt4NR2d2cMQ";

export const APPLE_PLAN_1="aos0001";
export const APPLE_PLAN_2="aos0002";
export const APPLE_PLAN_3="aos0003";
export const APPLE_PLAN_4="aos0004";
export const APPLE_PLAN_5="aos0005";

export const GOOGLE_PLAN_1="aos0001";
export const GOOGLE_PLAN_2="aos0002";
export const GOOGLE_PLAN_3="aos0003";
export const GOOGLE_PLAN_4="aos0004";
export const GOOGLE_PLAN_5="aos0005";
