import React from 'react';
import {Platform, Image, ImageBackground,Linking, ScrollView, Text, TouchableOpacity, View, StyleSheet} from 'react-native';
import {AppSpacing} from "../components/AppSpacing";
import {useDimension} from "../hooks/useDimension";
import {getIsMobileDevice} from "../utils/helpers";
import {FacebookIcon, InstagramIcon, TwitterIcon} from "../components/Icons";
import useAppNavigation from "../hooks/useNavigation";
import {DrawerActions, useNavigation} from "@react-navigation/native";
import {useNavigate} from "react-router";

const styles = StyleSheet.create({
    downloadAppContainer: {
        paddingVertical: 100,
        flex: 1,
    },
    faqHeader: {
        padding: 16,
    }
})

export const Footer = () => {
    return(
        <View>
            {
                Platform.OS === 'web' && <DownloadApp/>
            }
            <ImportantLinks/>
            <CopyRight/>
        </View>
    )
}


const DownloadApp = () => {
    const {height} = useDimension();
    const isMobile = getIsMobileDevice();

    return(
        <View style={{height: 500}}>
            <ImageBackground style={[styles.downloadAppContainer, {flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}]} resizeMode="cover" source={require('../../assets/img/background.jpeg')}>
                <View style={{maxWidth: 1140, flex: 1}}>
                    {
                        !isMobile &&   <View style={{position: 'absolute', marginTop: -50, marginLeft: 50, zIndex: 99}}>
                            <Image style={{width: 300, height: 400}} resizeMode="contain" source={require('../../assets/img/download-app.png')}/>
                        </View>
                    }


                    <View style={{marginLeft: isMobile ? 0 : 150, justifyContent: 'center', alignItems: 'center', paddingVertical: 50, backgroundColor: '#590100'}}>
                        <View style={{padding: 16}}>
                            <Text style={{fontWeight: 'bold', color: 'white', fontSize: 20}}>{'AFRICA ONE STOP  \nReady To Go'}</Text>
                            <AppSpacing gap={16}/>
                            <Text style={{ color: 'white'}}>Download our mobile application to search for any business on the go</Text>
                            <AppSpacing gap={16}/>
                            <View style={{flexDirection: 'row'}}>
                                <TouchableOpacity>
                                    <Image style={{height: 70, width: 150}} resizeMode={'contain'} source={require('../../assets/img/playstore.png')}/>
                                </TouchableOpacity>
                                <AppSpacing isHorizontal={true} gap={16}/>
                                <TouchableOpacity>
                                    <Image style={{height: 70, width: 150}} resizeMode={'contain'} source={require('../../assets/img/applestore.png')}/>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </View>
            </ImageBackground>
        </View>
    )
}

const ImportantLinks = () => {
    const isMobile = getIsMobileDevice();
    const navigation = useNavigation();
    const navigate = useNavigate();
    const {redirectTo} = useAppNavigation();
    const handleRedirects = (path) => {
        setTimeout(() => {
            redirectTo(path);
        }, 500)
    };

    const redirectLinks = (path) => {
        if(Platform.OS === 'web') {
            navigate(`/${path.toLowerCase()}`)
        }
        else{
            navigation.navigate(path);
        }
    }

    const openUrl = (url) => {
        Linking.openURL(url);
    }

    return(
        <View style={{backgroundColor: '#1E1E1E', flex: 1, padding: 32}}>
            <View style={{flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                <View style={{flex: 1, flexDirection: isMobile ? 'column' : 'row', maxWidth: 1140}}>
                    <View style={{flex: 1, alignItems: 'center'}}>
                        <Image style={{width: 100, height: 100}} source={require('../../assets/logo-white.png')}/>
                    </View>
                    <AppSpacing gap={32}/>
                    <View style={{flex: isMobile ? null : 1, alignItems: isMobile ? 'center' : 'flex-start'}}>
                        <Text style={{color: 'white', fontWeight: 'bold'}}>Connect with Us</Text>
                        <AppSpacing gap={16}/>
                        <View style={{flexDirection: 'row'}}>
                            <TouchableOpacity onPress={() => openUrl('https://www.facebook.com/profile.php?id=100089622135223&mibextid=LQQJ4d')}>
                                <FacebookIcon white={true}/>
                            </TouchableOpacity>
                            <AppSpacing gap={16} isHorizontal={true}/>
                            <TouchableOpacity onPress={() => openUrl('https://twitter.com/africaonestop')}>
                                <TwitterIcon white={true}/>
                            </TouchableOpacity>
                            <AppSpacing gap={16} isHorizontal={true}/>
                            <TouchableOpacity onPress={() => openUrl('https://www.instagram.com/africa_one_stop')}>
                                <InstagramIcon white={true}/>
                            </TouchableOpacity>
                        </View>
                    </View>
                    <AppSpacing gap={32}/>
                    <View style={{flex: isMobile ? null : 1, alignItems: isMobile ? 'center' : 'flex-start'}}>
                        <Text style={{color: 'white', fontWeight: 'bold'}}>Talk To Us</Text>
                        <AppSpacing gap={16}/>
                        <TouchableOpacity onPress={() => handleRedirects('AboutUs')}>
                            <Text style={{color: 'white'}}>About Us</Text>
                        </TouchableOpacity>
                        <AppSpacing gap={16}/>
                        <TouchableOpacity onPress={() => handleRedirects('ContactUs')}>
                            <Text style={{color: 'white'}}>Contact Us</Text>
                        </TouchableOpacity>
                    </View>
                    <AppSpacing gap={32}/>
                    <View style={{flex: isMobile ? null : 1, alignItems: isMobile ? 'center' : 'flex-start'}}>
                        <TouchableOpacity onPress={() => redirectLinks('Privacy')}>
                            <Text style={{color: 'white'}}>Privacy Policy</Text>
                        </TouchableOpacity>
                        <AppSpacing gap={16}/>
                        <TouchableOpacity onPress={() => redirectLinks('Terms')}>
                            <Text style={{color: 'white'}}>Terms & Conditions</Text>
                        </TouchableOpacity>
                        <AppSpacing gap={16}/>
                        <TouchableOpacity onPress={() => redirectLinks('Disclaimers')}>
                            <Text style={{color: 'white'}}>Disclaimers</Text>
                        </TouchableOpacity>
                        <AppSpacing gap={16}/>
                        <TouchableOpacity onPress={() => redirectLinks('Support')}>
                            <Text style={{color: 'white'}}>Support</Text>
                        </TouchableOpacity>
                        <AppSpacing gap={16}/>
                        <TouchableOpacity onPress={() => redirectLinks('Faq')}>
                            <Text style={{color: 'white'}}>FAQs</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        </View>
    )
}

const CopyRight = () => {
    return(
        <View style={{height: 70, padding: 16, justifyContent: 'center', alignItems: 'center', backgroundColor: '#171616'}}>
            <Text style={{color: 'white'}}>@ 2023 Africa One Stop. All Rights Reserved</Text>
        </View>
    )
}
