import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {Layout} from "../Layout";

const SupportScreen = () => {
    return(
        <Layout>
            <View style={{padding: 24}}>
                <Text style={{fontSize: 22, fontWeight: 'bold', marginVertical: 16}}>Customer Support</Text>


                <Text style={styles.subtitle}>Frequently Asked Questions</Text>
                <Text style={styles.text}>
                    Before contacting our support team, please check our Frequently Asked Questions (FAQs) page. We have compiled a list of common questions and answers about our platform to help you find the information you need quickly and easily.
                </Text>
                <Text style={styles.subtitle}>Submit a Support Request</Text>
                <Text style={styles.text}>
                    If you cannot find the information you need on our FAQs page, you can submit a support request using our contact form. Please provide as much detail as possible about your question or issue, and one of our support team members will get back to you as soon as possible.
                </Text>
                <Text style={styles.subtitle}>Troubleshooting</Text>
                <Text style={styles.text}>
                    If you are experiencing issues with our platform, please check our troubleshooting page. We have compiled a list of common issues and solutions to help you resolve your issue quickly and easily.
                </Text>
                <Text style={styles.subtitle}>Contact Support</Text>
                <Text style={styles.text}>
                    If you need further assistance, you can contact our support team directly using the contact information provided on our contact page. We are available during regular business hours and will do our best to help you with any questions or concerns.
                </Text>
                <Text style={styles.text}>Thank you for using Africa One Stop. We are here to help you find the African businesses and services you need, and we are committed to providing the best possible support to our users.</Text>
            </View>
        </Layout>
    )
}

const styles = StyleSheet.create({
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        marginVertical: 8
    },
    subtitle: {
        fontSize: 20,
        fontWeight: 'bold',
        marginVertical: 8
    },
    text: {
        marginVertical: 4
    }
})

export default SupportScreen;
