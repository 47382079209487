import {Dimensions, Platform} from "react-native";

export const getIsMobileDevice = () => {
    return Dimensions.get('window').width < 768;
}

export const serialize = function(obj) {
    let str = [];
    for (let p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
}

export const getImagePayload = uri => {
    const unix = Math.round(+new Date() / 1000);
    return {
        uri: uri,
        name: `${unix}.jpg`,
        type: 'image/jpeg',
    };
};


const isCloseToBottom = ({layoutMeasurement, contentOffset, contentSize}) => {
    return layoutMeasurement.height + contentOffset.y >= contentSize.height - 20;
}

export const parseUrl = (link) => {
    const regex = /^(https?:\/\/)/i;
    if(regex.test(link)) {
        return link;
    }
    return `https://${link}`;
}
