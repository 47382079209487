import React, {useState} from 'react';
import {View, Text, StyleSheet, Linking} from "react-native";
import {AppSpacing} from "../../components/AppSpacing";
import {Layout} from "../Layout";
import {AppCard} from "../../components/AppCard";
import useMessageQuery from "../../hooks/useMessageQuery";
import {getIsMobileDevice} from "../../utils/helpers";
import {useToast} from "react-native-toast-notifications";
import {AppTextInput} from "../../components/AppTextInput";
import {AppButton} from "../../components/AppButton";

export const ContactUsScreen = () => {
    const isMobile = getIsMobileDevice();
    const redirectTo = (link) => {
        Linking.openURL(link);
    }
    return(
        <Layout isStack={true}>
            <View style={{marginVertical: 32, flex: 1}}>
                <Text style={{fontSize: 20, padding: 16, fontWeight: 'bold'}}>Contact Us</Text>
                <AppSpacing gap={16}/>
                <View style={{flexDirection: isMobile ? 'column' : 'row', padding: 16}}>
                    <View style={{flex: isMobile ? null : 1, marginRight: 32}}>
                        <Text style={{fontWeight: 'bold'}}>We love to hear from our users! Whether you have a question, suggestion, or feedback, we are always happy to hear from you.</Text>
                        <Text style={{marginVertical: 8}}>Our office hours are Monday to Friday, 9 am – 5 pm EST. We aim to respond to all inquiries within 24 hours.</Text>
                        <Text>You can follow us or contact us on our social media pages.</Text>
                        <Text style={{marginTop: 16}}>Facebook: <Text style={styles.link} onPress={() => redirectTo('https://www.facebook.com/profile.php?id=100089622135223&mibextid=LQQJ4d')}>Africa One Stop</Text></Text>
                        <Text style={{marginTop: 16}}>Twitter: <Text style={styles.link} onPress={() => redirectTo('https://twitter.com/africaonestop')}>AfricaOneStop</Text></Text>
                        <Text style={{marginTop: 16}}>Instagram: <Text style={styles.link} onPress={() => redirectTo('https://www.instagram.com/africa_one_stop')}>@AfricaOneStop</Text></Text>
                        <Text style={{marginTop: 16}}>Email: <Text style={styles.link} onPress={() => redirectTo('mailto: hello@africaonestop.com')}>hello@africaonestop.com</Text></Text>
                        <Text style={{marginTop: 16}}>Website: <Text style={styles.link} onPress={() => redirectTo('https://africaonestop.com')}>www.africaonestop.com</Text></Text>
                        <Text style={{marginTop: 16, fontWeight: 'bold'}}>You can also send us a message directly</Text>
                    </View>
                    <View style={{flex: isMobile ? null : 1, marginTop: isMobile ? 32 : 0}}>
                        <AppCard marginHorizontal={0}>
                            <ContactForm/>
                        </AppCard>
                    </View>
                </View>
            </View>
        </Layout>
    )
}

const ContactForm = () => {
    const {sendMessage} = useMessageQuery();
    const isMobile = getIsMobileDevice();
    const initialData  = {
        name: '',
        email: '',
        message: '',
        businessName: ''
    }
    const [data, setData] = useState(initialData)
    const initialErrors = {
        name: false,
        email: false,
        message: false
    };
    const [errors, setErrors] = useState(initialErrors)
    const toast = useToast();
    const [loading, setLoading] = useState(false);
    const [messageSuccess, setMessageSuccess] = useState(false);
    const [messageError, setMessageError] = useState(false);


    const handleSendPress = async() => {
        setErrors(initialErrors);
        setMessageError(false);
        setMessageSuccess(false);
        if(!data.name) {
            setErrors({...errors, name: true})
            return;
        }
        if(!data.email) {
            setErrors({...errors, email: true})
            return;
        }
        if(!data.message) {
            setErrors({...errors, message: true})
            return;
        }

        setLoading(true);

        const response = await sendMessage(data)
        if(response?.error) {
            setMessageError(true);
            return;
        }
        setData({...initialData});
        setMessageSuccess(true);
        setLoading(false);
    }


    return(
        <View id={'contact-us'} naviteId={'contact-us'} style={{padding: 16, flex: 1, alignItems: 'center'}}>
            <View style={{width: '100%'}}>
                <Text style={{fontWeight: 'bold', fontSize: 20}}>Send us a Message</Text>
                <AppSpacing gap={16}/>
                <AppTextInput value={data?.name} onChangeText={e => setData({...data, name: e})} placeholder={'Name'}/>
                {
                    errors?.name &&
                    <Text style={{marginTop: 8, color: 'red'}}>Name is required</Text>
                }
                <AppSpacing gap={16}/>
                <AppTextInput value={data?.email} onChangeText={e => setData({...data, email: e})} placeholder={'Email'}/>
                {
                    errors?.email &&
                    <Text style={{marginTop: 8, color: 'red'}}>Email is required</Text>
                }
                <AppSpacing gap={16}/>
                <AppTextInput value={data?.businessName} onChangeText={e => setData({...data, businessName: e})} placeholder={'Business Name'}/>
                <AppSpacing gap={16}/>
                <AppTextInput value={data?.message} onChangeText={e => setData({...data, message: e})} multiline={true} height={100} placeholder={'Message'}/>
                {
                    errors?.message &&
                    <Text style={{marginTop: 8, color: 'red'}}>Message is required</Text>
                }
                <AppSpacing gap={16}/>
                <AppButton disabled={loading} loading={loading} onPress={handleSendPress} color={'#590100'} title={"Send"}/>
                <View style={{marginTop: 16}}>
                    {
                        messageError &&  <Text style={{color: 'red'}}>Unable to send message, try again.</Text>
                    }
                    {
                        messageSuccess &&  <Text style={{color: 'green'}}>Message has been sent</Text>
                    }

                </View>
            </View>
        </View>
    )
}

const styles =StyleSheet.create({
    link: {
        fontWeight: 'bold'
    }
})
