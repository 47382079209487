import React from 'react';
import {View, Text} from 'react-native';

export const BusinessStatus = ({status}) => {
    return(
        <View>
            {
                status === 'draft' &&
                <Text>
                    Your business status is in
                    <Text style={{fontWeight: 'bold'}}> Draft</Text>,
                    which means it is not published yet.
                </Text>}
            {
                status === 'pending' &&
                <Text>
                    Your business status is
                    <Text style={{fontWeight: 'bold'}}> Pending</Text>,
                    which means you have published the business but not approved by
                    <Text style={{fontWeight: 'bold'}}> Africa One Stop </Text>yet.
                </Text>
            }
            {
                status === 'published' &&
                <Text>
                    Your business status is
                    <Text style={{fontWeight: 'bold'}}> Published</Text>,
                    which means you have published the business and approved by
                    <Text style={{fontWeight: 'bold'}}> Africa One Stop </Text>and its visible publicly.
                </Text>
            }

            {
                status === 'unpublished' &&
                <Text>
                    Your business status is
                    <Text style={{fontWeight: 'bold'}}> Un-Published</Text>,
                    which means you have not published the business but approved by
                    <Text style={{fontWeight: 'bold'}}> Africa One Stop </Text>, not visible to the public.
                </Text>
            }
        </View>
    )
}
