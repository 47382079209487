import React, {useEffect, useState} from 'react';
import {View, Text, StyleSheet, TouchableOpacity, FlatList, Platform, ActivityIndicator, Pressable} from "react-native";
import {Layout} from "../Layout";
import {AppSpacing} from "../../components/AppSpacing";
import {redirect, useNavigate, useParams} from "react-router";
import {BusinessCard} from "./components/BusinessCard";
import useTheme from "../../hooks/useTheme";
import {getIsMobileDevice, serialize} from "../../utils/helpers";
import {useRoute} from "@react-navigation/core";
import useBusinessesQuery from "../../hooks/useBusinessesQuery";
import {BusinessSkeleton} from "./components/BusinessSkeleton";
import {useToast} from "react-native-toast-notifications";
import useAppNavigation from "../../hooks/useNavigation";
import {useAppContext} from "../../contexts/AppContext";

const styles = StyleSheet.create({
    categoryContainer: {
        gap: 4,
        flexDirection: 'row',
        flexWrap: 'wrap',
        paddingHorizontal: 16
    },
    category: {
        backgroundColor: 'white',
        borderRadius: 10,
        paddingVertical: 8,
        margin: 4,
        paddingHorizontal: 16
    }
})

export const BusinessListScreen = () => {
    const [activeCategory, setActiveCategory] = useState(null);
    const [viewportWidth, setViewportWidth] = useState(0);
    const [businesses, setBusinesses] = useState([]);
    const [nextPage, setNextPage] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const[categories, setCategories] = useState([]);
    const theme = useTheme();
    const isMobile = getIsMobileDevice();
    let params = {};
    if(Platform.OS === 'web') {
        const urlSearchParams = new URLSearchParams(window.location.search);
        params = Object.fromEntries(urlSearchParams.entries());
    }
    else{
        const route = useRoute();
         params = route.params;
    }
    const [searchParams, setSearchParams] = useState();
    const [loading, setIsLoading] = useState(false);
   const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [totalResults, setTotalResults] = useState(0);
    const [hasNextPage, setHasNextPage] = useState(true);

    const {fetchBusinesses, addLikeOrUnlike} = useBusinessesQuery();
    const toast = useToast();
    const {redirectTo} = useAppNavigation();
    const {state} = useAppContext();

    const defaultLat = state?.defaultLocation?.latitude ? state?.defaultLocation?.latitude : '';
    const defaultLon = state?.defaultLocation?.longitude ? state?.defaultLocation?.longitude : '';

    useEffect(() => {
        if(Platform.OS === 'web'){
            document.title = "Businesses"
        }
    }, [])


    useEffect(() => {
        if(state?.defaultLocation?.latitude && state?.defaultLocation.longitude) {
            setSearchParams({...params, lat: state?.defaultLocation?.latitude, lon: state?.defaultLocation.longitude});
        }
    }, [state?.defaultLocation])

    useEffect(() => {
        (async() => {
            if(searchParams) {
                await loadBusinesses();
            }

        })()
    }, [searchParams]);

    const onEndReached = async() => {
        await loadBusinesses(true)
    }

    const loadBusinesses = async(isMore = false) => {
        if(loading) {
            return;
        }

        if(isLoadingMore) {
            return;
        }


        if(isMore) {
            if(!hasNextPage) {
                return;
            }
            setIsLoadingMore(true);
        }

        setIsLoading(true);

        const query = serialize(searchParams);
        setCurrentPage(1);
        const response = await fetchBusinesses(`${query}&page=${isMore ? nextPage: 1}`);
        if(isMore) {
            setBusinesses([...businesses, ...response?.data]);
        }
        else{
            setBusinesses([...response?.data]);
        }

        setCurrentPage(response?.current_page);
        if(response?.next_page_url) {
            setNextPage(currentPage + 1);
        }
        setHasNextPage(!!response?.next_page_url);
        setTotalResults(response?.total);
        setCategories(response?.categories);
        setIsLoading(false);
        setIsLoadingMore(false);
    }

    const toggleActiveCategory = (index) => {
        if(activeCategory === index) {
            setActiveCategory(null)
            setSearchParams({...searchParams, category: ''})
        }
        else{
            setActiveCategory(index);
            setSearchParams({...searchParams, category: categories[index]?.id})
        }
    }

    const handleLikeBusiness = async(business) => {
        if(state.authToken) {
            const response = await addLikeOrUnlike(business?.id);
            if(response?.error) {
                toast.show(response?.message);
                return;
            }

            const existingBusinesses = businesses;
            const index = existingBusinesses.findIndex(item => item.id === business?.id);
            existingBusinesses[index]['has_liked'] = !business?.has_liked;
            if(existingBusinesses[index]['has_liked']) {
                existingBusinesses[index]['likes_count'] = existingBusinesses[index]['likes_count'] + 1
            }
            else{
                existingBusinesses[index]['likes_count'] = existingBusinesses[index]['likes_count'] - 1
            }
            setBusinesses([...existingBusinesses]);
        }
        else{
            redirectTo('Login');
        }
    }

    return(
        <Layout isStack={true} onEndReach={() => onEndReached()}>
           <View style={{paddingHorizontal: isMobile ? 16 : 0}}>
               <AppSpacing gap={16}/>
               <Text style={{fontWeight: 'bold', fontSize: 20}}>{totalResults} results found</Text>
               <AppSpacing gap={16}/>
               <View style={{height: 1, backgroundColor: '#DaDaDa'}}/>
               <AppSpacing gap={16}/>
               <Text style={{color: 'grey',fontWeight: 'bold'}}>Filter by Categories</Text>
               <AppSpacing gap={16}/>
           </View>
            <View style={[styles.categoryContainer, {paddingHorizontal: isMobile ? 16 : 0}]}>
                {categories.map((item, index) => {
                    return(
                        <Pressable
                            onPress={() => toggleActiveCategory(index)}
                            key={`cat-${index}`}
                            activeOpacity={0.8}
                            style={[styles.category, {backgroundColor: activeCategory === index ? theme.colors.primary : 'white'}]}>
                            <Text style={{color: activeCategory === index ? 'white' : theme.colors.text}}>{item?.name}</Text>
                        </Pressable>
                    )
                })}
            </View>
            <AppSpacing gap={16}/>
            <View style={{height: 1, backgroundColor: '#DaDaDa'}}/>
            <AppSpacing gap={32}/>
            <View style={{paddingHorizontal: isMobile ? 8 : 0}} onLayout={(event) => {
                const {x, y, width, height} = event.nativeEvent.layout;
                setViewportWidth(width);
            }} >
                {
                    loading &&
                    <View style={{flex: 1, width: '100%', minHeight: 300, justifyContent: 'center', alignItems: 'center'}}>
                        <ActivityIndicator color={theme.colors.primary}/>
                    </View>
                }
                {
                    businesses?.length > 0 &&

                        <View style={{flexDirection: 'row', marginBottom: 40, flexWrap: 'wrap'}}>
                            {
                                businesses?.map((item, index) =>
                                    <BusinessCard key={`rec-${index}`}
                                                  parentWidth={viewportWidth}
                                                  business={item}
                                                  likeBusiness={() => handleLikeBusiness(item)}/>)
                            }
                        </View>


                }
                {
                    isLoadingMore && <View style={{paddingVertical: 40, alignItems: 'center',  justifyContent: 'center'}}><ActivityIndicator color={theme.colors.primary}/></View>
                }
                {
                    !loading && businesses?.length === 0 &&
                        <View style={{minHeight: 300, alignItems: 'flex-start', justifyContent: 'center'}}>
                            <Text style={{fontSize: 40, fontWeight: 'bold', color: '#5c5c5c'}}>Oops!</Text>
                            <Text style={{fontSize: 40, marginTop: 4, fontWeight: 'bold', color: '#5c5c5c'}}>Businesses not found</Text>
                            <Text style={{fontWeight: 'bold', marginTop: 16}}>at {searchParams?.area ? searchParams?.area : state?.defaultLocation?.name}</Text>
                        </View>
                }

            </View>
        </Layout>
    )
}
