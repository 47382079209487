const Theme = () => {
    const options = {
        colors: {
            primary: '#590100',
            text: '#22334D',
            primaryText: '#22334D'
        }
    }
   return(
       options
   )
}

export default Theme;
