import {apiInstance} from '../utils/service';
import AsyncStorage from "@react-native-async-storage/async-storage";

const useRequest = () => {
    const getToken = async() => {
        const tokenString = await AsyncStorage.getItem('apiToken');
        return tokenString !== 'undefined' ? JSON.parse(tokenString) : null;
    }

    const get = async (url) => {
       const token = await getToken();
        return await apiInstance
            .get(url, {headers: {Authorization: `Bearer ${token}`}})
            .then(res => res.data)
            .catch(e => {
                console.log('check error in get', e);
                console.log('check error in get url', url);
                return parseResponse(e);
            });
    };

    const post = async (url, payload, headers = {}) => {
        const token = await getToken();
        return await apiInstance
            .post(url, payload, {
                headers: {...headers, Authorization: `Bearer ${token}`},
            })
            .then(res => res.data)
            .catch(e => {
                console.log('check error in post', e);
                console.log('check error in post url', url);
                return parseResponse(e);
            });
    };

    const upload = async (url, payload) => {
        const token = await getToken();
        return await apiInstance
            .post(url, payload, {headers: {Authorization: `Bearer ${token}`}})
            .then(res => console.log('red', res))
            .catch(e => {
                console.log('check error in upload', e);
                console.log('check error in upload url', url);
                console.log('eeror', e);
            });
    };

    const put = async (url, payload) => {
        const token = await getToken();
        return await apiInstance
            .put(url, payload, {headers: {Authorization: `Bearer ${token}`}})
            .then(res => res.data)
            .catch(e => {
                console.log('check error in put', e);
                console.log('check error in put url', url);
                return parseResponse(e);
            });
    };

    const remove = async (url) => {
        const token = await getToken();
        return await apiInstance
            .delete(url, {headers: {Authorization: `Bearer ${token}`}})
            .then(res => res.data)
            .catch(e => {
                console.log('check error in remove', e);
                console.log('check error in remove url', url);
                return parseResponse(e);
            });
    };

    const parseResponse = (e) => {
        console.log('error occured', e);
        if (e?.response?.status === 422) {
            const errors = Object.values(e?.response?.data.message).flat();
            return {
                error: true,
                message: errors[0],
                status: e?.response?.status,
            };
        }

        return {
            error: true,
            message: e.message,
            status: e?.response && e?.response?.status ? e?.response?.status : e,
        };
    };

    return {
        get,
        post,
        put,
        remove,
        upload,
    };
};

export default useRequest;
