import React, {useState} from 'react';
import {Image, StyleSheet, View, ActivityIndicator, Platform} from 'react-native';
import {useTheme} from "@react-navigation/native";
const FastImage = Platform?.OS === 'web' ? '' : require('react-native-fast-image');


const styles = StyleSheet.create({
    container: {
        overflow: 'hidden',
        borderRadius: 12,
        backgroundColor: 'transparent',
    },
    image: {
        width: '100%',
        height: '100%',
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 12,
        zIndex: 99,
    },
});


export const AppImage = ({
                             width,
                             height,
                             size,
                             roundness = 12,
                             resizeMode = 'cover',
                             uri,
                             isLocal = false,
                             aspectRatio,
                         }) => {
    const [isLoading, setIsLoading] = useState(true);
    const theme = useTheme();
    return (
        <View
            style={[
                styles.container,
                {width: size ? size : width, height: size ? size : height, borderRadius: roundness},
            ]}>
            {isLoading && (
                <View style={[styles.overlay, {width: size ? size : width, height: size ? size : height}]}>
                    <ActivityIndicator color={theme.colors.primary} />
                </View>
            )}

            {
                Platform.OS === 'web' ?
                    <Image
                        onLoadEnd={() => setIsLoading(false)}
                        style={[styles.image, {resizeMode: resizeMode}]}
                        source={isLocal ? uri : {uri: uri}}
                    />
                    :
                    <FastImage
                        onError={(e) => console.log('image error', e)}
                        onLoadEnd={() => setIsLoading(false)}
                        style={[styles.image, {resizeMode: resizeMode}]}
                        source={isLocal ? uri : {uri: uri}}
                    />
            }

        </View>
    );
};
