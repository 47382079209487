import React from 'react';
import {View, StyleSheet, Image, TouchableOpacity, Platform} from 'react-native';
import {DesktopMenu} from "../navigations/DesktopMenu";
import {AppButton} from "../components/AppButton";
import {AppSpacing} from "../components/AppSpacing";
import {useNavigate} from "react-router";
import useAppNavigation from "../hooks/useNavigation";
import {useAppContext} from "../contexts/AppContext";
import {useNavigation} from "@react-navigation/native";
import {updateLoginModalStatus} from "../contexts/reducers";

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 100,
        width: '100%',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 99,
    },
    menuContainer: {
        flexDirection: 'row',
        marginHorizontal: 16
    }
})

export const DesktopHeader = ({isTransparent = false, onPressAddBusiness}) => {
    const backgroundColor = isTransparent ? 'transparent' : 'white';
    const {redirectTo} = useAppNavigation();
    const navigate = useNavigate();
    const navigation  = useNavigation();
    const {state, dispatch} = useAppContext();
    const shadow = {
        shadowColor: '#1B1956',
        shadowOffset: {width: 0, height: 2},
        shadowOpacity: 0.1,
        shadowRadius: 8,
        elevation: 5,
    }

    const onClickLogo = () => {
        if(Platform.OS === 'web') {
            navigate('/');
        }
        else{
            navigation.navigate('Home');
        }
    }

    return(
        <View style={[styles.container, {backgroundColor: backgroundColor, flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}, isTransparent ? {} : {...shadow}]}>
            <View style={{flexDirection:'row', alignItems: 'center', justifyContent: 'space-between', maxWidth: 1140, flex: 1}}>
                <TouchableOpacity onPress={onClickLogo}>
                    <Image style={{height: 50, width: 100}} resizeMode='contain' source={require('../../assets/logo-cropped.png')}/>
                </TouchableOpacity>
                <View style={styles.menuContainer}>
                    <DesktopMenu onAddBusinessPress={onPressAddBusiness}/>
                    <AppSpacing isHorizontal={true} gap={16}/>
                    {
                        state?.authToken ?
                            <AppButton onPress={() => redirectTo('/profile')} title={'Profile'}/>
                            :
                            <AppButton onPress={() => redirectTo('/login')} title={'Login'}/>
                    }
                </View>
            </View>
        </View>
    )
}
