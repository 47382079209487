import React, {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useReducer,
} from 'react';
import {apiInstance} from '../utils/service';
import AsyncStorage from '@react-native-async-storage/async-storage';

const initialState = {
    authToken: null,
    isLoginModalVisible: false,
    user: null,
    defaultLocation: null,
    recommendedBusinesses: [],
    nearestBusinesses: [],
    businesses: [],
    myBusiness: [],
    loadingStatus: {
        isRecommendedLoading: true,
        isNearestLoading: true,
        isBusinessesLoading: true,
    },
    categories: []
};

const AppContext = createContext({
    state: initialState,
    dispatch: () => {},
});

const setLoginParams = async (token) => {
    await AsyncStorage.setItem('apiToken', JSON.stringify(token));
};

const unsetLoginParams = async () => {
    await AsyncStorage.removeItem('apiToken');
};

function appReducer(prevState, action) {
    switch (action.type) {
        case 'SET_TOKEN':
            setLoginParams(action.payload);
            return {
                ...prevState,
                authToken: action.payload,
            };
        case 'UNSET_TOKEN':
            unsetLoginParams();
            return {
                ...prevState,
                authToken: null,
            };
        case 'SET_USER':
            return {
                ...prevState,
                user: action.payload,
            };
        case 'UPDATE_DEFAULT_LOCATION':
            return {
                ...prevState,
                defaultLocation: action.payload,
            };
        case 'UPDATE_RECOMMENDED_BUSINESSES':
            return {
                ...prevState,
                recommendedBusinesses: action.payload,
            };
        case 'UPDATE_NEAREST_BUSINESSES':
            return {
                ...prevState,
                nearestBusinesses: action.payload,
            };
        case 'UPDATE_BUSINESSES':
            return {
                ...prevState,
                businesses: action.payload,
            };
        case 'UPDATE_MY_BUSINESSES':
            return {
                ...prevState,
                myBusiness: action.payload,
            };
        case 'UPDATE_LOADING_STATUS':
            return {
                ...prevState,
                loadingStatus: action.payload,
            };
        case 'UPDATE_LOGIN_MODAL_STATUS':
            return {
                ...prevState,
                isLoginModalVisible: action.payload,
            };
        case 'SET_CATEGORIES':
            return {
                ...prevState,
                categories: action.payload,
            };
        default:
            return prevState;
    }
}

export const AppProvider = (props) => {
    const {children} = props;
    const [state, dispatch] = useReducer(appReducer, initialState);

    useEffect(() => {
        (async () => {
            try {
                const tokenFromStorage = await AsyncStorage.getItem('apiToken');
                console.log('tokenFromStorage', tokenFromStorage === 'undefined');
                const authToken = tokenFromStorage !== 'undefined'
                    ? JSON.parse(tokenFromStorage)
                    : null;

                if (authToken) {
                    dispatch({
                        type: 'SET_TOKEN',
                        payload: authToken,
                    });
                }
            } catch (e) {
                console.warn(e);
            }
        })();
    }, []);

    const contextValue = useMemo(
        () => ({
            state,
            dispatch,
        }),
        [state, dispatch],
    );

    return (
        <AppContext.Provider value={contextValue}>
            {children}
        </AppContext.Provider>
    );
};

export const useAppContext = () => useContext(AppContext);
export default AppContext;
