import useRequest from "./useRequest";

const useMessageQuery = () => {
    const request = useRequest();

    const sendMessage = async(payload) => {
        return await request.post('/enquiries/sent', payload).then(res => res);
    }

    return {
        sendMessage
    }
}

export default useMessageQuery;
