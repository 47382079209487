import {View} from "react-native";
import {ResponsiveRow} from "./ResponsiveRow";
import {AppTextInput} from "../../../components/AppTextInput";
import {AppSpacing} from "../../../components/AppSpacing";
import {useEffect, useState} from "react";
import {AppButton} from "../../../components/AppButton";

export const TimingForm = ({initialValues, onFormChange, onContinuePress}) => {
    const initialTiming = {
        "SUN": "",
        "MON" : "",
        "TUE" : "",
        "WED" : "",
        "THU" : "",
        "FRI" : "",
        "SAT" : "",
    }
    const [timing, setTiming] = useState(
        initialValues?.timing
        ? initialValues?.timing
        : initialTiming
    )

    const updateTiming = (key, value) => {
        const updatedTime = timing;
        updatedTime[key] = value;
        setTiming({...updatedTime})
    }

    const onFocus = (key) => {
        const value = timing['MON'];
        const existingValue = timing[key];
        if(!existingValue) {
            const updatedTime = timing;
            updatedTime[key] = value;
            setTiming({...updatedTime})
        }

    }

   useEffect(() => {
       onFormChange({...initialValues, timing: timing})
   }, [timing])


    return(
        <View style={{paddingVertical: 16}}>
            <ResponsiveRow>
                <AppTextInput editable={false} value={'Sunday'}/>
                <AppTextInput value={timing['SUN']} onChangeText={e => updateTiming('SUN', e)} placeholder={'Time Range'}/>
            </ResponsiveRow>
            <AppSpacing gap={16}/>
            <ResponsiveRow>
                <AppTextInput editable={false} disabled value={'Monday'}/>
                <AppTextInput value={timing['MON']} onChangeText={e => updateTiming('MON', e)} placeholder={'Time Range'}/>
            </ResponsiveRow>
            <AppSpacing gap={16}/>
            <ResponsiveRow>
                <AppTextInput editable={false} disabled value={'Tuesday'}/>
                <AppTextInput value={timing['TUE']} onFocus={() => onFocus('TUE')} onChangeText={e => updateTiming('TUE', e)} placeholder={'Time Range'}/>
            </ResponsiveRow>
            <AppSpacing gap={16}/>
            <ResponsiveRow>
                <AppTextInput editable={false} disabled value={'Wednesday'}/>
                <AppTextInput value={timing['WED']} onFocus={() => onFocus('WED')} onChangeText={e => updateTiming('WED', e)} placeholder={'Time Range'}/>
            </ResponsiveRow>
            <AppSpacing gap={16}/>
            <ResponsiveRow>
                <AppTextInput editable={false} disabled value={'Thursday'}/>
                <AppTextInput value={timing['THU']} onFocus={() => onFocus('THU')} onChangeText={e => updateTiming('THU', e)} placeholder={'Time Range'}/>
            </ResponsiveRow >
            <AppSpacing gap={16}/>
            <ResponsiveRow>
                <AppTextInput editable={false} disabled value={'Friday'}/>
                <AppTextInput value={timing['FRI']} onFocus={() => onFocus('FRI')} onChangeText={e => updateTiming('FRI', e)} placeholder={'Time Range'}/>
            </ResponsiveRow>
            <AppSpacing gap={16}/>
            <ResponsiveRow>
                <AppTextInput editable={false} disabled value={'Saturday'}/>
                <AppTextInput value={timing['SAT']} onFocus={() => onFocus('SAT')} onChangeText={e => updateTiming('SAT', e)} placeholder={'Time Range'}/>
            </ResponsiveRow>
            <View style={{marginVertical: 16}}>
                <AppButton onPress={onContinuePress} title={"Save"}/>
            </View>
        </View>
    );
}
