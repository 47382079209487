import useRequest from "./useRequest";

const useUsersQuery = () => {
    const request = useRequest();

    const login = async(payload) => {
        return await request.post('/login', payload)
            .then(res => res)
            .catch(e => {
                return {
                    error: true,
                    message: e.message
                }
            });
    }

    const register = async(payload) => {
        return await request.post('/register', payload).then(res => res);
    }

    const forgotPassword = async(payload) => {
        return await request.post('/forgot-password', payload).then(res => res);
    }

    const resetPassword = async(payload) => {
        return await request.post('/reset-password', payload).then(res => res);
    }

    const getMe = async() => {
        return await request.get('/me').then(res => res);
    }

    const updateProfile = async(payload) => {
        return await request.put('/me', payload).then(res => res);
    }
    const getUpgradeStatus = async(payload) => {
        return await request.get('/users/upgrade/status', payload).then(res => res);
    }

    const deleteAccount = async(userId) => {
        return await request.remove(`users/${userId}`).then(res => res);
    }

    const cancelStripeSubscription = async() => {
        return await request.remove(`users/subscriptions/cancel`).then(res => res);
    }

    return {
        login,
        register,
        forgotPassword,
        resetPassword,
        getMe,
        updateProfile,
        getUpgradeStatus,
        deleteAccount,
        cancelStripeSubscription
    }
}

export default useUsersQuery;
