import {Layout} from "../Layout";
import {StyleSheet, Text, TouchableOpacity, View, Pressable} from 'react-native';
import {AppButton} from "../../components/AppButton";
import {useEffect, useState} from "react";
import {getIsMobileDevice} from "../../utils/helpers";
import {PLAN_1, PLAN_2, PLAN_3, PLAN_4, PLAN_5} from "../../utils/constants";
import {useAppContext} from "../../contexts/AppContext";
import {setUser} from "../../contexts/reducers";
import useUsersQuery from "../../hooks/useUsersQuery";
import {useNavigate} from "react-router";
import useTheme from "../../hooks/useTheme";
import {Colors} from "../../themes/colors";
import {useToast} from "react-native-toast-notifications";
import {ActivityIndicator} from "react-native-web";

const pricingOptions = [
    {
        name: 'Plan 1',
        features: ['Add up to 2 businesses', 'Monthly renewable', 'Cancel item or upgrade to next plan'],
        price: 7.99,
        limit: 2,
    },
    {
        name: 'Plan 2',
        features: ['Add up to 5 businesses', 'Monthly renewable', 'Cancel item or upgrade to next plan'],
        price: 14.99,
        limit: 5
    },
    {
        name: 'Plan 3',
        features: ['Add up to 10 businesses', 'Monthly renewable', 'Cancel item or upgrade to next plan'],
        price: 34.99,
        limit: 10,
    },
    {
        name: 'Plan 4',
        features: ['Add up to 20 businesses', 'Monthly renewable', 'Cancel item or upgrade to next plan'],
        price: 79.99,
        limit: 20
    },
    {
        name: 'Plan 5',
        features: ['Add up to 50 businesses', 'Monthly renewable', 'Cancel item or upgrade to next plan'],
        price: 149.99,
        limit: 9999
    }
]

const SubscriptionWebScreen = () => {
    const paymentLinks = [PLAN_1, PLAN_2, PLAN_3, PLAN_4, PLAN_5]
    const [selectedPlan, setSelectedPlan] = useState(null);
    const theme = useTheme();
    const {state, dispatch} = useAppContext();
    const isMobile = getIsMobileDevice();
    const {getMe} = useUsersQuery();
    const toast = useToast();
    const [isLoading, setIsLoading] = useState(true);
    const choosenPlan = pricingOptions[selectedPlan];
    console.log('state.user?.businesses_count', state.user);
    const handleBuySubscription = async() => {
        window.location.href = `${paymentLinks[selectedPlan]}?prefilled_email=${state.user.email}&client_reference_id=${state.user.id}-${selectedPlan}`;
    }

    const navigate = useNavigate();

    useEffect(() => {
        (async() => {
            const response = await getMe();
            dispatch(setUser(response));
            setIsLoading(false);
            // if(response.active_subscription) {
            //     window.location.href = ('/business/setup')
            // }
        })()
    }, [])

    return(
        <Layout>
            <View style={{flexDirection: 'row', justifyContent: 'center'}}>
                {isLoading ?
                    <View style={{justifyContent: 'center', alignItems: 'center', minHeight: 600}}>
                        <ActivityIndicator color={theme.colors.primary}/>
                    </View>
                    :
                    <View style={{padding: 16, marginVertical: 50, maxWidth: isMobile ? '100%' : '50%'}}>
                        <Text style={{fontSize: 20, fontWeight: 'bold', marginBottom: 16}}>Subscribe</Text>
                        <Text style={{marginBottom: 8}}>To add new business you need to subscribe to one of our subscription plan</Text>
                        <Text style={{marginBottom: 8}}>Please choose one of the plan below</Text>
                        {
                            state.user?.active_plan ?
                                <Text style={{fontWeight: 'bold'}}>Please upgrade your plan in order to add more business</Text>
                                :
                                <Text style={{fontWeight: 'bold', color: theme.colors.primary}}>You don't have an active plan</Text>
                        }

                        <View style={{marginVertical: 16}}>
                            {
                                pricingOptions.map((item, index) => {
                                    const isDisabled = item?.limit < state.user?.businesses_count;
                                    return(
                                        <Pressable disabled={isDisabled} key={item?.name} onPress={() => setSelectedPlan(index)} style={[styles.subscriptionItem, {position: 'relative', borderColor: selectedPlan === index ? theme.colors.primary : 'grey'}]}>
                                            {
                                                isDisabled &&
                                                <View style={[styles.subscriptionItem, {position: 'absolute', top: 0, zIndex: 9, left: 0, borderColor: 'transparent', width: '100%', margin: 0, height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.7)'}]}/>
                                            }

                                            <Text style={{fontWeight: 'bold'}}>{item?.name}</Text>
                                            <View style={{marginTop: 8}}>
                                                {
                                                    item.features.map(feature => {
                                                        return(
                                                            <Text key={feature} style={{marginVertical: 4}}>• {feature}</Text>
                                                        )
                                                    })
                                                }
                                            </View>
                                            <Text style={{fontWeight: 'bold', fontSize: 20, marginTop: 10}}>${item?.price} per month</Text>
                                        </Pressable>
                                    )
                                })
                            }
                        </View>
                        <View>
                            <AppButton onPress={() => handleBuySubscription()} title={"Subscribe"}/>
                        </View>
                    </View>
                }
            </View>

        </Layout>
    )
}

const styles = StyleSheet.create({
    subscriptionItem: {
        padding: 16,
        borderWidth: 2,
        borderColor: 'grey',
        borderRadius: 16,
        marginVertical: 16
    }
})

export default SubscriptionWebScreen;
