import {Text, TouchableOpacity, View, Image} from "react-native";
import {AppButton} from "../../components/AppButton";
import {Layout} from "../Layout";
import {AppSpacing} from "../../components/AppSpacing";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {setUser} from "../../contexts/reducers";
import useUsersQuery from "../../hooks/useUsersQuery";

export const ThankYouScreen = () => {
    const [timeLeft, setTimeLeft] = useState(10);
    const navigate = useNavigate();
    const {getMe} = useUsersQuery();

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft => timeLeft - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        if (timeLeft === 0) {
            window.location.href = '/profile';
        }
    }, [timeLeft]);

    return(
        <Layout>
            <View style={{flexDirection: 'row', minHeight: 500, flex: 1, alignItems: 'center',  justifyContent: 'center'}}>
               <View style={{alignItems: 'center'}}>
                   <Image style={{width: 150, height: 150}} source={require('../../../assets/img/thank-you.png')}/>
                   <AppSpacing gap={32}/>
                   <Text style={{fontWeight: 'bold', fontSize: 20, textAlign: 'center'}}>Thank you for your subscription</Text>
                   <AppSpacing gap={16}/>
                   <Text style={{textAlign: 'center'}}>You'll be redirected in {timeLeft} seconds</Text>
               </View>
            </View>
        </Layout>
    )
}
