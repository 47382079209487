import {Dimensions} from "react-native";

export const useDimension = () => {
    const width = Dimensions.get('window').width;
    const height = Dimensions.get('window').height;
    return {
        width,
        height
    }
}
